import React from "react";

const PurityIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    className="promises-img"
  >
    <path
      class="st0"
      d="M142.92,89.58c-0.02-0.68-0.55-1.24-1.2-1.24h-2.63c-0.68,0-1.21,0.59-1.2,1.31l0.06,2.51
	c0.01,0.69,0.55,1.24,1.2,1.24l2.62,0.01c0.68,0,1.23-0.59,1.21-1.31L142.92,89.58z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57
	c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z
	 M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1
	c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44
	c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57
	c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z
	 M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1
	c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44
	c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57
	c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z
	 M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1
	c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44
	c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57
	c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z
	 M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1
	c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44
	c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57
	c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z
	 M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1
	c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M151.45,89.62c0-0.71-0.54-1.28-1.21-1.28h-2.57c-0.69,0-1.23,0.59-1.21,1.31l0.06,2.44
	c0.02,0.71,0.58,1.27,1.25,1.25l2.53-0.1c0.65-0.02,1.16-0.59,1.16-1.28V89.62z M133.43,81l17.71-0.05c0.21,0,0.41,0.06,0.58,0.17
	h0.63v-0.18l20.41-0.06c0.01-0.14,0.02-0.28,0.03-0.42c0.05-0.49,0.08-0.91,0.08-1.32c0.02-1.28,0.03-2.56,0.05-3.83
	c0.05-3.55,0.1-7.22,0.06-10.81c-0.04-3.15-0.15-6.79-0.97-10.35c-0.55-2.38-1.82-3.97-3.86-4.86c-1.91-0.83-3.92-1.55-5.86-2.24
	c-0.42-0.15-0.84-0.29-1.26-0.45c-3.35-1.21-6.71-2.43-10.07-3.63c-4.55-1.64-9.25-3.32-13.88-5c-1.08-0.4-2.2-0.9-3.63-1.62
	c-1.93-0.98-3.99-1.46-6.28-1.45c-0.54,0-1.1,0.03-1.67,0.08c-2.58,0.25-5.22,0.45-7.75,0.64c-1.67,0.12-3.41,0.25-5.11,0.4
	c-2.56,0.21-5.17,0.46-7.68,0.69c-1.52,0.14-3.04,0.28-4.57,0.42c-1.97,0.17-3.93,0.35-5.9,0.51c-1.86,0.17-3.72,0.33-5.58,0.5
	c-1.82,0.16-3.65,0.32-5.47,0.49c-2.39,0.22-4.78,0.43-7.17,0.64c-2.58,0.23-5.17,0.45-7.76,0.66c-1.31,0.12-2.61,0.23-3.92,0.34
	c-1.41,0.12-2.81,0.24-4.21,0.36c-2.95,0.26-6.01,0.52-9.02,0.76c-1.76,0.14-3.57,0.28-5.3,0.41c-2.6,0.19-5.3,0.39-7.93,0.62
	c-2.84,0.25-5.39,0.51-7.89,1.01c-2.46,0.49-3.24,1.55-3.24,4.37c0.02,9.08,0.05,18.17,0.07,27.26l0.02,9.9l0.01,3.51
	c0,0.07-0.02,0.14-0.05,0.2c0.03,0.46,0.05,0.94,0.05,1.42c0.06,19.54,0.11,39.1,0.16,58.64c0.01,2.95,0.42,3.45,3.15,3.83
	c1.52,0.21,89.02,12.52,91.03,12.52c3.18-0.01,5.98-0.4,8.57-1.2c2.06-0.64,4.11-1.58,5.96-2.72c5.08-3.15,10.19-6.45,15.13-9.64
	c1.12-0.72,2.25-1.45,3.37-2.18c3.68-2.36,7.47-4.86,11.28-7.38c0.87-0.58,1.76-1.27,2.8-2.16c1.25-1.09,2.12-2.43,2.56-4
	c0.66-2.35,0.98-4.38,1.02-6.35c0.1-6.35,0.24-12.79,0.38-19.04c0.05-2.17,0.1-4.38,0.14-6.61c0.03-1.61,0.06-3.23,0.1-4.85
	c0.01-0.5,0.02-1,0.02-1.5c0.01-0.64,0.02-1.28,0.03-1.92v-0.01c0-0.41,0.01-0.8,0.02-1.21c0.02-2.03,0.03-4.06,0.04-6.04
	c0.01-1.27,0.02-0.22,0.03-1.49c0.01-0.49,0.01-0.98,0-1.54c0-0.14-0.01-0.29-0.01-0.45l-20.46,0.06l0.08,1.04h-15.28
	c-0.79,0-1.44,0.7-1.42,1.55l0.31,13.67l-3.2,0.2l-0.02-0.8c-0.08-0.16-0.13-0.34-0.14-0.53l-0.45-17.8
	c-0.01-0.23,0.05-0.44,0.15-0.62l-0.01-0.47h0.49C133.09,81.04,133.25,81,133.43,81 M127.94,37.04c0.09-0.01,0.17-0.03,0.26-0.03
	c0.2,0,0.38,0.05,0.53,0.12h0.06c0.24,0,0.45,0.06,0.61,0.17c0.21,0.16,0.38,0.27,0.54,0.37c0.35,0.21,0.64,0.4,0.64,0.69
	c0.03,12.92,0.03,26.1,0.02,39.15c0,0.29-0.29,0.47-0.64,0.69c-0.15,0.1-0.35,0.23-0.57,0.39c-0.14,0.11-0.38,0.18-0.61,0.18
	c-0.09,0-0.17-0.01-0.27-0.03c-0.1-0.02-0.2-0.06-0.28-0.1c-0.04,0-0.06-0.01-0.1-0.02c-0.32-0.07-0.54-0.25-0.54-0.45l-0.18-40.69
	C127.41,37.29,127.62,37.11,127.94,37.04 M31.07,49.62c0.35-1.45,1.55-2.58,3.01-2.71l84.54-7.52c0.93-0.09,1.87,0.25,2.57,0.93
	c0.69,0.67,1.1,1.62,1.1,2.62l0.13,12.8c0.01,1.94-1.46,3.57-3.3,3.63l-16.36,0.56h-0.02l-12.86,0.43L34.5,62.23h-0.1
	c-0.88,0-1.72-0.35-2.37-1.01c-0.46-0.48-0.78-1.06-0.94-1.7v-0.01c-0.06-0.28-0.1-0.57-0.1-0.87l-0.02-8.13
	C30.96,50.2,31,49.91,31.07,49.62 M114.48,144.66c-0.01,0.02-0.01,0.02-0.01,0.03l0.01,0.38c0.02,2.02-0.73,3.91-2.08,5.16
	c-1.16,1.09-2.61,1.57-4.09,1.36l-7.92-1.02l-0.13-0.02l-0.05-0.01c-0.5-0.05-1-0.12-1.5-0.18l-1.09-0.14c-0.04,0-0.08,0-0.12-0.01
	l-16.98-2.24c0.08,0.01,0.14,0.03,0.22,0.04l0.19,0.03c1.64,0.27,3.34,0.55,5,0.76c2.35,0.31,4.75,0.6,7.09,0.89
	c-2.34-0.28-4.72-0.57-7.08-0.87c-1.67-0.21-3.36-0.49-5.01-0.75l-0.19-0.03c-0.08-0.01-0.14-0.02-0.22-0.03l-16.35-2.11l-28.9-3.72
	c-0.5-0.21-1.03-0.57-1.5-1.03c-2.65-2.62-3.63-7.72-2.57-11.6c0-0.01,0.01-0.01,0.01-0.01c0.03-0.12,0.07-0.25,0.1-0.36
	c0.26-0.83,0.63-1.6,1.09-2.26c0.98-1.4,2.13-2.29,3.43-2.65c0.31-0.08,0.63-0.14,0.96-0.17h0.02l9.2,0.82h0.02l6.89,0.61
	c0.03,0.01,0.07,0.01,0.1,0.01l55.21,4.9h0.02c0,0,6.02-0.2,6.11,6.38c0,0.01,0.01,0.02,0.01,0.03c0.01,0.16,0.02,0.31,0.02,0.47
	l0.07,7.24V144.66z M119.86,86.77l-0.84,0.01c-5.11,0.13-10.2,0.25-15.31,0.36c-3.79,0.1-7.61,0.18-11.46,0.27
	c-0.14,0.01-0.29,0.03-0.44,0.03h-0.13c-0.16,0.01-0.31,0.01-0.47,0.01c-7.05,0.23-14.18,0.45-21.22,0.72
	c-8.6,0.33-17.34,0.76-25.79,1.17c-4.69,0.23-9.39,0.46-14.08,0.68c-0.08,0-0.16,0.01-0.23,0.01c-0.52,0-1.03-0.06-1.53-0.12
	c-0.27-0.03-0.53-0.06-0.8-0.1c-0.21,0-0.37-0.4-0.38-0.9l-0.01-0.53l-0.02-0.53c-0.01-0.36,0.08-0.71,0.2-0.87
	c0.03-0.23,0.12-0.4,0.21-0.5c0.06-0.04,0.12-0.13,0.17-0.2c0.16-0.2,0.36-0.46,0.6-0.46c21.13-0.65,42.5-1.3,63.63-1.94
	c0.09,0,0.18-0.01,0.28-0.01c0.05,0,0.1,0,0.15-0.01c10.59-0.39,21.13-0.78,31.54-1.17l4.04-0.15h0.01c0.1,0,0.2,0.1,0.28,0.29
	c0.14,0.1,0.25,0.41,0.28,0.79l0.02,0.68l0.03,0.6c0.02,0.51-0.12,0.99-0.34,1.07c-0.18,0.05-0.36,0.13-0.54,0.22
	c-0.4,0.17-0.83,0.34-1.26,0.36C124.25,86.65,122.01,86.71,119.86,86.77 M127.7,149.17v-3.27c-0.11-21.19-0.18-40.44-0.24-58.53
	c0-0.26,0.38-0.4,0.69-0.51c0.12-0.05,0.26-0.1,0.42-0.17c0.14-0.07,0.36-0.1,0.56-0.1c0.09,0,0.15,0.01,0.24,0.02
	c0.14,0.02,0.27,0.06,0.38,0.1c0.25,0.04,0.47,0.15,0.54,0.28c0.08,0.11,0.16,0.21,0.23,0.31c0.18,0.24,0.38,0.49,0.38,0.75
	c0.02,19.59,0.02,39.45,0.02,58.85c0.01,2.78,0.03,5.54,0.04,8.3l0.03,4.8c0,0.06-0.05,0.12-0.09,0.18
	c-0.12,0.13-0.35,0.21-0.63,0.23c-0.27,0.02-0.47,0.04-0.61,0.05c-0.19,0.02-0.32,0.03-0.46,0.03c-0.09,0-0.17-0.01-0.25-0.02
	c-0.39-0.03-0.83-0.21-0.91-0.45c-0.02-0.08-0.05-0.16-0.08-0.23c-0.09-0.27-0.19-0.55-0.19-0.84
	C127.74,155.64,127.71,152.38,127.7,149.17 M112.84,137.21c-0.03-3.04-2.25-5.53-5.17-5.78l-69.15-5.78
	c-1.54-0.13-3.04,0.39-4.19,1.46c-1.14,1.05-1.8,2.51-1.88,4.09l-0.15,3.29c-0.15,3.05,2.01,5.73,4.91,6.11l69.39,9.09
	c0.23,0.03,0.47,0.05,0.7,0.05c1.39,0,2.74-0.53,3.78-1.52c1.19-1.13,1.86-2.73,1.84-4.41L112.84,137.21z M110.81,147.92
	c-1.13,1.07-2.65,1.57-4.18,1.37l-69.37-9.09c-2.7-0.35-4.72-2.85-4.57-5.68l0.16-3.3c0.07-1.47,0.68-2.82,1.74-3.79
	c0.98-0.91,2.19-1.39,3.48-1.39c0.14,0,0.28,0.01,0.43,0.02l69.15,5.79c2.72,0.23,4.79,2.54,4.82,5.37l0.07,6.6
	C112.54,145.38,111.92,146.87,110.81,147.92 M121.91,42.95c-0.01-0.9-0.35-1.72-0.98-2.32c-0.56-0.54-1.27-0.83-2.03-0.83
	c-0.08,0-0.17,0.01-0.24,0.01l-84.54,7.51c-1.08,0.1-1.99,0.8-2.45,1.78c-0.2,0.43-0.31,0.91-0.31,1.41l0.02,8.13
	c0.01,0.61,0.17,1.2,0.49,1.71c0.12,0.2,0.27,0.39,0.43,0.57c0.6,0.6,1.36,0.92,2.19,0.9l55.72-1.88l16.48-0.56l2.93-0.1
	c0,0,0,0-0.01,0h0.03l9.48-0.32c1.62-0.06,2.94-1.5,2.92-3.22L121.91,42.95z M117.09,57.4L57.9,59.56l-22.17,0.8h-0.13
	c-0.98,0.01-1.88-0.35-2.52-0.98c-0.48-0.49-0.75-1.12-0.76-1.75l-0.07-6.19c-0.02-1.46,1.27-2.67,2.99-2.8l43.93-3.52l0.07-0.01
	l37.23-2.99c1.03-0.08,2.07,0.27,2.76,0.92c0.51,0.5,0.8,1.13,0.81,1.79l0.19,9.72C120.27,56.08,118.89,57.33,117.09,57.4"
    />
  </svg>
);

export default PurityIcon;
