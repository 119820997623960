const DeductionIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    className="promises-img"
  >
    <path
      class="st0"
      d="M173.07,104.05c-0.26-1.09-4.64-8.9-5.7-10.87c-0.28-0.52-0.42-0.67-0.71-1.23c-1.17-2.29-2.34-4.7-3.68-6.9
	l-2.9-5.38c-0.29-0.53-0.43-0.88-0.71-1.42c-2.08-4.1-4.43-8.05-6.52-12.16l-1.47-2.76c-0.44-0.85-1.18-1.81-1.41-2.82
	c2.2-1.17,4.47-1.94,6.3-3.74c7.18-7.02,1.75-15.47-5.6-15.47c-2.81,0-7.58,1.2-7.58,4.58c0,6.89,8.1,4.59,8.1,0.88
	c0-0.7-0.36-0.87-0.53-1.58c1.37,0.32,2.85,0.69,3.63,1.66c2.55,3.11-0.14,8.74-6.63,8.74c-5.4,0-16.76-3.99-25.73-4.24
	c-1.08-0.03-1.46,0.2-2.64,0.19c-2.59-0.02-4.83,0.55-7.33,0.96c-1.09,0.18-1.46,0.14-2.19,0.63c-0.48-1.79-1.77-1.7-2.99-2.29
	c1.2-2.49-1.02-6.65-3.71-6.87c0.17-0.64,0.3-0.46,0.65-1.11c0.59-1.1,0.4-2.44-0.42-3.35c-1.89-2.09-5.7-0.65-5.7,1.99
	c0,1.45,0.66,1.46,0.88,2.29c-2.13,0.5-3.87,2.44-3.87,4.76l0.17,2.29c-1.64,0.14-2.38,0.95-2.99,2.12
	c-1.73-0.04-7.73-1.41-13.22-1.41c-9.08,0-17.05,4.05-25.21,4.05c-7.2,0-8.59-7.25-5.65-9.35c0.72-0.52,2.03-0.96,3.19-1.05
	c-0.08,0.89-1.6,4.94,2.99,4.94h1.59c1.56,0,2.99-2.07,2.99-3.71c0-2.96-3.65-5.11-6.7-5.11c-2.7,0-5.41,0.51-7.18,2.16
	c-2.86,2.65-3.18,7.45-1.33,10.77c1.35,2.44,3.52,4.1,6.02,5.33c-0.13,0.52-0.31,0.83-0.59,1.29L37.8,81.31
	c-1.56,2.9-4.39,8.47-5.79,10.77l-5.78,10.97L19,103.41l7.37,1.8c0.91,0.23,7,1.56,7.3,2.04c0.72,1.14-1.06,2.5,2.07,2.52
	c1.76,0.01,2.83-0.19,4.6-0.19h23.27c0.59,0,1.8,0.17,2.13-0.16c0.42-0.42-0.04-1.66,0.29-2.18c0.21-0.31,2.95-0.97,3.5-1.09
	c1.72-0.39,10.58-2.74,11.35-2.75l-7.93-0.35l-6.46-12.05c-1.66-3.08-5.01-9.78-6.55-12.31l-7.96-14.95
	c-0.42-0.8-1.7-2.84-2.04-3.88c1.03,0.47,2.11,0.89,3.21,1.25c7.95,2.62,20.29,3.63,29.28,1.83c1.79-0.36,6.78-1.8,7.98-1.89
	c0,3.82,1.94,6.87,4.85,9.07c0.49,0.37,0.83,0.61,1.4,0.89c0.66,0.33,1.3,0.27,1.67,0.79c-1.91,0.16-4.05,2.52-4.05,4.58
	c0,4.87,3.17,4.82,3.17,6.52c0,4.95,0.21,9.14-1.21,13.78c-0.66,2.15-1.41,4.22-2.16,6.3l-1.99,6.47
	c-1.79,7.15-1.64,13.17,1.89,19.75c0.27,0.51,0.56,0.97,0.86,1.43c0.32,0.51,0.69,0.85,0.84,1.45c-2,1.06-4.58,3.7-4.58,6.7
	c0,5.54,2.56,5.7,3.7,7.4c-1.95,0-4.74-0.33-6.17,0c-0.29,1.23-0.06,2.28,0.04,3.52C75.49,149.7,77,148.9,77,152.17
	c0,0.75-0.79,0.55-1.58,0.54c-3.03-0.05-12.17-0.33-14.4,0.41c-3.04,1-6.21,2.28-7.32,5.37c-0.19,0.53-0.29,0.96-0.43,1.69
	c-0.16,0.8-0.39,1.33,0.28,1.33h94.48c0-5.14-5-8.5-9.69-8.64c-0.7-0.02-0.91-0.16-1.51-0.11c-3.51,0.35-7.99,0.28-11.71,0.28
	c-0.63,0-0.76-0.5-0.69-1.19c0.06-0.63,0.17-0.89,0.17-1.81l-11.99-0.17c0-3.08,0.4-3.53-0.53-3.69c-0.36-0.06-1.34-0.01-1.76-0.01
	c-1.09,0-2.74-0.2-3.53,0.17c0.12-0.46,1.95-2.03,2.4-2.71c3.41-5.12-0.71-10.7-3.28-11.38c0.06-0.72,1.14-2.34,1.55-3.03
	c0.57-0.94,1.11-2.12,1.51-3.25c2.74-7.74,1.76-11.77-0.38-19.49c-1.46-5.28-4.62-11.62-4.62-17.24c0-0.91,0.2-0.88,0.18-1.76
	c-0.03-1.35-0.94-4.28,0.14-5.32c0.68-0.66,1.54-1.03,2.12-1.93c1.85-2.86,1.16-7.35-3.5-8.43l0.68-0.38
	c0.41-0.18,0.39-0.17,0.81-0.42c4.14-2.46,6.62-4.11,6.62-9.94c1.52,0.13,3.13,0.74,4.59,1.05c10.49,2.15,21.4,2.58,31.7-0.72
	c0.67-0.22,1.4-0.51,2.13-0.68c-0.33,1.27-2.16,4.13-2.87,5.41c-0.52,0.93-0.89,1.72-1.41,2.64l-15.93,29.73
	c-0.57,1.07-2.67,4.71-2.87,5.58l-7.23,0.35c1.56,0.04,10.7,2.62,12.87,3.18c3.36,0.86,1.36-0.23,2.12,2.99h26.09
	c7.66,0,5.14,1.18,5.99-2.47l10.96-2.78c0.98-0.27,2.92-0.84,3.84-0.92L173.07,104.05z M49.32,103.05H26.76
	c0.15-0.66,2.24-4.41,2.7-5.24l7.06-13.21c0.24-0.49,0.42-0.87,0.64-1.3l5.64-10.58c0.27-0.52,0.48-0.76,0.77-1.34l4.23-7.94
	c0.3-0.61,1.09-2.2,1.53-2.52V103.05z M58.89,77.79l3.59,6.64c0.25,0.5,0.38,0.8,0.65,1.3c0.3,0.55,0.47,0.76,0.76,1.34
	c0.51,1.01,0.92,1.65,1.41,2.65l4.23,7.93c0.5,1.01,0.92,1.65,1.41,2.65l1.47,2.76H49.67V60.75C50.3,61.21,57.67,75.51,58.89,77.79z
	 M149.45,104.05h-22.56l5.52-10.52c0.27-0.56,0.42-0.73,0.71-1.23c0.29-0.52,0.41-0.88,0.72-1.39l4.23-7.94
	c1.05-1.76,1.8-3.51,2.84-5.26c1.5-2.5,4.7-8.87,6.3-11.85l2.23-3.94V104.05z M149.98,104.05V61.92l0.76,1.17
	c0.25,0.49,0.42,0.87,0.64,1.3l19.81,36.95c0.44,0.88,1.13,1.75,1.35,2.7H149.98z"
    />
  </svg>
);

export default DeductionIcon;
