import React from "react";

const DebitCardIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    className="promises-img"
  >
    <g>
      <path
        class="st0"
        d="M74.62,146.09l0.16-6.04l-38.13-0.02c-1.55,0.01-2.21,0.24-3.23-0.38c-2.04-0.6-2.05-1.73-2.04-4.19
		l0.01-44.53l129.86,0.09l0.09,4.27l6.07,0c0.48-6.84,0.07-15.19,0.07-22.19c0-7.93,1.35-14.21-4.08-17.92
		c-3.8-2.59-14.97-1.5-20.38-1.5H45.8c-7.47,0-14.98-1.37-18.74,3.5c-3.03,3.93-1.89,14.05-1.89,19.79c0,14.56,0,29.11,0,43.67
		c0,4.98-0.82,16.98,1.07,20.62c3.1,5.95,9.33,4.78,17.09,4.78C57.54,146.05,60.55,145.5,74.62,146.09 M31.48,73.32
		c-0.24-1.92-0.3-8.85-0.08-10.77c0.4-3.55,4.21-2.67,7.71-2.67h114.81c3.65,0,7.05-0.84,7.33,3c0.17,2.41,0.17,8.06-0.08,10.48
		L31.48,73.32z"
      />
      <path
        class="st1"
        d="M100.27,134.93c-2.35,0-4.23-1.66-4.23-5.43c0-5.66,3.03-13.16,8.18-13.16c2.35,0,4.23,1.66,4.23,5.44
		C108.45,127.44,105.41,134.93,100.27,134.93 M99.06,146.55c13.27,0,22.82-12.24,22.82-25.97c0-8.47-5.89-15.85-16.48-15.85
		c-13.27,0-22.82,12.24-22.82,25.97C82.59,139.17,88.48,146.55,99.06,146.55z"
      />
      <path
        class="st1"
        d="M163.19,139.34c-1.77,0-2.86-1.43-2.86-3.2c0-2.86,1.66-5.6,4.18-5.6c1.77,0,2.86,1.43,2.86,3.2
		C167.37,136.59,165.71,139.34,163.19,139.34 M136.53,120.69c-1.77,0-2.86-1.43-2.86-3.2c0-2.86,1.66-5.61,4.17-5.61
		c1.77,0,2.86,1.43,2.86,3.2C140.71,117.94,139.05,120.69,136.53,120.69z M162.96,146.26c7.26,0,12.07-6.41,12.07-12.87
		c0-5.49-3.49-9.78-10.3-9.78c-7.26,0-12.07,6.41-12.07,12.87C152.67,141.97,156.16,146.26,162.96,146.26z M126.01,145.63h8.64
		l40.39-40.04h-8.64L126.01,145.63z M136.3,127.61c7.26,0,12.07-6.41,12.07-12.87c0-5.49-3.49-9.78-10.3-9.78
		c-7.27,0-12.07,6.41-12.07,12.87C126.01,123.32,129.5,127.61,136.3,127.61z"
      />
    </g>
  </svg>
);

export default DebitCardIcon;
