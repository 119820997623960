import React from "react";

const RateIcon = () => {
  return (
   
    

    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      className="promises-img"
    >
      <style type="text/css">
        {`
        .st0 { fill-rule:evenodd; clip-rule:evenodd; fill:#E5C16E; }
        .st1 { fill:#E5C16E; }
        .st2 { fill:#FFFFFF; }
      `}
      </style>
      <g>
        <polygon
          className="st1"
          points="24.26,124.85 34.04,100.85 49.74,92.41 54.19,82.93 118.78,48.26 141.59,58.78 147.96,88.56 158.63,94.04 164.41,126.04 99.22,161.74"
        />
        <path
          className="st2"
          d="M149.08,88.47l-5.58-30.86L118.1,46.78L52.43,82.06l-0.03-0.02l-3.2,8.68l-17.16,9.23L32,99.94l-9.71,26.4l77.52,36.88l66.56-36.56l-6.03-33.38L149.08,88.47z M156.99,95.11l-62.75,34.47l-8.01-3.8l62.35-34.25L156.99,95.11z M146.19,89.4l-62.15,34.14l-4.58-25.38l-0.32-1.77l62.15-34.14L146.19,89.4z M118.25,50.11l21.9,9.33L77.41,93.91L55.8,83.66L118.25,50.11z M54.08,86.18l22.03,10.45l0.32,1.79l4.49,24.85l-34.47-16.34L54.08,86.18z M47.64,94.98l-3.87,10.53l-8.35-3.96L47.64,94.98z M33.69,104.06l9.03,4.28l-0.04,0.1l13.06,6.2l0.3,1.65l4.49,24.85L26.07,124.8L33.69,104.06z M59.1,116.23l9.09,4.31l-5.58,15.16L59.1,116.23z M63.29,142.57l7.63-20.73l22.03,10.45l0.32,1.79l4.49,24.85L63.29,142.57z M100.88,159.2l-4.58-25.38l-0.32-1.77l62.15-34.14l4.91,27.15L100.88,159.2z"
        />
      </g>
    </svg>
  );
};

export default RateIcon;
