import { Box, Divider, Grid, List, ListItem } from "@mui/material";
import React from "react";

export default function TermsAndCondition() {
  return (
    <div className="about-us">
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-main-header-text">
              TERMS & CONDITIONS
            </div>
            <div className="privacy-policy-normal-text">
              Thank you for visiting www.csjewels.com. This site is owned and
              operated by M/s. Chandukaka Saraf Jewels Private Limited (referred
              to as "www.csjewels.com"). <br />
              <br />
              By accessing and shopping on this site, you agree to comply with
              and be bound by these Terms & Conditions. We reserve the right to
              modify or update these Terms & Conditions at our discretion.
              Continued use of the site following any changes constitutes your
              acceptance of those changes. Our objective at www.csjewels.com is
              to provide a secure and enjoyable shopping experience. All
              products and information on the site constitute an "invitation to
              offer." www.csjewels.com reserves the right to accept or reject
              your offer. Your purchase order is considered your "offer" and is
              subject to the following terms and conditions.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              Eligibility to Use Our Site
            </div>
            <div className="privacy-policy-normal-text">
              Use of the site is restricted to individuals who are legally
              capable of entering into binding contracts under applicable laws.
              Individuals deemed "incompetent to contract" under the Indian
              Contract Act, 1872, including undischarged insolvents, are not
              eligible to use the site. www.csjewels.com reserves the right to
              terminate access if it discovers that a user is under the age of
              18 or otherwise lacks legal capacity.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>

      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">Membership</div>
            <div className="privacy-policy-normal-text">
              While creating an account is not mandatory to shop, registered
              users must provide accurate and complete information as prompted
              by the site's registration form. Registration is void where
              prohibited by law. www.csjewels.com reserves the right to revoke
              or terminate your registration at any time without notice.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>

      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              Electronic Communications
            </div>
            <div className="privacy-policy-normal-text">
              By using the site or communicating with us electronically, you
              consent to receive communications from us electronically. This
              includes, but is not limited to, emails and other data related to
              your use of the site.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>

      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              Reviews, Feedback, and Submissions
            </div>
            <div className="privacy-policy-normal-text">
              All reviews, comments, feedback, suggestions, and other
              submissions (collectively, "Comments") provided to
              www.csjewels.com are considered the property of www.csjewels.com.
              By submitting Comments, you grant www.csjewels.com all rights to
              use, reproduce, modify, publish, and distribute the Comments
              without restriction or compensation to you. You acknowledge that
              www.csjewels.com is under no obligation to maintain the
              confidentiality of Comments or to respond to them. You agree that
              your Comments will not infringe on any third-party rights or
              contain defamatory, unlawful, or offensive material. You are
              responsible for the content of your Comments and agree to
              indemnify www.csjewels.com for any claims arising from them.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>

      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              Accuracy of Content and Product Information
            </div>
            <div className="privacy-policy-normal-text">
              www.csjewels.com strives to provide accurate product descriptions
              and pricing. However, typographical errors may occur. In the event
              of incorrect pricing or product information, www.csjewels.com
              reserves the right to correct the information or cancel the order
              at our discretion, unless the product has already been dispatched.
              If an order is canceled, you will be notified, and any charges
              will be refunded.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>

      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              Contact Information
            </div>
            <div className="privacy-policy-normal-text">
              For questions or concerns about this Privacy Policy or our
              practices, please contact us at:
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>

      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="">
              <strong>Email</strong> : care@csjewels.com
            </div>
            <div className="">
              <strong>Phone: </strong> : 07969991827
            </div>
            <div className="">
              <strong>Address:</strong> : 1st Floor, Office No 1A and 1B,
              Mudliyar Chambers Co Op Hsg Society, Mudliyar Road, Rasta Peth,
              Pune, Pune, Maharashtra 411011
            </div>

            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
