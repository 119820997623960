import React from "react";

const DiamondsIcon = () => (

  


      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        className="promises-img"
      >
        <g>
          <path
            class="st0"
            d="M121.66,55.95H78.34l-10.46,34.8h64.25c-0.32-1.37-0.87-2.85-1.31-4.29l-7.84-25.99
		C122.52,58.86,122.01,57.47,121.66,55.95z"
          />
          <path
            class="st0"
            d="M130.91,98.29h-44.3c0.58,1.09-0.14,0.36,2.08,1.08c0.84,0.27,1.62,0.72,2.11,1.3
		c0.55,0.66,0.76,1.13,1.05,2.11c0.8,2.73,3.46,0.37,3.75,6.22c0.11,1.99,2.2,1.98,2.2,4.61c0,1.24-0.67,2.63-0.58,3.07
		c0.01,0.03,0.54,0.95,0.65,1.24c0.68,1.65,0.44,3.09-0.51,4.51c-1.22,1.83-0.53,0.3-0.53,3.36c0,2.16-1.49,3.38-2.92,4.13
		c0,2.2-0.41,3.28-1.7,4.14l3.42,8.27c0.39,0.89,0.72,1.76,1.14,2.75l4.69,11.12c0.69-0.51,9.73-18.74,11.11-21.5l14.77-29.04
		C128.14,104.12,130.59,99.65,130.91,98.29z"
          />
          <path
            class="st0"
            d="M92.04,142.52c-0.67-1.7-4.12-9.34-4.21-10.4c0.76,0,1.51-0.09,2.04-0.39c1.65-0.91,0.22-1.99,1.03-3.6
		c0.5-0.99,0.74-0.78,1.65-1.28c2.02-1.12,0.39-2.39,0.39-3.74c0-1.74,1.95-1.83,1.95-3.41c0-0.97-1.46-1.95-1.46-2.92
		c0-1.18,0.97-1.84,0.97-2.67c0-1.54-1.02-1.52-1.77-2.36c-0.97-1.08-0.41-1.69-0.41-3.48c0-1.15-1.69-1.29-2.49-1.89
		c-1.41-1.06-0.62-2.14-1.35-3.26c-0.7-1.07-2.26-0.38-3.36-1.03c-0.57-0.34-1.35-2.14-1.8-2.82c-1.11,0-3.1,0.39-3.77,0.12
		c-1.4-0.55-0.69-1.83-3.05-1.83c-0.79,0-0.93,0.98-2.67,0.98c-1.47,0-2.54-2.25-4.25-0.11c-0.29,0.36-0.89,1.08-1.35,1.08
		l-2.66-0.24c-2.18,0.2-1.26,2.31-3.09,3c-1.91,0.72-2.85-0.65-3.27,1.85c-0.34,2.08-0.43,2.18-2.28,2.83
		c-1.98,0.7-1.11,1.64-1.11,3.5c0,1.55-1.22,1.53-2.02,2.64c-1.04,1.41,0.56,1.82,0.56,3.69c0,1.59-1.22,1.33-1.22,2.92
		c0,1.74,1.7,1.84,1.7,2.92c0,1.39-0.48,1.91-0.48,2.92c0,1.16,1.41,1.4,2.13,2c1.05,0.87,0.79,1.85,0.79,3.6
		c4.11,2.75,4.86-0.78,1.74,6.84l-6.12,14.81c3.19-0.74,6.2-2.21,9.25-2.92c0.17,0.72,3.64,7.97,4.38,8.52
		c0.76-1.45,1.31-3.17,2.03-4.78l6-14.44c0.03,0.05,0.07,0.07,0.09,0.15c0.02,0.12,0.14,0.26,0.18,0.32
		c0.14,0.28,0.15,0.3,0.31,0.66l7.95,18.1l4.13-8.52c0.51,0.24,1.66,0.51,2.23,0.7c1.5,0.5,6,2.14,7.02,2.22l-2.16-5.14
		C93.45,145.86,92.76,144.34,92.04,142.52z M63.43,129.02c-2.25-2.25-4.56-6.14-4.56-10.53c0-8.29,6.78-15.09,15.09-15.09
		c8.06,0,14.85,6.49,14.85,15.33C88.81,131.34,72.8,138.4,63.43,129.02z"
          />
          <path
            class="st0"
            d="M129.21,54.73c0.37,1.6,0.83,2.74,1.27,4.33l9.2,30.71c3.54-0.82,10.15-3.04,14.12-4.13
		c2.39-0.66,4.67-1.39,7.14-2.1l7.22-2.04c-0.1-1.12-9.09-14.24-10.27-16.01l-7.79-11.93c-0.62-0.97-2.38-3.25-2.63-4.19
		c-0.8,0.19-1.53,0.49-2.24,0.68l-11.3,3.31C132.42,53.86,130.66,54.39,129.21,54.73z"
          />
          <path
            class="st0"
            d="M46.02,85.59c3.97,1.11,10.82,3.37,14.31,4.19l10.46-35.05c-1.4-0.32-3.09-0.84-4.54-1.31l-13.72-4.05
		c-0.24,0.91-1.91,3.08-2.54,4.04l-15.62,23.8c-0.51,0.78-0.86,1.3-1.36,2.04c-0.47,0.7-1.09,1.28-1.17,2.24l7.03,1.98
		C41.19,84.15,43.75,84.95,46.02,85.59z"
          />
          <path
            class="st0"
            d="M73.96,104.63c-4.1,0-7.6,1.87-9.67,3.95c-1.18,1.17-2.05,2.39-2.83,3.99c-0.62,1.29-1.37,3.92-1.37,5.69
		c0,12.04,14.7,18.57,23.31,9.92C92.71,118.79,85.33,104.63,73.96,104.63z M77.17,123.41c-0.43,0.68-0.68,1.06-1.12,1.79
		c-0.46,0.76-0.8,1.27-1.36,1.81c-1.97-1.31-3.56-3.23-5.49-4.73l-2.72-2.39c-3.29-3.05-3.01-2.11-1.17-4.46
		c0.51-0.67,0.96-1.14,1.6-1.56c0.91,0.24,0.97,0.67,1.65,1.28c0.63,0.57,0.93,0.68,1.58,1.34c0.49,0.49,2.89,2.73,3.35,2.73
		c0.67,0,0.78-0.39,1.06-0.88l4.84-7.81c0.83-1.07,0.96-0.68,2.5,0.28c0.77,0.47,1.37,0.9,2.05,1.36
		C83.65,113.42,78.08,121.97,77.17,123.41z"
          />
          <path
            class="st0"
            d="M139.67,98.05l-14.28,28.07c-1.35,2.71-2.58,5.5-4.11,8.06c-0.72,1.19-3.75,7.1-4,8.17l36.81-41.56
		c0.57-0.64,0.68-0.93,1.34-1.58l7.12-8.21c-1.1,0.09-3.34,0.95-4.54,1.3C155.67,92.99,140.36,97.06,139.67,98.05z"
          />
          <path
            class="st0"
            d="M123.12,48.41c1.76,0,4.38-1.06,6.03-1.52c1.32-0.37,4.79-1.62,6.13-1.65v-0.49c-1.66-0.04-10-3.16-11.68-3.16
		H76.39c-1.72,0-9.98,3.12-11.68,3.16v0.49c2.2,0.05,9.58,3.17,12.17,3.17H123.12z"
          />
          <path
            class="st0"
            d="M55.7,103.9c0.07-2.74,2.35-4.81,5.11-4.87c-1.08-2.05-0.28-1.22-5.08-2.71L37.45,91
		c0.53,0.78,1.06,1.44,1.77,2.13l10.95,12.41c0.8,0.81,1.01,1.71,2.13,2.01c0.07-0.89,0.57-1.76,1.08-2.33
		C54.04,104.46,54.76,104.11,55.7,103.9z"
          />
          <polygon
            class="st0"
            points="117.24,142.63 117.28,142.35 117.04,142.35 	"
          />
        </g>
      </svg>
   
   
  );


export default DiamondsIcon;
