import React from "react";

const AstrologyIcon = () => {
  return (


      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        className="promises-img"
        viewBox="0 0 200 200"
      >
        <style type="text/css">
          {`.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#E5C16E;}
	.st1{fill:#E5C16E;}
	.st2{fill:#FFFFFF;}`}
        </style>
        <g>
          <path
            class="st0"
            d="M59.16,25.54c0.03,0.03,0.07,0.05,0.09,0.07c0.02,0.02,0.06,0.05,0.09,0.07l0.27,0.17
		c0.23,0.11-0.16,0.04,0.3,0.06c-0.17,0.21-0.31,0.26-0.47,0.65l0.39,0.19c-0.02-0.03-0.04-0.13-0.05-0.1l-0.17-0.23
		c0.47-0.11,0.65-0.29,0.93-0.62c1.04-1.18,10.73-5.09,12.48-5.72c0.82-0.3,4.43-1.52,5.12-1.54l1,3.88
		c-1.99,0.47-5.14,1.59-7.15,2.4c-0.26,0.1-0.41,0.14-0.65,0.24l-4.85,2.14c-4.88,2.41-8.65,4.51-13.11,7.86l-7.61,6.37
		c-0.47,0.48-1.31,1.42-1.8,1.75c-0.28-0.19-2.65-2.44-2.77-2.89c0.37-0.55,3.65-3.57,4.39-4.15c0.23-0.18,0.27-0.25,0.46-0.42
		l2.37-1.96c0.89-0.71,5.97-4.52,6.98-5c0.49-0.24,0.88-0.14,1.22-0.67l-0.52,0.04c0.04-0.35,0.1-0.4,0.11-0.64
		c-0.74-0.38-0.53-0.16-0.59-0.71c0-0.04,0-0.07-0.01-0.12l-0.06-0.23c0-0.06-0.02-0.08-0.04-0.11c0,0.7-0.69,0.54-0.71,0.92
		c-0.01,0.31,0.08,0.15,0.46,0.2c0.34,0.05,0.37,0.07,0.69,0.1c-0.14,0.59-1.37,1.23-1.89,1.55l-2.04,1.4
		c-1.66,1.2-5.18,3.83-6.69,5.29c-0.23,0.22-0.27,0.22-0.49,0.4l-4.09,3.79l-0.27-0.29c-0.02-0.02-0.04-0.04-0.05-0.06
		c-0.58-0.64-0.83-1.12-1.34-0.87c0.16,0.61,0.99,1.04,1.33,1.55l-7.99,9.54l-3.57,5.31c-0.18,0.28-0.57,0.86-0.64,1.13l-0.33-0.44
		v0.67c0.01,0,0.34-0.02,0.38-0.02l0.62-0.09c-0.3-0.12-0.39,0.17,0.36-0.98l3.53-5.24c0.5-0.7,0.96-1.35,1.52-2.03
		c0.54-0.67,0.96-1.28,1.51-1.93l4.1-4.67c0.31-0.33,0.62-0.55,0.86-0.91l2.91,2.86l-2.51,2.76c-0.29,0.38-0.58,0.56-0.86,0.92
		c-1.06,1.32-2.09,2.49-3.12,3.87c-4.53,6.03-5.97,8.72-9.18,15.23c-1.49,3.02-2.89,6.95-3.9,10.19l-0.76,2.79l-3.88-1.11
		c0.15-1.8,3.19-9.71,3.92-11.4c0.18-0.42,0.28-0.66,0.46-1.09l2.03-4.19c0.52-1.06,0.22-0.75,0.7-0.96
		c-0.03-0.02-0.07-0.05-0.09-0.06c-0.24-0.18-0.43-0.38-0.73-0.45l0.03-0.21c0.09-0.72,0.01-0.53-0.38-0.82
		c-0.02-0.01-0.07-0.02-0.08-0.06c-0.01-0.04-0.06-0.04-0.08-0.06c0.13-0.36,0.07-0.25,0.27-0.49c-0.3-0.3-0.28-0.17-0.44-0.62
		c0.4-0.33,0.69-0.29,0.49-0.99c1.16,0.11,0.87,0.23,0.94-0.72c0-0.03,0.06-0.32,0.06-0.4c0-0.56,0.03-0.21-0.11-0.33
		c-0.16,0.34-0.22,0.71-0.22,1.22c-0.54-0.05-0.61-0.28-1.22-0.34c0.12,0.26,0.3,0.76,0.33,1.11h-0.89
		c0.11,0.42,0.55,0.77,0.78,1.11c-0.32,0.22-0.68,0.46-0.89,0.78c0.48,0.01,0.61,0.11,1.22,0.11c-0.04,0.47-0.23,0.78-0.33,1.22
		c0.38-0.03,0.41-0.08,0.67-0.22l-1.48,3.07c-0.92,1.85-1.57,3.43-2.36,5.41c-1.19,2.96-1.82,5.11-2.7,8.07
		c-0.1,0.35-0.21,0.93-0.35,1.21c-0.23-0.11-2.13-0.64-2.44-0.66c0.01,0.51-0.19,0.4,0.89,0.66c1.63,0.4,1.43,0.4,1.34,0.87
		c-0.29,1.41-0.81,3.24-1.08,4.82c-0.84,4.85-1.08,7.16-1.47,11.85c-0.09,1.14,0.29,1.15-0.36,2.07c-0.21,0.3-0.24,0.29-0.31,0.7
		l0.27,0.06c-0.02-0.47,0.22-0.67,0.52-0.84c-0.02,0.4-0.11,0.56,0.05,0.95c0.13,0.32,0.22,0.23,0.6,0.2
		c-0.09,1,0.12,0.99,0.82,1.05l-0.14,0.35c0.43-0.14,0.54-0.09,0.66-0.56c-1.57-0.37-1.25,0.22-0.89-1.33
		c-0.27,0.13-0.48,0.21-0.89,0.22c0-2.34,0.27-5.51,0.54-7.9c0.1-0.93,0.21-1.77,0.32-2.57c0.32-2.22,0.78-4.97,1.32-7.11
		c0.17-0.66,0.37-1.8,0.6-2.29c0.62,0.05,1.32,0.33,1.95,0.49c0.59,0.15,1.42,0.46,1.93,0.51c-0.9,3.84-1.26,5.3-1.85,9.36
		l-0.56,5.1c-0.06,0.44,0,0.86-0.04,1.3l-0.1,2.78c0,0.64-0.1,0.76-0.12,1.33l0.11,4.11c0,1.13,0.47,5.4,0.56,6.54
		c0.22,2.68,1.16,6.93,1.77,9.55c-0.37,0.18-1.41,0.38-1.93,0.51c-0.7,0.18-1.29,0.34-1.95,0.49c-0.45-0.94-1.62-7.84-1.74-8.8
		c-0.19-1.48-1.03-8.63-0.7-9.73l-0.46,0.76c-0.1-0.14-0.05-0.07-0.13-0.17c-0.33-0.42-0.17-0.3-0.4-0.36
		c0.26,0.64,0.45,0.61,0.53,1.02l0.25,4.19c0.13,1.74,0.42,3.66,0.65,5.34c0.62,4.56,1.98,9.97,3.43,14.32
		c1.34,4,1.96,5.12,3.39,8.59c0.47,1.14,1.82,3.35,1.95,4.15c0.27-0.22,0.22,0.32,0.22-0.35l0.22,0.13l-3.09-6.67
		c-1.32-3.2-2.43-6.18-3.37-9.5c-0.17-0.59-0.63-1.9-0.64-2.58c0.89-0.07,3.04-0.87,3.88-0.89c0.89,3.8,2.8,9.11,4.44,12.76
		l2.24,4.64c2.86,5.36,5.18,8.8,8.87,13.43l4.09,4.68c-0.12,0.43-1.37,1.55-1.86,2.02c-0.41,0.39-0.46,0.28-0.36,0.75
		c0.94-0.63,1.7-1.76,2.55-2.33l4.63,4.35c0.35,0.35,0.59,0.48,0.94,0.83c0.22,0.22,0.23,0.21,0.48,0.41
		c3.15,2.46,4.05,3.36,7.73,5.81l5.6,3.39l4.79,2.42c0.45,0.18,0.79,0.4,1.28,0.6c2.73,1.14,7.75,3.13,10.72,3.82l-0.73,2.96
		c-0.15,0.51-0.24,0.47,0.28,0.59c0.06-0.67,0.42-1.78,0.6-2.51c0.05-0.2,0.11-0.52,0.16-0.63c0.13-0.33,0.12-0.26,0.38-0.23
		l3.75,0.93c2.89,0.48,4.67,0.95,8.01,1.31c2.38,0.26,4.87,0.48,7.31,0.46c1.47-0.01,0.82,0.25,2.9,0.02l4.53-0.13
		c2.79-0.22,8.27-0.93,10.88-1.55l2.66-0.55c0.02,0.29,0.9,3.67,1,3.89l-4.25,0.97c-0.85,0.14-1.39,0.27-2.27,0.39
		c-2.21,0.32-4.87,0.8-7.14,0.86c-0.56,0.01-0.62,0.11-1.22,0.12l-2.56,0.11c-0.15,0-0.55,0.03-0.67,0.02
		c-0.79-0.07-0.35,0.07-0.55-0.24c-0.64,0-0.11-0.05-0.33,0.22c0.03,0.02,0.09,0,0.11,0.05c0.26,0.56-0.09,0,0.31,0.12l2.35,0.16
		l3.92-0.3c2.42-0.31,3.9-0.41,6.48-0.84c1.57-0.26,3.28-0.59,4.8-0.97c0.76-0.19,1.56-0.35,2.32-0.57
		c0.76-0.22,1.57-0.44,2.34-0.65c1.44-0.4,2.97-0.89,4.36-1.41c0.4-0.15,0.69-0.23,1.06-0.38l3.18-1.25
		c2.04-0.81,3.98-1.76,5.93-2.73c0.61-0.31,1.46-0.88,2-1c-0.14,0.29-0.2,0.6-0.33,0.89c0.65-0.05,0.45-0.29,1.22-0.33
		c-0.3-0.23,0.44-0.25-0.56-0.18l-0.19-0.32c-0.25-0.38-0.37-0.32-0.4-0.33l-0.29-0.28c0.16-0.39,0.18-0.28,0.43-0.67
		c-0.2-0.23,0.01-0.09-0.33-0.22c0.01,0.02,0.05,0.25,0.11,0.33c-0.35,0.19-0.67,0.35-0.89,0.67c0.24,0.11,0.16,0.04,0.34,0.1
		l0.25,0.09c0.02,0.01,0.05,0.02,0.08,0.03c-0.36,0.1-1.78,0.89-2.22,1.11c-2.61,1.3-4.11,1.98-6.9,3.09
		c-1.32,0.53-8.78,3.2-10.19,3.23c-0.31-1.32-0.7-2.59-1-3.88c0.37-0.18,0.9-0.21,1.33-0.33c5.03-1.42,10.13-3.27,14.77-5.65
		c0.22-0.11,0.34-0.17,0.59-0.29c0.42-0.21,0.77-0.41,1.17-0.61c1.79-0.88,5.13-2.94,6.61-3.93c0.39-0.26,0.68-0.45,1.07-0.71
		c0.39-0.26,0.63-0.46,1.04-0.73c2.32-1.53,4.03-3.17,5.97-4.68c0.41-0.31,0.58-0.54,0.95-0.82l2.77-2.55
		c0.42,0.11,2.6,2.35,2.89,2.77c-0.68,1.02-4.91,4.44-6.08,5.46c-0.31,0.27-0.61,0.46-0.94,0.73c-0.63,0.52-1.23,0.94-1.89,1.44
		l-5.43,3.7c-0.42,0.26-0.31,0.39-0.42-0.11c-0.31,0.26-0.09-0.04-0.12,0.31c0,0.03-0.01,0.06-0.01,0.1c0,0.37,0.16,0.65,0.52,0.73
		l-0.4,0.67c0.18,0.11,0.28,0.2,0.37,0.37c0.1,0.19,0.11,0.21-0.03,0.44c-0.02,0.02-0.09,0.11-0.11,0.14c1.57-0.25,0.18-0.44,0-1.11
		c0.32-0.09,0.18-0.04,0.44-0.23c0.2-0.15,0.26-0.15,0.34-0.44c-0.33-0.08-0.34-0.11-0.78-0.11v-0.22c0.33-0.09,0.74-0.38,1-0.55
		c2.94-1.95,8.84-6.07,11.23-8.52c0.34-0.35,0.56-0.45,0.89-0.78l1.23-1.21c0.36-0.3,0.28-0.01,0.4-0.48
		c0.5,0.34,0.65,0.94,1.22,0.67c-0.15-0.42-0.05-0.05-0.58-0.64l-0.15-0.18c-0.01-0.01-0.03-0.04-0.05-0.06
		c0.24-0.36,0.54-0.6,0.86-0.91c0.36-0.35,0.49-0.6,0.83-0.95l3.39-3.71c1.18-1.24,3.84-4.86,4.75-6.12
		c0.28-0.38,0.46-0.68,0.73-1.05c0.26-0.35,0.46-0.67,0.72-1.06c0.33-0.49,1.28-1.85,1.38-2.4c-0.74,0.26,0.58-0.34-0.54-0.02
		c-0.77,0.22-0.04-0.13-0.35,0.24c0.56,0,0.21-0.03,0.33,0.11l-0.67,1c-2.01,3-2.75,4.08-5.1,7.1l-3.19,3.8
		c-0.28,0.31-0.56,0.52-0.83,0.83c-0.74,0.83-1.79,2.13-2.64,2.69l-2.86-2.8c0.3-0.46,1.19-1.25,1.67-1.72l2.5-2.72
		c0.28-0.3,0.56-0.64,0.82-0.95c0.55-0.68,1.05-1.25,1.6-1.95c1.03-1.32,2.05-2.61,2.98-4.01c1.72-2.6,3.17-4.7,4.69-7.52l2.89-5.88
		c1.27-2.98,1.87-4.61,2.9-7.53l0.79-2.76c0.87,0.2,3.3,1.06,3.88,1.11c-0.02,0.89-1.77,5.55-2.18,6.7l-3.7,8.28
		c-0.16,0.33-0.3,0.6-0.48,0.97c-0.2,0.41-0.43,0.62-0.52,1.03l-0.33-0.22c0,0.44,0.07,0.29,0.33,0.42l0.36,0.16
		c0.25,0.12,0.26,0.28,0.31-0.01v0.31c0.15-0.11,0.2,0,0.33-0.55c-0.34,0.01-0.4,0.05-0.67,0.11c1.43-2.69,2.82-5.49,4-8.43
		c0.58-1.47,1.17-2.96,1.71-4.51l1.1-3.57c0.33-1,0.31-0.01,0.86-0.58c-0.27-0.37-0.12-0.12-0.55-0.33
		c1.24-5.3,1.83-6.98,2.57-12.97l0.54-5.68c0.09-1.61-0.2-1.56,0.44-2.77h1c-0.08-0.36-0.02-0.33-0.33-0.56l0.04,0.29
		c-0.58-0.02-0.39-0.02-0.72,0.02c-0.33,0.05,0.19,0.09-0.28,0.02c-0.68-0.11,0.05-0.37-0.71,0.17l-0.33,0.28
		c0.32-0.11,0.15-0.04,0.26,0c0.54,0.19,0.25,2.06,0.21,2.37c-0.13,0.96-0.04,1.97-0.17,2.94c-0.74,5.67-0.84,7.6-2.26,13.28
		c-0.2,0.8-0.41,1.88-0.71,2.51c-1.07-0.25-2.48-0.81-3.77-1.11l1.13-4.75c0.65-2.76,1.37-7.34,1.53-10.23
		c0.1-1.85,0.34-3.69,0.33-5.66c0-0.96,0-1.92,0-2.89c0.01-5.32-0.83-10.75-1.84-15.92c-0.17-0.85-0.49-1.64-0.49-2.51
		c0.5-0.01,1.48-0.3,1.9-0.43c0.48-0.14,1.46-0.45,1.98-0.46c0.03,0.39,0.23,1.16,0.33,1.56c0.6,2.44,1.45,7.29,1.66,9.76l0.11,1.33
		c0.11,0.63-0.13,0.21,0.23,0.44L185,91.3c0-0.95-0.22-2.22-0.35-3.15l-1.76-8.9c0.44-0.1,0.56-0.03,0.78-0.33
		c-0.37-0.39-0.36-0.24-0.89-0.11c-0.03-0.4-0.22-1.01-0.32-1.35c-0.65-2.18-1.22-4.24-2.03-6.41c-1.54-4.11-2.59-6.67-4.65-10.55
		c-0.44-0.84-0.21-0.94-0.66-1.22l-0.45,0.24c-0.46,0.1-0.48,0.3-0.69,0.72l-0.29-0.2c-0.03-0.02-0.06-0.04-0.09-0.06
		c-0.16-0.1-0.05-0.05-0.26-0.15c0.15,0.38,0.32,0.66,0.67,0.89c0.17-0.32,0.35-0.62,0.44-1c0.43,0.03,0.48,0.1,0.85,0.81
		c2.84,5.39,5.05,11.06,6.69,16.94c0.12,0.44,0.32,0.91,0.33,1.44c-0.58,0.13-1.29,0.33-1.93,0.52c-0.54,0.16-1.42,0.44-1.96,0.48
		c-0.01-0.54-0.55-2.25-0.73-2.82c-1.15-3.53-1.35-4.34-2.77-7.88c-1.42-3.56-3.94-8.52-6.02-11.74c-1.17-1.82-3.02-4.68-4.33-6.32
		c-0.54-0.67-1.05-1.32-1.57-1.98l-2.02-2.41c-0.17-0.17-0.29-0.26-0.45-0.44c-0.19-0.22-0.19-0.28-0.39-0.5l-1.27-1.39
		c-0.31-0.32-0.62-0.56-0.86-0.91l2.89-2.66c0.51,0.34,1.82,1.83,2.41,2.47l2.3,2.7c0.51,0.66,0.96,1.22,1.48,1.85
		c1.09,1.32,2.45,3.3,3.47,4.74l1.31,2.02c0.32,0.48,0.35,0.54,0.35,1.31c-0.19,0.11-0.14,0.06-0.44,0.11
		c0.23,0.26-0.39,0.22,0.55,0.22c0.25-0.34,0.2-0.31,0.19-0.8c0.61,0.5,0.07,0.61,0.92,0.61c0.22-0.5,0.38-0.35,0.44-1.04
		c-0.35,0.16-0.35,0.26-0.73,0.72c-0.01,0.01-0.03,0.04-0.05,0.06c-0.15-0.57-2.87-4.53-3.31-5.12l-7.64-9.33
		c-0.32-0.32-0.56-0.62-0.92-0.86c0.23-0.34,0.33-0.25,0.44-0.67c-0.51-0.24-0.37-0.05-0.78,0.22c-0.47-0.31-1.82-1.81-2.74-2.58
		l-1.45-1.33c-0.18-0.17-0.26-0.28-0.45-0.43l-2.98-2.46c-1.65-1.34-4.53-3.48-6.31-4.56c-0.58-0.35-1.74-1.28-2.38-1.4l0.12,0.35
		c-0.13,0.1-0.07,0.02-0.19,0.16c-0.19,0.22,0.04-0.12-0.15,0.27c0.78-0.13,0.75-0.01,1.33,0.34c0.37,0.23,0.67,0.44,1.07,0.7
		c3.97,2.65,4.81,3.49,8.19,6.12l2.84,2.49c0.78,0.6,2.07,2.19,2.77,2.66c-0.33,0.49-0.92,0.97-1.36,1.41
		c-0.41,0.42-1.06,1.11-1.52,1.36c-0.58-0.39-1.24-1.23-1.8-1.75c-0.23-0.21-0.27-0.2-0.5-0.39c-0.18-0.15-0.29-0.27-0.45-0.44
		c-3.19-3.22-9.52-7.68-13.34-9.97c-6.02-3.6-11.92-6.12-18.66-8.08c-0.48-0.14-1.02-0.26-1.43-0.46c0.19-0.39,0.99-3.26,1-3.77
		c0.73,0,4.05,1.11,4.93,1.4c1.64,0.55,3.13,1.11,4.7,1.74l4.4,1.93c0.39,0.2,0.64,0.32,1.04,0.52c0.21,0.1,0.3,0.14,0.53,0.25
		c1.45,0.65,1.91,0.8,2.17,1.93c-0.56,0-0.57-0.01-1-0.11c0.1,0.42-0.04,0.17,0.33,0.33l0.91-0.02l-0.28-1.23l0.37,0.03
		c-0.23-0.36,0.02-0.03-0.18-0.24c-0.59-0.61-7.2-3.44-8.25-3.86c-0.73-0.29-1.51-0.6-2.24-0.87c-0.78-0.29-1.55-0.51-2.31-0.8
		l-3.55-1.11c-0.41-0.12-0.87-0.29-1.33-0.33c0.05-0.56,0.22-0.54,0.22-1.22h-0.45c-0.01,0.45-0.13,0.73-0.22,1.11
		c-0.4-0.19-3.19-0.88-3.77-0.89l-0.56,0.11c0.22,0.28-0.31,0.15,1.05,0.4c0.44,0.08,0.75,0.16,1.09,0.24
		c0.64,0.16,1.59,0.34,2.09,0.58c-0.14,0.29-0.99,3.41-1,3.77l-4.84-1.05c-5.69-1.06-10.01-1.5-16.14-1.5
		c-5.77,0-12.77,0.86-18.29,2.24c-0.62,0.15-1.83,0.39-2.35,0.64c-0.24-0.51-0.4-1.27-0.55-1.89c-0.11-0.45-0.39-1.66-0.55-1.99
		c1.58-0.76,10.2-2.17,12.02-2.41l3.31-0.35c1.39-0.13,0.84,0.14,1.09-0.24l-1.67-0.11c-2.25,0-9.19,1.26-11.32,1.78
		c-0.6,0.15-1.25,0.28-1.8,0.42c-0.62,0.15-1.19,0.33-1.75,0.47c-0.29-1.26-0.17-1.32-0.78-1.33c0.04,0.53,0.28,0.87,0.33,1.44
		c-2.35,0.55-7.55,2.46-9.62,3.36l-5.56,2.54c-0.35,0.17-0.68,0.37-1.09,0.57C60.38,25.21,60.28,25.54,59.16,25.54 M111.87,55.83
		c-0.39-0.03-0.7-0.15-1.09-0.25c-3.87-0.92-10.01-1.42-14.04-0.9c-1.05,0.14-1.9,0.14-2.9,0.32c-2.69,0.48-2.99,0.63-5.28,1.16
		c-0.16-0.67-0.36-1.37-0.53-2.02l-5.02-18.73c-0.36-1.37-0.72-2.83-1.12-4.2l-1.65-6.23c-0.66-2.48-0.97-2.15,0.46-2.53
		c11.37-3.04,24.11-3.33,35.61-1.03l4.65,1.01c-0.01,0.52-4.41,16.36-4.5,16.69L111.87,55.83z M44.29,43.52
		c0.44-0.66,1.8-1.76,2.58-2.53c5.44-5.34,15.24-11.5,22.09-14.42c0.83-0.36,1.59-0.71,2.41-1.03c1.84-0.72,6.04-2.23,7.88-2.66
		l2.2,8.35c0.35,1.37,0.78,2.77,1.11,4.1c0.35,1.44,0.77,2.8,1.13,4.19l2.22,8.32c0.37,1.41,0.74,2.81,1.11,4.22
		c0.37,1.39,0.78,2.78,1.11,4.21c-0.29,0.14-1.05,0.33-1.43,0.46c-1.99,0.66-2.41,0.88-4.25,1.63c-3.32,1.36-8.71,4.77-11.39,7.26
		l-2.58,2.41c-1.07-0.72-4.7-4.76-6.02-6.07l-8.06-8.14c-0.02-0.02-0.04-0.04-0.05-0.06c-1.96-2.15-4.94-4.88-7.09-7.11
		c-0.29-0.3-0.36-0.48-0.66-0.78C46.04,45.31,44.59,43.97,44.29,43.52z M132.74,67.71l-2.04-1.95c-0.28-0.22-0.29-0.23-0.54-0.46
		c-3.05-2.83-8.14-5.65-11.81-7.28c-0.94-0.42-1.88-0.76-2.88-1.11l-3.15-0.96l9.1-33.29l4.15,1.18c0.49,0.16,0.91,0.33,1.31,0.46
		c3.14,1.04,6.95,2.74,9.96,4.24c4.06,2.03,9.54,5.54,13,8.31l7.53,6.79c-0.43,0.29-5.03,5.11-6.12,5.98l-9.94,9.81
		c-0.32,0.33-0.52,0.41-0.84,0.72c-1.68,1.62-3.73,3.85-5.32,5.33L132.74,67.71z M108.88,66.82c-0.71-0.34-2.99-0.69-4.04-0.84
		c-1.53-0.21-3.13-0.27-4.62-0.38l-4.65,0.33c-1.2,0.13-3.9,0.75-4.22,0.78c-0.02-0.62-2.33-8.68-2.66-10.1
		c5.17-1.2,6.87-1.66,12.76-1.66c2.53,0,5.44,0.32,7.85,0.81l2.47,0.53l-2.48,9.17C109.14,65.95,109.07,66.41,108.88,66.82z
		 M75.81,75.48c-0.64-0.34-0.39-0.32-0.86-0.81c-0.31-0.33-0.57-0.57-0.89-0.89l-4.38-4.5c-0.31-0.33-0.62-0.55-0.86-0.91l2.47-2.3
		c0.23-0.22,0.32-0.24,0.55-0.45c0.36-0.31,0.73-0.65,1.08-0.91c3.25-2.48,5.86-4.17,9.66-5.88l5.65-2.11
		c0.25,0.51,2.64,9.8,2.66,10.1l-4.34,1.54c-0.4,0.16-0.63,0.3-0.98,0.46c-0.39,0.17-0.67,0.28-1.07,0.48
		c-2.6,1.3-4.9,2.92-7.11,4.77L75.81,75.48z M112.21,56.39c0.33,0.16,1.08,0.32,1.46,0.43c0.53,0.16,0.95,0.32,1.5,0.5
		c1.08,0.36,1.92,0.68,2.91,1.08c3.64,1.48,6.89,3.53,10.02,5.85l2.19,1.81c0.44,0.36,0.64,0.55,1.04,0.95
		c0.33,0.33,0.6,0.79,1.08,0.91c-0.17,0.62-4.93,4.99-5.85,5.91c-0.52,0.52-1.51,1.62-2.03,1.97c-0.73-0.64-0.98-1.02-1.9-1.76
		c-3-2.42-5.03-3.93-8.78-5.54c-1.09-0.46-3.29-1.32-4.41-1.58c0.01-0.43,0.18-0.96,0.29-1.37c0.13-0.46,0.24-0.9,0.36-1.31
		L112.21,56.39z M91.57,67.15l3.55-0.67c4.52-0.78,9.28-0.24,13.65,0.78c-0.06,0.69-0.83,2.74-0.89,3.44
		c-0.55,0-1.22-0.21-1.69-0.31c-0.64-0.13-1.11-0.2-1.81-0.3c-3.96-0.58-8.16-0.27-11.92,0.6C92.29,69.99,91.58,67.68,91.57,67.15z
		 M133.18,68.04c0.2-0.29,0.49-0.43,0.75-0.69l19.86-19.64c0.09-0.09,0.08-0.07,0.18-0.16l0.69-0.64c0.77-0.71,2.6-2.66,3.04-2.95
		c0.56,0.38,3.11,3.27,3.38,3.62c1.44,1.85,3.17,3.71,4.64,5.9c0.49,0.74,0.95,1.37,1.44,2.11c0.49,0.73,0.93,1.4,1.39,2.17
		c0.46,0.78,0.89,1.42,1.33,2.22c0.85,1.56,1.63,2.93,2.44,4.55c0.23,0.47,0.35,0.72,0.56,1.21c1.97,4.63,2.87,6.21,4.41,11.57
		c0.21,0.73,0.71,2.06,0.73,2.82c-0.55,0.01-1.53,0.33-2.07,0.48c-0.74,0.22-1.39,0.34-2.12,0.54c-1.33,0.36-2.75,0.72-4.13,1.09
		l-8.27,2.16c-1.39,0.39-2.73,0.74-4.13,1.09l-8.27,2.16c-0.68,0.2-3.78,1.04-4.29,1.04c-0.91-3.9-2.73-8.09-4.65-11.44
		c-1.01-1.75-1.98-3.22-3.18-4.81L133.18,68.04z M56.83,88.46c-0.54,0-23.62-6.46-24.82-6.81l-8.25-2.29l0.73-2.71
		c2.01-7.02,5.97-15.12,10.06-21.24c1.38-2.07,2.89-4.06,4.42-6.02l3.25-3.74c0.33-0.34,0.47-0.59,0.83-0.94
		c0.32-0.31,0.56-0.62,0.91-0.86l1.47,1.53c1.45,1.58,4.4,4.4,6.11,6.1c0.32,0.31,0.43,0.51,0.73,0.82l0.11,0.11
		c1.87,1.94,3.73,3.67,5.54,5.67c0.15,0.16,0.22,0.24,0.38,0.4c1.51,1.51,3.99,3.88,5.29,5.37l0.39,0.39
		c0.16,0.17,0.21,0.24,0.37,0.4l3.8,3.85c-0.99,0.66-4.62,5.56-5.19,6.46l-2.17,3.72c-0.26,0.47-0.46,0.78-0.7,1.29l-1.2,2.69
		c-0.53,1.33-1.4,3.68-1.75,5.13C57.1,87.97,57.06,88.46,56.83,88.46z M78.36,78.14l-2.25-2.19c0.94-1.34,5.32-4.43,6.65-5.17
		c2.03-1.13,4.86-2.52,7.16-3.16c0.35-0.1,0.93-0.23,1.2-0.36c0.04,0.47,0.31,1.37,0.43,1.79c0.17,0.58,0.32,1.17,0.46,1.76
		l-1.98,0.58c-2.63,0.72-7.37,3.2-9.48,4.94L78.36,78.14z M109.21,67.37c1.53,0.36,3.02,1,4.35,1.54c2.64,1.08,7.02,3.75,9.11,5.76
		l1.52,1.47L122,78.39c-0.32-0.22-0.4-0.37-0.67-0.61c-2.5-2.22-5.87-4.32-8.98-5.55c-0.97-0.39-2.92-1.21-4.03-1.3
		C108.4,70.02,109.15,68.08,109.21,67.37z M66.82,91.23l-7.05-1.93c-0.43-0.12-0.71-0.19-1.13-0.31c-0.36-0.1-0.79-0.27-1.24-0.31
		c0.13-1.58,2.35-6.93,3.11-8.44c1.49-2.97,3.08-5.65,5.18-8.25l2.7-3.18l7.13,7.08c-0.31,0.22-0.54,0.44-0.77,0.73
		c-3.34,4.01-5.44,6.83-7.26,12.27C67.32,89.35,66.86,90.75,66.82,91.23z M132.85,68.48c0.18,0.13,0.66,0.72,0.92,0.97
		c0.39,0.39,0.56,0.67,0.94,1.05c0.34,0.34,0.6,0.72,0.9,1.1c3.28,4.11,6.27,9.15,7.8,14.17c0.15,0.5,0.31,0.99,0.47,1.53
		c0.14,0.48,0.36,1.12,0.4,1.59c-0.57,0.05-10.27,2.72-10.87,2.77c-0.13-0.27-0.23-0.82-0.32-1.12c-0.79-2.7-1.28-3.94-2.53-6.46
		c-0.83-1.67-4.14-6.8-5.7-7.84c0.41-0.61,2.32-2.33,3.04-3.06l3.87-3.79C132.1,69.07,132.37,68.61,132.85,68.48z M69.7,92.01
		c-0.31-0.02-2.21-0.55-2.44-0.67c1.03-4.42,3.12-8.41,5.74-11.91c0.61-0.81,2.14-2.74,2.81-3.19c0.03,0.02,0.2,0.19,0.23,0.22
		l0.12,0.1c0.06,0.06,0.08,0.06,0.18,0.16l1.59,1.74L76,80.55c-1.64,1.77-3.31,4.31-4.41,6.46c-0.16,0.32-0.28,0.67-0.44,1
		C70.81,88.71,69.77,91.2,69.7,92.01z M131.07,100c0,1.14-0.12,2.53-0.25,3.63c-0.65,5.19-2.71,9.98-5.8,14.06l-2,2.33
		c-0.51,0.52-0.73,0.84-1.35,1.32c-0.55,0.43,0.09-0.12-0.58,0.19c-0.44,0.46,0.84-0.24-1.12,1.32l-3.12,2.21
		c-1.29,0.83-3.98,2.13-5.43,2.67c-0.42,0.15-3.13,1.08-3.56,1.08l-0.38-0.06c-0.15-0.02-0.19,0.1-0.24,0.13
		c-1.26,0.82-6.93,0.87-8.57,0.87c-3.82,0-8.57-1.31-11.87-2.78c-3.37-1.5-5.36-3.01-7.99-5.21l-2.08-2.02
		c-4.49-4.86-7.68-12.13-7.68-18.95c0-5.37,0.9-9.38,2.96-13.46c1.42-2.83,2.57-4.38,4.51-6.7c1.19-1.42,4.29-4.1,6.03-5.17
		c9.64-5.92,19.55-6.77,30.16-2.57c1.97,0.78,3.28,1.68,4.98,2.68c0.83,0.48,1.91,1.37,2.66,2l1.07,0.94
		c0.15,0.15,0.09,0.08,0.16,0.22c0.17,0.36-0.29-0.12,0.11,0.28c0.44,0.43-0.16-0.23,0.48,0.18c0.11,0.07,0.39,0.38,0.5,0.5
		l2.65,3.12C128.88,87.63,131.07,93.83,131.07,100z M124.52,76.58c1.26,0.84,4.26,5.18,5.07,6.7c0.72,1.35,1.3,2.5,1.89,3.99
		c0.4,1,1.36,3.67,1.36,4.63l-2.33,0.55l-0.63-2.03c-1.42-3.57-2.45-5.76-4.88-8.77l-2.07-2.37c-0.02-0.02-0.04-0.04-0.06-0.05
		c-0.02-0.02-0.04-0.04-0.05-0.06c-0.33-0.33-0.09-0.26-0.52-0.37c0.11-0.41,0.59-0.7,1.08-1.14
		C123.79,77.29,124.08,76.88,124.52,76.58z M55.5,99.11c0,3.54,0.15,6.24,0.82,9.61c0.15,0.74,0.49,1.78,0.51,2.49
		c-4.14,0.97-8.51,2.26-12.5,3.15c-1.4,0.31-2.87,0.68-4.17,1.04l-8.34,2.09c-0.78,0.2-7.79,2.03-8.39,2.04
		c-0.51-1.06-1.62-8-1.75-9.12c-1.24-10.27-0.76-16.24,0.87-26.06c0.13-0.76,0.85-4.12,1.11-4.66l31.1,8.63
		c0.7,0.2,1.41,0.42,2.08,0.58l-0.91,4.86C55.69,95.4,55.5,97.22,55.5,99.11z M144.83,89.13c0.66-0.05,11.27-2.91,12.43-3.22
		l20.97-5.44l0.86,4.13c0.3,1.56,0.92,5.01,1.02,6.63c0.16,2.44,0.45,4.52,0.45,7.21c0,5.73-0.11,8.18-0.91,13.85
		c-0.61,4.36-1.21,5.9-2.09,9.67c-0.58-0.01-7.65-2.17-8.28-2.37c-2.56-0.83-5.73-1.63-8.3-2.47c-3.64-1.18-8.5-2.52-12.32-3.66
		l-4.18-1.26c0.58-2.49,1.57-7.03,1.56-9.76c-0.01-1.3,0.22-2.04,0.06-3.06c-0.08-0.54-0.07-1.02-0.06-1.6
		c0.01-2.15-0.51-5.12-0.93-7.28C145.02,89.98,144.89,89.6,144.83,89.13z M65.82,98.56c0,2.8,0.14,5.45,0.8,8.08
		c0.13,0.53,0.42,1.38,0.42,2.02c-0.86,0.02-8.9,2.31-9.77,2.33c-1.49-6.42-1.86-12.94-0.53-19.5c0.09-0.47,0.35-2,0.53-2.36
		c0.43,0.03,0.75,0.17,1.16,0.28c0.46,0.13,0.77,0.21,1.22,0.33l7.06,1.93C66.25,92.64,65.82,97.16,65.82,98.56z M133.51,92.12
		c0.72-0.06,1.96-0.48,2.66-0.67c0.91-0.24,1.79-0.48,2.73-0.71l4.06-1.05c0.4-0.12,1-0.31,1.43-0.35c0.16,0.34,0.46,1.98,0.54,2.46
		c0.57,3.4,0.97,6.56,0.69,9.98c-0.04,0.55,0.03,1.05-0.05,1.61c-0.35,2.37-0.26,3.1-0.83,5.94c-0.07,0.37-0.53,2.65-0.79,2.65
		c-0.28,0-2.34-0.66-2.76-0.79c-0.88-0.28-1.79-0.54-2.74-0.81l-4.09-1.24c-0.46-0.13-0.98-0.35-1.4-0.38l0.9-4.42
		c0.6-3.58,0.54-6.91-0.06-10.51C133.71,93.27,133.53,92.62,133.51,92.12z M68.59,98.56c0,3.74-0.09,4.14,0.64,7.8
		c0.11,0.57,0.32,1.12,0.36,1.64c-0.68,0.06-1.32,0.39-2,0.44c-0.81-3.46-1.33-5.17-1.33-9.21c0-2.15,0.2-3.64,0.54-5.68
		c0.1-0.61,0.31-1.24,0.35-1.76c0.5,0.24,1.7,0.52,2.33,0.66c-0.01,0.44-0.17,0.93-0.27,1.4C68.91,95.2,68.59,97.16,68.59,98.56z
		 M130.63,92.9c0.57-0.05,1.7-0.54,2.44-0.56c0.02,0.7,0.67,3.24,0.67,5.88c0,3.14-0.04,5.37-0.8,8.41
		c-0.12,0.47-0.41,1.37-0.42,1.91c-0.59-0.01-1.52-0.42-2.11-0.55c0.35-1.49,0.53-1.8,0.77-3.56c0.41-2.99,0.47-5.28,0.09-8.29
		C131.19,95.57,130.82,93.33,130.63,92.9z M67.71,108.88c0.72-0.06,1.37-0.43,2.11-0.44c0.05,0.64,1.04,3,1.33,3.66
		c0.51,1.16,1.14,2.27,1.78,3.33c0.66,1.09,3.53,4.99,4.55,5.67c-0.23,0.34-1.18,1.34-1.55,1.44l-1.91-2.08
		c-1.16-1.33-3.02-4.06-3.86-5.68c-0.99-1.91-1.35-2.83-2.09-4.79C67.93,109.63,67.84,109.15,67.71,108.88z M123.08,123.3
		c-0.12-0.34-0.42-0.42-0.94-1.06c-0.14-0.17-0.18-0.23-0.28-0.38c0.1-0.15,2.59-2.68,3.81-4.3c1.54-2.05,2.99-4.65,3.92-7.07
		l0.71-2.06c0.58,0.13,1.46,0.51,2,0.56c-0.37,1.6-1.76,4.71-2.62,6.15c-2.2,3.71-1.77,2.91-4.28,6.04l-1.4,1.49
		C123.71,122.96,123.58,123.26,123.08,123.3z M57.39,111.54c0.55-0.26,6.32-1.64,7.41-1.91c0.54-0.13,2.03-0.44,2.46-0.64
		c0.21,0.89,1.1,3.22,1.52,4.03l0.93,1.85c1.14,2.2,2.32,3.87,3.86,5.79c0.24,0.3,0.44,0.45,0.68,0.76c0.33,0.43,1,1.14,1.44,1.44
		c-0.09,0.14-6.71,6.98-7.88,7.77l-1.69-1.97c-0.55-0.63-1.09-1.37-1.59-2.07c-0.54-0.75-1-1.43-1.49-2.17l-1.41-2.37
		c-1.01-2.05-1.36-2.4-2.38-4.94c-0.33-0.82-0.7-1.77-1-2.66L57.39,111.54z M132.07,132.29l-8.46-8.52l2.11-2.28
		c0.81-0.8,2.61-3.23,3.23-4.21c0.37-0.6,0.75-1.15,1.09-1.79c0.9-1.68,1.22-2.3,1.92-4.07c0.27-0.66,0.6-1.48,0.78-2.22l2.79,0.77
		c0.95,0.29,1.81,0.55,2.82,0.84l5.6,1.62c-0.66,2.83-2.08,5.9-3.37,8.51c-1.17,2.36-4.31,7.06-6.04,8.83
		C133.84,130.49,132.91,131.73,132.07,132.29z M23.54,119.86c0.52-0.01,3.4-0.79,4.13-0.98l12.52-3.13
		c2.76-0.69,5.46-1.36,8.26-2.07c1.44-0.36,2.77-0.69,4.17-1.04c5.21-1.3,4.14-1.42,4.7,0.29c1.09,3.32,2.08,6.21,3.91,9.3
		c1.38,2.32,2.75,4.6,4.52,6.69c0.31,0.37,0.56,0.65,0.88,1.01l0.86,1.03c-0.13,0.48-3.17,3.38-3.69,3.86l-0.59,0.52
		c-0.15,0.17-0.1,0.12-0.2,0.24l-3.87,3.78c-3.03,2.88-6.91,6.91-9.93,9.93c-0.53,0.53-5.67,5.77-6.13,6.07l-1.59-1.73
		c-0.01-0.02-0.04-0.04-0.05-0.06l-0.77-0.9c-1.35-1.52-2.68-3.12-3.85-4.81c-2.86-4.16-3.94-5.45-6.61-10.59
		c-0.39-0.74-0.83-1.42-1.17-2.26c-0.64-1.55-1.39-2.96-2.11-4.77c-0.67-1.69-1.27-3.25-1.86-5.02
		C24.83,124.57,23.56,120.54,23.54,119.86z M156.6,157.26l-4.52-4.58c-0.15-0.15-0.24-0.23-0.38-0.39
		c-0.09-0.09-0.07-0.08-0.16-0.18l-7.21-7.32c-0.13-0.14-0.05-0.04-0.17-0.17c-1.59-1.83-3.48-3.42-5.11-5.21l-6.64-6.79
		c0.45-0.3,1.65-1.55,2-2c0.19-0.24,0.25-0.29,0.47-0.53c1.28-1.31,3.77-4.9,4.64-6.35c0.99-1.66,1.92-3.37,2.74-5.25l1.14-2.85
		c1.46-3.99-0.16-3.29,5.24-1.8l4.1,1.22c4.06,1.13,8.35,2.56,12.32,3.66l10.25,3.06c0.67,0.22,1.5,0.47,2.15,0.62l-2.17,6.37
		c-2.67,6.62-5.08,11.32-9.08,17.11c-1.61,2.34-3.49,4.72-5.31,6.89l-0.83,0.95C159.38,154.51,157.21,156.85,156.6,157.26z
		 M77.78,121.5l2.98,2.41c1.53,1.15,3.21,2.18,4.93,3.06c1.39,0.71,3.3,1.37,4.79,1.87c0.32,0.11,0.85,0.23,1.1,0.35
		c-0.04,0.43-0.4,1.79-0.56,2.11c-0.22-0.11-0.77-0.24-1.01-0.32l-3.19-1.14c-3.36-1.38-6.19-3.19-8.93-5.5l-1.52-1.37L77.78,121.5z
		 M108.32,131.41c-0.16-0.68-0.39-1.4-0.56-2.11c1.55-0.04,6.25-2.13,7.51-2.81c1.76-0.95,3.89-2.35,5.44-3.66
		c0.29-0.25,0.39-0.43,0.7-0.63c0.4,0.27,1.4,1.17,1.44,1.66c-0.28,0.07-1.17,0.89-1.51,1.16c-1.78,1.39-3,2.25-5.01,3.32
		c-0.34,0.18-0.48,0.3-0.85,0.48l-1.9,0.88C112.52,130.15,109.27,131.38,108.32,131.41z M68.26,131.07l7.74-7.8
		c0.32,0.23,0.51,0.46,0.78,0.72c0.3,0.3,0.47,0.39,0.78,0.66c0.53,0.48,1.03,0.86,1.6,1.29c2.1,1.57,4.88,3.29,7.37,4.28
		c1.39,0.55,2.86,1.15,4.37,1.5c-0.01,0.57-2.74,10.93-2.89,11.54c-1.21-0.28-4.64-1.62-5.81-2.18c-0.89-0.43-1.81-0.86-2.66-1.33
		c-1.8-1.01-3.23-1.9-4.89-3.1c-1.44-1.04-2.16-1.73-3.34-2.65L68.26,131.07z M92.01,129.3c4.36,1.02,8.93,1.33,13.51,0.53
		c0.5-0.09,1.21-0.3,1.81-0.3c0.17,0.36,0.44,1.63,0.55,2.11c-0.63,0-3.62,0.8-6,0.78c-0.83-0.01-1.3,0.15-2.22,0.12
		c-0.53-0.02-0.51-0.09-0.99-0.12c-0.36-0.02-0.75,0.02-1.11,0c-1.15-0.06-5.26-0.65-5.99-1C91.58,130.67,91.95,130.01,92.01,129.3z
		 M108.43,131.96c0.29-0.14,0.67-0.18,1.04-0.29c0.42-0.12,0.67-0.2,1.11-0.33c0.79-0.23,1.38-0.48,2.12-0.77
		c4.49-1.8,6.14-3.05,9.72-5.82c0.3-0.23,0.48-0.46,0.78-0.67c0.44,0.12,1.43,1.38,2.14,2.08c1.77,1.77,3.62,3.5,5.27,5.38
		c0.31,0.35,0.77,0.84,1.14,1.08c-0.49,0.73-3.39,3.01-4.23,3.65c-2.99,2.24-6.56,4.29-10.08,5.67c-1.08,0.42-4.96,1.8-5.99,1.89
		c0-0.5-0.54-2.37-0.69-2.98l-1.91-7.41C108.72,133.01,108.58,132.27,108.43,131.96z M91.34,131.85c1.53,0.73,6.99,1.11,9.1,1.11
		c1.69,0,4.16-0.22,5.78-0.54c0.54-0.11,1.24-0.3,1.76-0.35l2.29,8.81c0.17,0.69,0.71,2.52,0.71,3.06l-4.69,0.86
		c-2.03,0.27-5.01,0.49-6.98,0.27c-0.56-0.06-1.16,0.03-1.73-0.05c-2.07-0.28-7.36-0.78-9.13-1.63
		C88.62,142.72,91.33,132.43,91.34,131.85z M79.47,177.24c-0.84-0.02-4.55-1.35-5.54-1.68c-5.3-1.77-12.16-5.31-16.79-8.4
		c-0.76-0.5-1.46-0.95-2.18-1.48c-1.94-1.44-4.72-3.43-6.54-5.12c-0.22-0.2-0.25-0.25-0.47-0.42c-0.4-0.31-0.6-0.58-0.97-0.91
		c-0.68-0.6-3.43-3.1-3.58-3.63c0.41-0.62,3.71-3.65,4.58-4.52l18.37-18.25c0.29-0.29,0.49-0.49,0.78-0.78
		c0.29-0.29,0.41-0.6,0.91-0.64c0.08,0.31,0.19,0.32,0.43,0.57c0.18,0.18,0.3,0.32,0.48,0.52c0.36,0.36,0.67,0.58,1.06,0.94
		l3.12,2.65c0.94,0.72,2.31,1.77,3.67,2.54c1.47,0.84,1.98,1.23,3.83,2.16c0.48,0.25,0.87,0.42,1.35,0.65
		c0.95,0.46,1.84,0.79,2.82,1.18c0.63,0.25,2.61,0.96,3.11,1c0,0.89-3.99,15.92-4.2,16.78l-3.2,12.56
		C80.3,173.8,79.48,176.66,79.47,177.24z M111.65,144.28l3.01-0.87c1.04-0.35,1.95-0.65,2.95-1.05c4.73-1.88,8.41-4.25,12.34-7.42
		c0.42-0.34,0.66-0.63,1.06-0.94c0.43-0.34,0.68-0.68,1.06-0.93c0.6,0.16,1.13,1.02,1.53,1.47c1.14,1.29,6.1,6.1,7.66,7.66l2.4,2.49
		c2.4,2.64,6.18,5.99,8.58,8.62l4.14,4.3c-0.47,0.31-2.18,2.09-2.71,2.51l-1.92,1.63c-0.4,0.3-0.59,0.49-0.98,0.79
		c-0.38,0.29-0.61,0.48-1,0.77l-3,2.22c-0.72,0.53-1.34,0.97-2.09,1.47c-1.52,1.01-5.03,3.14-6.61,3.93l-2.35,1.21
		c-1.48,0.6-3.1,1.49-4.9,2.2c-2.64,1.05-6.36,2.39-9.2,3.11c-0.49,0.13-0.91,0.31-1.42,0.35l-2.13-8.3
		c-0.33-1.32-0.73-2.81-1.07-4.15c-0.18-0.74-0.38-1.42-0.55-2.11l-1.62-6.26c-0.81-2.76-1.97-7.63-2.69-10.52
		C111.99,145.84,111.67,144.86,111.65,144.28z M88.35,143.83c2.54,0.59,2.86,0.75,5.75,1.24c4.67,0.78,11.15,0.67,15.82-0.42
		c0.43-0.1,0.87-0.23,1.29-0.26l3.23,12.53c0.98,3.31,2.39,9.16,3.25,12.62l2.17,8.37c-10.05,2.34-19.39,3.04-30.07,1.55
		c-1.67-0.23-3.55-0.51-5.11-0.88c-0.81-0.2-1.65-0.37-2.44-0.56l-2.44-0.55c0-0.47,0.12-0.71,0.19-1.03
		c2.11-8.69,4.51-17.55,6.69-26.27l1.09-4.24C87.93,145.29,88.11,144.33,88.35,143.83z"
          />
          <path
            class="st0"
            d="M100.11,110.54c3.26,0,5.78-0.72,8.02-2.97c5.31-5.34,3.74-13.73-2.65-16.93c-2.87-1.44-6.44-1.47-9.32-0.04
		c-0.87,0.43-1.55,0.9-2.23,1.55c-0.28,0.28-0.44,0.33-0.73,0.61c-3.64,3.46-3.76,9.91-0.65,13.8c0.2,0.25,0.38,0.51,0.6,0.73
		c0.77,0.84,1.93,1.68,2.94,2.17C97.07,109.94,98.71,110.54,100.11,110.54"
          />
          <path
            class="st0"
            d="M89.13,88.24c0.18,0.24,0.46,0.39,0.73,0.6c0.39,0.3,0.41,0.43,0.58,0.86c0.32,0.8,0.82,0.91,1.24,1.54
		c-2.16-0.05-3.75-3.14-3.4-5.17c0.12-0.71,0.33-1.07,0.06-1.81c-0.19-0.53-0.52-0.93-0.91-1.32c-0.51-0.51-1.88-1.51-2.64-1.69
		c0.66,0.99,1.89,1.87,2.41,2.92c0.43,0.85-0.29,1.26-0.66,1.73c-0.37,0.46-0.73,1.14-0.76,1.9c-0.08,1.7,0.28,2.85,1.18,4.15
		c0.7,1.03,2.33,2.33,2.6,2.73c-2.38-0.55-1.19-0.64-2.41-1.26c-0.29-0.14-0.66-0.29-1.03-0.3c0.48,0.42,1.09,0.51,1.52,1.03
		c0.24,0.3,0.3,0.46,0.62,0.72c0.21,0.17,0.6,0.4,0.86,0.47v0.22c-2.29,0-3.97-1.92-4.41-4.02c-0.36-1.7-0.1-1.9-1.5-2.72
		c-1.27-0.74-2.27-0.7-3.18-1.14c0.09,0.26,0.06,0.16,0.24,0.31c0.82,0.7,1.94,1.09,2.73,1.82c1.21,1.11-0.64,1.93-0.27,3.93
		c0.56,3.04,2.48,3.86,4.63,5.14c0.35,0.21,0.2,0,0.33,0.34c-0.7-0.16-1.08,0.06-1.65-0.35c-0.6-0.43-0.73-0.32-1.68-0.32l0.47,0.2
		c0.87,0.21,0.8,0.12,1.45,0.66c0.58,0.49,1.52,0.3,1.97,0.92c-1.87,0.9-4.35-0.78-5.31-2.24c-1.04-1.59-0.8-2.2-3.79-2.2
		c-0.79,0-1.23,0.11-1.89,0.11v0.22c0.66,0.05,3.42,0.65,3.86,1.02c0.75,0.63-0.26,2.13,0.85,3.81c1.38,2.1,4.22,3.04,6.83,3.04
		l-0.8,0.31c-0.89,0.28-1.23-0.27-2.31,0.02c-0.31,0.09-0.23,0.03-0.45,0.22c0.89,0,1.24-0.22,1.92,0.07
		c0.81,0.34,1.01,0.26,2.08,0.26c-0.96,1.31-4.24,1.2-6.02-0.52c-1.21-1.17-1.7-1.85-4.42-0.93c-0.52,0.18-1.07,0.46-1.54,0.57v0.22
		c2.43,0,4.4-0.98,4.66,1c0.06,0.49,0.09,0.58,0.24,0.98c0.17,0.45,0.43,0.78,0.77,1.11c2.94,2.81,6.22,1.29,8.31,1.12
		c-0.17,0.25-1.23,1.37-1.5,1.5c-0.84,0.4-1.14,0.13-1.83,1.16c0.82-0.22,0.87-0.77,1.88-0.89c1.06-0.13,1.18-0.75,2.11-0.77
		c-1.09,2.05-3.36,2.84-5.71,2.26c-1.44-0.35-1.81-0.48-3.13,0.55c-0.42,0.33-0.74,0.67-1.04,1.06c-0.37,0.48-0.57,0.62-0.89,1.22
		c0.39-0.1,1.12-0.67,1.51-0.93c3.54-2.36,1.93-0.45,4.45,0.64c1.9,0.82,4.11,0.24,5.74-0.88c0.41-0.28,1.1-0.94,1.51-1.05
		c-0.15,0.65-0.42,1.04-0.97,1.37c-0.44,0.26-0.88,0.88-0.92,1.41c0.72-0.48,0.52-1.01,1.62-1.49c0.77-0.33,0.87-1.32,1.82-1.4
		c-0.09,1.05-0.88,2.19-1.55,2.67c-0.78,0.56-1.76,1.14-3.12,1.14c-1.37,0-2.03,0.18-2.89,1.28c-0.68,0.87-0.96,1.94-1.43,2.9
		c0.38-0.14,0.07,0.01,0.37-0.3c0.13-0.13,0.21-0.21,0.32-0.34c1.99-2.27,1.93-2.96,3.61-1.89c1.88,1.2,4.47,0.37,5.93-1.1
		c0.52-0.52,0.87-0.88,1.32-1.46c0.19-0.24,0.37-0.52,0.55-0.78c0.1-0.14,0.16-0.23,0.26-0.41l0.17-0.5
		c0.04-0.5,0.18-0.86,0.66-0.89c-0.26-0.35,0.33-0.33-0.66-0.33c-0.07-0.08-0.09-0.27-0.11-0.33c-1.51-0.21-4.02-1.14-5.22-1.99
		c-2.06-1.46-3.69-3.34-4.56-5.76c-1.13-3.14-1.07-6.65,0.61-9.75c0.8-1.48,1.49-2.16,2.6-3.28c0.33-0.33,0.39-0.39,0.78-0.66
		c1.9-1.28,2.66-1.77,5.16-2.28c0.72-0.15,2.17-0.14,2.75-0.36l1.89-0.16c0.02-0.01,0.05-0.02,0.07-0.03
		c0.02-0.01,0.05-0.02,0.07-0.03c0.48-0.2-0.35-0.06,0.22-0.1c0.38-0.03,0.04,0.01,0.26,0.07l2.04,0.25l-0.44-0.33
		c-0.17-0.41-0.2-0.88,0.11-1.22c0.14-0.12,0.15-0.41,0.23-0.65c0.58-1.65,2.29-2.67,3.99-3c1.06-0.21,1.75-0.38,2.35-1.64
		c0.31-0.66,0.86-2.25,0.86-3.14c-0.39,0.45-1.5,2.53-1.97,3.13c-0.99,1.25-1.73-0.68-3.93-0.16c-1.22,0.28-2.45,0.95-3.19,1.91
		c-1.75,2.28-1.09,1.84-1.85,3.03c-0.13,0.21-0.04,0.08-0.14,0.19c-0.17-0.73-0.21-0.79,0.16-1.4c0.36-0.61,0.29-0.72,0.29-1.6
		h-0.22c0,2.1-0.58,1.25-0.93,2.73c-0.11,0.47-0.09,0.71-0.4,0.93c-0.27-1.18-0.5-2.1,0.18-3.37c1.96-3.63,4.49-1.43,4.48-6.06
		c0-0.48,0.06-1.05-0.06-1.5l-0.16-0.39c-0.17,0.72-0.87,3.52-1.2,3.91c-0.74,0.89-1.84-0.6-4.11,0.88
		c-0.92,0.6-1.84,1.77-2.24,2.87c-0.23,0.63-0.42,1.25-0.57,1.99c-0.1,0.53-0.31,1.78-0.32,2.34c-0.26-0.19-1.02-1.35-1.08-1.69
		c-0.12-0.66,0.1-0.62-0.2-1.24c-0.16-0.32-0.36-0.66-0.6-0.84c0.07,0.82,0.66,0.91,0.49,2.04c-0.13,0.91,0.52,1.84,0.29,2.06
		l-0.25-0.07c-0.32-0.19-0.6-0.55-0.81-0.86c-1.11-1.69-0.52-3.95,0.42-5.25c1.02-1.42,2.11-1.86,0.6-4.87
		c-0.3-0.59-0.55-1.26-0.96-1.37c0.08,0.98,0.96,3.68,0.31,4.41c-0.68,0.77-1.81,0.21-3.21,2.11c-1.01,1.36-1.12,2.47-1.1,4.24
		c0.02,1.91,0.95,3.5,1.11,4.21C90.14,89.83,90.54,88.35,89.13,88.24"
          />
          <path
            class="st0"
            d="M44.51,138.84c0.09-0.21,0.05-0.21,0.11-0.44c0.75,0,0.83-0.08,1.52,0.19l-0.12-0.33c0-0.01,0-0.05,0-0.06
		c0-0.02,0-0.09,0-0.11c-0.07-0.24,0.23,0.08-0.08-0.14c-0.28-0.2-0.22-0.04-0.24-0.03c0.85,0,0.16,0.04,0.44-0.19
		c0.05-0.55,0.33-0.77,0-0.8l0.31-0.03c0.01,0.51-0.14,0.2,0.04,0.43c0.3,0.37-0.12-0.16,0.82-0.4c0.37-0.1,0.54-0.13,0.85,0.02
		c0.54,0.25-0.46,0.01,0.18,0.06c0.46,0.03,0.72-0.24,0.82-0.72h0.44c0.3,0.03-0.08,0.01,0.25,0.01c0.04,0,0.27,0,0.3,0
		c0.45-0.02,0.28,0.03,0.67-0.12c0.55,0,0.75-0.02,1.16-0.11c-0.01-0.03-0.04-0.09-0.04-0.07c-0.01,0.02-0.04-0.06-0.04-0.07
		c-0.43-0.46-0.02,0.19-0.4-0.44l-0.22-0.31c0.16-0.35,0.44-0.8,0.09-0.91c0.81-0.09,0.52,0.17,0.87-0.33
		c-0.35-0.17-0.43-0.09-0.96-0.09c-0.06-0.27-0.02-0.19-0.11-0.44c-0.09-0.16,0.13,0-0.22-0.12c-1.91-0.66-3.15-1.21-4.52-0.19
		c-0.22,0.17-0.36,0.24-0.54,0.46c-0.26,0.32-0.17,0.39-0.6,0.51c-0.09-0.4-0.3-0.48-0.21-0.98c0.19-1.04,2-0.73,2.76-1.02
		c0.7,0,0,0.02,0.47-0.13c0.35-0.11,0.22-0.09,0.64-0.09c-0.1,0.19-0.35-0.03-0.56-0.19c-0.81-0.59-0.4,0.08-2.17-1.06
		c-1.09-0.71-1.14-1.28-1.5-1.49l-0.2-0.11c0,0-0.08-0.18-0.12-0.25c-0.11,0.29,0.01,0.01-0.15,0.19c-0.23,0.25-0.21,0.13-0.41,0.57
		H43.4c-0.4,0.01-0.26,0.32-1.02,0.53c-0.8,0.22-0.77,0.02-1.53,0.02c0.35-0.47,0.99-0.08,1.96-0.82c0.25-0.19,1.03-1.25,1.04-1.62
		c-0.02-0.02-0.07-0.05-0.07-0.09c-0.01-0.05-0.02-0.09-0.04-0.13c0.03-0.02,0.07-0.04,0.1-0.06l0.17-0.11
		c0.02-0.02,0.05-0.03,0.07-0.05l0.26-0.25c0.01-0.05,0.04-0.05,0.06-0.08c0.06-0.45,0.31-1.14,0.42-1.58
		c0.1-0.39,0.33-1.36,0.47-1.64c2.2,0,5.87,2.49,6.76-0.01c0.32-0.9-0.03-1.82-0.1-2.66c-0.31,0.15-0.88,0.32-1.33,0.33
		c0.15,0.65,0.54,1.23,0.56,1.89c-1.46,0.97-3.68,0-5.06-0.82c-1.88-1.11-1.24-1.05-2.57-0.15c-0.31,0.21-0.76,0.51-1.1,0.68
		c-0.41,0.2-0.96,0.23-1.46,0.31c-0.51,0.09-1.02,0.31-1.57,0.32c0.11-0.49,0.87-1.31,1.25-1.63c0.98-0.81,2.44-1.96,3.85-1.93
		c0.7,0.02,1.47,0.65,1.9,0.99c0.96,0.77,2.72,2.57,3.43,1.02c0.35-0.76-0.21-1.63-0.54-1.91c-0.58-0.49-1.42-0.42-2.46-0.42
		c0.14,1.64,2.38,1.16,2.66,2.22c-0.54-0.23-0.8,0.36-2.09-0.68c-1.86-1.5-2.37-2.8-6.2-0.51c-0.41,0.25-0.46,0.34-0.78,0.66
		c-0.33,0.33-0.43,0.52-0.72,0.84l-2.28,2.38c-0.89,1.01-1.59,1.21-1.94,2.72c-0.39,1.68,0.11,3.72,0.34,5.37
		c0.46,3.25-1.27,7.89,4.45,7.86c3.21-0.02,1.97,1.05,3.71-0.06c0.25-0.16,0.83-0.35,1.17-0.38v-0.22c-0.3-0.02-0.3-0.05-0.55-0.11
		C44.62,139.25,44.68,139.26,44.51,138.84"
          />
          <path
            class="st0"
            d="M105.66,86.35c-0.31,0.34-0.28,0.81-0.11,1.22l0.44,0.33l-2.04-0.25c-0.22-0.06,0.12-0.1-0.26-0.07
		c-0.57,0.04,0.26-0.1-0.22,0.1c-0.02,0.01-0.05,0.03-0.07,0.03c-0.02,0.01-0.05,0.02-0.07,0.03l-1.89,0.16
		c0.16,0.39-0.19,0.12,0.44,0.22c1.09,0.18,1.82,0.01,3.55,0.78c0.56,0.25,1.13,0.46,1.65,0.8c2.63,1.71,4.52,3.63,5.51,7.03
		c1.05,3.61,0.33,6.98-1.47,9.84c-0.81,1.29-1.84,2.28-3.05,3.16c-0.18,0.14-0.24,0.15-0.39,0.26l-0.35,0.43
		c0.75,0,0.41,0.05,0.89-0.28c0.12,0.26-0.03,0.14-0.08,0.36l0.03,0.2c0.17,0.24,0.33,0.02,0.2,0.44l-0.09,0.24
		c-0.27,0.57-0.09,0.53-0.09,1.69c-0.27-0.17,0.02,0.03,0.06,0.1c0.29,0.49,0.29,0.6,0.27,1.25c-0.04,1.86,0.41,0.63,0.06,1.3
		l-0.2,1.39c-0.1,0.9-0.27,0.35-0.5,1.19l-1.73,0.1c-0.01-0.57-0.11-0.85-0.03-1.25l0.04-0.16c0.52-3.17-0.39-3.06-0.3-3.83
		c0-0.01,0.01-0.04,0.01-0.05c0-0.01,0.01-0.05,0.01-0.06l0.08-0.46c-0.34,0.01-0.35,0.06-0.64,0.11l-0.25-0.65
		c-0.2-0.04-0.03,0.17-0.24-0.17l0.17-0.08c0.31-0.14,0.5-0.24,0.89-0.25c-0.05-0.4,0.13-0.16-0.11-0.44
		c-0.54,0.17-0.85,0.32-1.42,0.47c-1.57,0.39-1.52,0.34-3.15,0.52c-0.53,0.06-1.17-0.1-1.87-0.1c0.02,0.06,0.04,0.25,0.11,0.33
		c1,0,0.4-0.02,0.66,0.33c-0.48,0.03-0.63,0.39-0.66,0.89h0.22c-0.01,0.4-0.1,0.65-0.17,1.05c-0.09,0.5-0.07,0.68-0.31,1.02
		c-0.43,0.61-0.84,0.71-0.84,1.82c0.48-0.36,0.33-1.22,1-1.66c1.03-0.67,0.61-1.11,1.22-1.56v1.22c0.03-0.03,0.05-0.12,0.07-0.11
		c0.15,0.22,0.15-1.07,0.15-1.11c-0.28-0.24,0.42-0.22-0.56-0.22l0.09-0.95c0.03-0.78,0.07-0.47,0.26-0.56l0.2-0.08
		c0.04-0.01,0.06-0.01,0.11-0.02c0.23-0.05,1.39-0.22,1.51,0.01l0.05,0.17c0.04,0.17,0.02,0.25-0.14,0.26l-0.52-0.16
		c0.02,0.01,0.05,0.02,0.06,0.05c0.07,0.27,0.55,0.12,0.83,0.17c0.13,0.31,0.12,0.67,0.12,1c0,0.23-0.12,1.92,0.16,2.07l0.12,0.06
		c0.02,0.01,0.06,0.03,0.07,0.04c0.02,0.01,0.06,0.02,0.08,0.05c0,0.01-0.02,0.02-0.02,0.03l-0.04,0.08c0,0.01-0.02,0.05-0.02,0.06
		c-0.1,0.52,0.04,0.19,0.08,0.61c0.46-0.29,0.58-0.98,0.71-1.51c0.13-0.54,0.39-1.3,0.4-1.82c0.02,0.02,0.04,0.03,0.04,0.07
		c0.01,0.06,0.49,0.73,0.21,1.63c-0.13,0.41-0.28,0.98-0.04,1.54c0.07,0.16,0.07,0.13,0.12,0.21c0-2.41,0.36-1.47,0.53-2.47
		c0.05-0.31,0.02-0.65,0.02-0.98c1,0.36,1.02,4.01-1.2,5.79c-1.16,0.93-2.17,1.2-1.56,4.41c0.06,0.32,0.13,0.47,0.21,0.79
		c0.11,0.44,0.03,0.55,0.33,0.78c0-0.55,0.1-0.54,0.11-1.11c0.11-4.76,0.82-2.89,2.87-3.68c2.56-0.98,3.54-4.71,3.17-7.25l-0.27-2.4
		c0.19,0.14,0.35,0.37,0.49,0.62c0.42,0.79-0.06,1.09,0.36,1.86l0.34,0.54c0.15,0.21,0.03,0.06,0.14,0.19
		c-0.05-0.57-0.43-0.95-0.44-1.56c-0.01-0.51,0.11-0.36,0.11-0.88c0-0.5-0.1-0.37-0.11-0.89c0.58,0.05,0.12,0,0.43,0.12l1.03,0.63
		c2.7,2.18,0.85,6.12,0.87,6.67c0.05,1.45,2.29,3.62,2.89,3.78c-0.25-0.93-1.76-2.58-1.79-3.56c-0.04-1.4,1.61-0.74,2.19-3.26
		c0.43-1.89-0.05-3.13-0.69-4.43c-0.94-1.89-1.81-2.51-2.26-3.18c0.76,0.02,1.33,0.32,1.66,0.78c0.33,0.44,0.85,0.83,1.55,0.89
		c-0.53-0.72-0.91-0.11-1.57-1.42c-0.16-0.31-0.23-0.29-0.42-0.57c1.06,0.24,2.05,1.01,2.58,1.86l0.38,0.73
		c0.2,0.3-0.04,0.06,0.26,0.29c0.03-0.1,0.2-0.17-0.03-0.7l-0.49-0.88c-0.82-1-1.03-1-1.98-1.53l-0.15-0.09
		c-0.72-0.38-1.59,0.38-1.84-0.1c-0.01-0.02-0.04-0.06-0.05-0.08c-0.31-0.7,0.38-1.92,0.32-2.61c0.46,0.37,0.35,0.93,0.27,0.31
		l0.06-0.31l0.55-0.11c-0.05,0.15-0.04,0.17-0.22,0c-0.41-0.4,0.09-0.35-0.22-1.33l0.29,0.15c0.18,0.01-0.06-0.34-0.08-0.37
		l-0.17-0.26c0.44-0.41,0.18-1.02,0.18-1.74h1.89c0,0.32-0.03,0.81-0.01,1.11c0.05,0.58,0.19,0.28,0.17,0.59l-0.16,0.34
		c-0.01,0.01-0.02,0.03-0.03,0.05c-0.01,0.01-0.02,0.04-0.03,0.05l-0.05,0.24c0,0.01,0.01,0.03,0.02,0.04l0.1,0.02
		c-0.2,0.08-0.23,0.09-0.44,0.11c0.44,0.7,1.08,0.2,1.61,0.95c0.65,0.93,0.34,0.49,1.5,1.05c-0.11-0.41-0.25-0.34-0.64-0.57
		c-1.12-0.68-0.59-0.73-1.16-1.29c-0.33-0.32-0.38-0.19-0.64-0.58c2.27,0,3.84,1.44,4.62,3.37c0.48,1.2,0.12,1.51,1.7,2.29
		c1.03,0.51,1.92,0.55,3.44,0.55c-0.13-0.12-0.04-0.06-0.26-0.19c-0.08-0.04-0.24-0.12-0.3-0.15c-3.78-1.67-3.32-1-2.88-3.44
		c0.21-1.18-0.26-2.11-0.78-2.89c-0.43-0.65-1.39-1.73-2.22-1.88c0.06,0.88,0.57,0.5,1.41,1.46l1.1,2.04
		c-0.59,0.05-1.32-0.13-2.08-0.21c-0.73-0.08-1.34-1.29-2.86-1.3c0.02-0.24-0.16-0.2,0.47-0.33c-0.11-0.36-0.01-0.68-0.01-1.24
		h-0.69c-0.17-0.21-0.12,0.04-0.22-0.44H115c-0.05-0.9-0.13,0.29-0.19-0.68c-0.04-0.64,0.09-0.34,0.51-0.28
		c0.21,0.03,0.03,0.03,0.3,0.03c0.05,0,0.41-0.06,0.58-0.06c-0.23-0.29-0.15-0.25-1-0.44l0.71-0.3c0.49-0.1,0.53-0.03,0.84,0.07
		c0.77,0.25,1.17,0.06,1.89,0v-0.22c-0.58,0.05-1.25,0.29-1.84-0.04c-0.99-0.56-0.55-0.13-1.71-0.4c0.11-0.1,0.04-0.06,0.26-0.19
		c0.12-0.07,0.21-0.1,0.31-0.14c0.85-0.3,1.72-0.26,2.54-0.01c-0.09-0.52,0.11-0.17-0.22-0.44c-0.15-0.12-0.15-0.08-0.25-0.11
		c-0.04-0.01-0.16-0.04-0.2-0.05c-0.49-0.11-1.04-0.13-1.51,0.03c-0.14,0.05-0.15,0.07-0.25,0.11l-0.18,0.08
		c-0.15,0.05-0.27,0.12-0.49,0.08c-0.41-0.07-0.14-0.1-0.12-0.15c-0.14,0.92-0.46,0.09-0.22,1.33c-0.26-0.08-0.59,0-0.33-0.22
		c0.14,0.15,0.21,0.39,0.14,0.6c-0.01,0.01-0.02,0.04-0.03,0.05l-0.21,0.15c-0.37,0.05-0.36,0-0.71-0.2
		c-0.02-0.02-0.05-0.03-0.07-0.04c-0.19-0.11-0.07-0.05-0.23-0.11c0-1.47-0.08-1.31-0.45-2.39c-0.11-0.32-0.01-0.02-0.09-0.24
		l-0.38-0.98c-0.19-0.5-0.08-0.09-0.08-0.38c0.26-0.22-0.39-0.2,0.57-0.24c0.91-0.03,0.36,0.12,0.47,0.18
		c0.52,0.33,0.08,0.06,0.72,0.23c0.42,0.11,0.13,0.14,0.9,0.19c0.54,0.03,0.43-0.06,0.56,0.08c-0.41-0.27-0.35-0.17-0.22-0.66
		c0.08,0.08-0.21,0.06,0.53,0c0.78-0.06,1.26,0.11,2.06,0.22c0.02,0.92-0.06,0.02-0.26,0.55c0.5,0.2,0.02-0.28,0.22,0.22
		c0.35,0,0.67-0.04,0.89,0.11l0.18-0.15c0.01-0.01,0.03-0.04,0.04-0.06c0.13-0.23,0.04-0.01,0.11-0.35l0.26,0.18
		c0.02,0.01,0.05,0.03,0.07,0.05l0.52,0.27c0.4,0.23,0.55,0.4,0.93,0.28c-0.17-0.38,0.01-0.07-0.22-0.33
		c-0.45-0.49-1.99-1.06-2.58-1.2c-1.2-0.29-2.63-0.25-4.08-0.25c0.95-1.29,2.04-0.4,2.89-1.55c-0.9,0.07-0.72,0.62-1.94,0.52
		c-0.86-0.07-1.02,0.46-1.94,0.48c0.65-0.98,1.06-1.59,2.31-2.02c2.62-0.89,4.5,0.75,5.23,0.8c1.77,0.11,3.79-1.52,4.33-2.34
		c-0.52,0.04-1.34,0.47-1.79,0.65c-2.4,0.96-2.3,1.06-3.16-0.47c-0.29-0.51-0.79-0.95-1.33-1.22c-2.97-1.49-5.54,0.01-7.7,1.15
		c0.16-0.6,0.63-0.72,1.14-0.96c0.43-0.21,0.85-0.65,0.97-1.14c-0.6,0.4-0.66,0.87-1.47,1.08c-0.59,0.15-0.48,0.07-0.92,0.41
		c-0.3,0.23-0.45,0.41-0.82,0.51c0.26-1.11,0.89-2.04,1.75-2.58c3.36-2.09,4.44,0.63,6.71-1.39c0.49-0.43,1.69-1.94,1.86-2.58
		c-0.42,0.11-1.17,0.83-1.54,1.13c-3.43,2.71-1.71,0.01-5.05-0.53c-3.66-0.58-6.06,2.95-7.06,3.62c0.13-1.62,0.76-1,1.29-2.16
		c0.36-0.77,0.25-0.58,0.04-0.73c-0.3,1.29-1.26,1.48-1.58,1.86c-0.23,0.28-0.23,0.47-0.53,0.69V86.35z M99.67,113.54l0.06-0.24"
          />
          <path
            class="st0"
            d="M134.4,51.51l0.11-0.02c0.34,0.18,0.54,0.39,0.59,0.92l-1.54-0.02c-0.79-0.05-1.82-0.43-2.05,0.44l3.95,0.94
		c0.66,0.09,0.78-0.13,1.16-0.39c0-1.46-0.5-1.63-0.64-2.47c-0.18-1.06,0.36-1.89,0.53-2.64c0.22-0.99-0.03-1.95,0.39-2.83
		c0.38-0.81,1.59-0.96,1.17-1.83c-0.29-0.01-0.49-0.01-0.71-0.18c-0.45-0.35-0.01-0.41-0.71-0.85c-0.35-0.22-1.32-0.6-1.8-0.64
		c-0.28,0.59-0.47,1.31-0.78,1.88c-2.15,0-3.89-1.26-5.3-2.69c-0.5-0.51-1.27-1.3-2.02-1.31c-1.55-0.02-1.55-0.31-2.48-0.74
		c-1.06-0.49-2.45,0.28-3,1.6c-1.19,2.86-0.06,1.79-2.11,2.77c-0.68,0.32-0.7,0.49-1.07,0.59c0.25,0.35,0.04,0.06,0.33,0.23
		c0.28,0.16,0.05-0.09,0.34,0.22c-0.23,0.63-2.27,0.59-2.66,1.67c0.5,0.26,0.47,0.23,1.22,0.22c3.89-0.03,1.29-0.43,3.22-1.45
		c0,1.39-0.55,0.98-0.89,1.44c0.72,0,1.2-0.33,1.62-0.82c1.9-2.23,0.95-2.93,1.47-4.41c0.22-0.63,0.87-1.07,1.68-1.09
		c-0.17,0.72-0.52,0.91-0.78,2.55c-0.36,2.29-1.8,2.18-1.89,3.1c-0.1,0.99,0.17,1.58-0.19,3.14c-0.13,0.54-0.35,0.83-0.36,1.41
		c0.96,0,1.08-0.56,1.4-1.7l0.7-2.4c0.49-0.01,0.69-0.12,1.33-0.11c0.68,0.01,0.88-0.11,1.44-0.11c-0.47,0.7-1.94,2.33-2.05,2.73
		c-0.13,0.45,0.29,1.28,0.41,1.79c0.19,0.79,0.2,1.22,0.2,2.25c0.24,0.16,0.33,0.25,0.67,0.33l-0.25-4.1
		c0.43-0.11,1.05-0.58,1.42-0.82c0.37-0.25,1-0.74,1.38-0.84c-0.26-1.11-0.71-1.16-1.44-1.33c0.32-0.2,0.84-0.23,1.17,0.04
		c0.18,0.15,0.53,0.84,0.68,1.1c0.44,0.81,1,1.31,1.62,1.93C131.41,50.59,133.75,50.61,134.4,51.51 M129.19,47.18
		c0.38,0.2,0.26,0.13,0.53,0.47c0.81,0.98,0.34,0.37,0.99,1.01c0.29,0.29,0.25,0.31,0.7,0.41c0.05-0.56,0.29-0.77,0.33-1.33h1.33
		c-0.09,0.24-0.15,0.26-0.44,0.45c-0.18,0.12-0.35,0.22-0.56,0.33c0.04,0.46,0.23,1,0.33,1.44c-0.55-0.15-0.19-0.29-1-0.44
		c-0.77-0.14-1.26-0.72-1.7-1.3C129.45,47.86,129.25,47.69,129.19,47.18z"
          />
          <path
            class="st0"
            d="M122.19,164.03c0.7,0.11,0.33-0.08,0.55,0.57l0.12-0.58c0.01-0.01,0.02-0.04,0.03-0.05l0.13-0.2
		c0.01-0.02,0.04-0.05,0.06-0.08c0.04-1.02,1.13-1.7,1-2.36c-0.08-0.42-0.17,0.26-0.05-0.35c0.08-0.41,0.02-0.21,0.27-0.39
		c0.9,3.39,6.38-2.53,8.14-3.29c0.63-0.27,0.52,0.01,0.66-0.67c0.29-1.35,0.46-1.48,1.49-2.51l1.36-1.42v1.55
		c-1.79,0.42-2.45,2.47-2.19,3.75c0.32,1.61,2.31,1.38,2.31,3.69c-0.41-0.2-0.37,0.03-0.77-0.34c-0.28-0.26,0.29-0.01-0.23-0.22
		c0,1.26,0.74,1.78,1.74,2.15c0.45,0.17,0.25,0.09,0.48,0.07l0.57,0.24c0.02,0,0.05,0.01,0.07,0.01c0.03,0,0.14-0.02,0.14-0.03
		l-0.26,0.52c0.01,0.37,0.18,0.55,0.59,0.59c-0.07-0.08,0.2-0.27-0.44,0l1.33,0.03c-0.27,0.04-0.23-0.46-0.2-1.21l0.08-0.48
		c0.5-0.17-0.27,0.52,0.08-0.05c0.36-0.58,0.06-0.05-0.09-0.35c-0.07-0.14-0.18-1.69-0.01-2.05c0.15-0.34-0.01,0.27,0.16-0.44
		l0.19-0.67c-0.09-0.48,0.14-0.31,0.27-0.83c0.01-0.02,0.01-0.06,0.01-0.07c0-0.02,0-0.05,0-0.07c0-0.01,0-0.05,0-0.06
		c0.05-0.49-0.18,0.23,0.02-0.11c0.18-0.3,0.21,0.06,0.29-0.57c0.02-0.13,0.04-0.28,0.05-0.34c0.04-0.34,0-0.53-0.31-0.72
		c-0.15,0.16-0.11,0.11-0.24,0.33c-0.04,0.08-0.06,0.17-0.09,0.25c-0.79,0.2-1.47-0.4-1.54-0.81l0.23-1.4
		c0.2-0.52,0.14,0.32,0.09-0.94c-0.03,0.15,0.38-0.08-0.11,0.09c0,0-0.2,0.04-0.24,0.04c-0.53-0.03-0.32,0-0.54-0.24
		c0-0.82-0.1-1.63,0-2.33c0.17-0.31-0.11-0.41-0.41-0.58c-0.29-0.17-0.52-0.27-0.92-0.3v1.22c-0.56-0.13-0.61-0.41-1.22-0.56
		c0.23,0.48-0.07,0.42,0.44,0.56c-0.08,0.34-0.14,0.32-0.22,0.67c-2.52-1.33-0.04-2.99-2-3.44c-0.4,0.27-0.56,0.56-1.02,0.87
		c-3.25,2.18-2.47,3.13-4.09,5.68c-0.21,0.34-0.61,0.84-1.3,0.65c-0.35-0.09-0.81-0.69-1.03-0.98c-1.45-1.91-7.21-0.98-6.82-2.71
		c0.15-0.68,2.38-2.25,1.57-3.13c-0.15-0.16-0.72-0.06-0.63,0.19c0,0.01,0.02,0.05,0.03,0.06l0.07,0.15
		c-0.42,0.03-1.36,0.3-1.67,0.44c-0.46,0.21-0.51,0.33-0.73,0.72l-0.27,0.51l3.46,4.2c0.47,0.48,4.11,1.35,2.61,4.31
		C122.16,162.41,122.19,162.15,122.19,164.03 M135.07,158.7c-0.59-0.4-1-1.26-0.31-1.98c0.38-0.4,0.3-0.24,0.76-0.46
		C135.44,157.07,135.07,157.9,135.07,158.7z"
          />
          <path
            class="st0"
            d="M25.87,139.95c-0.29-0.05-0.04,0-0.11,0.44c-0.06,0.35,0.15,0.32-0.21,0.4c-0.12,0.03-0.69-0.33-1-0.4
		c0.04,0.47,0.23,0.78,0.33,1.22c-1.34,0-1.23-0.51-0.22,1c-0.35,0.52-0.64,0.36-0.78,0.89c0.62,0,0.66,0.11,1.22,0.11
		c-0.12,0.51-0.32,0.67-0.33,1.22c0.36-0.1,0.17-0.07,0.45-0.21c0.2-0.1,0.35-0.14,0.55-0.23c0.19,0.38,0.17,0.87,0.33,1.22
		c0.45-0.33,0.39-0.75,0.89-0.89c0.14,0.54,0.19,0.13,0.33,0.66h0.33c0.09-0.39,0.17-0.58,0.33-0.89c0.97,0,0.71,0.35,1.7,1.74
		c1.5,2.12,2.58,3.96,4.33,6.1l1.53,1.91c2.11,2.66,4.32,4.85,6.73,7.25c2,2,3.46,3.13,5.54,4.89c0.67,0.56,1.24,1.01,1.92,1.52
		l5.12,3.64c0.21,0.14,0.35,0.19,0.56,0.33c0.25,0.15,0.33,0.16,0.42,0.47c-0.39-0.09-0.36-0.11-0.89-0.11
		c0.11,0.47,0.31,0.59,0.67,1.11c-0.24,0.33-0.1,0.11-0.41,0.36c-0.35,0.28-0.24,0.09-0.36,0.42c0.28,0.13,0.85,0.14,1.22,0.22
		c-0.1,0.44-0.29,0.75-0.33,1.22c0.32-0.17,0.67-0.41,1.11-0.44c0.01,0.33,0.12,0.89,0.22,1.11c0.31-0.2,0.56-0.48,0.67-0.89
		c0.49,0.33,0.29,0.62,0.89,0.78c0.04-0.41,0.18-0.59,0.33-0.89h1l-0.24-0.77l7.05,3.5c0.44,0.18,0.71,0.33,1.08,0.48
		c3.88,1.58,5.96,2.34,10.04,3.5c5.94,1.69,13.52,3.06,19.69,3.06l-0.46,0.54c0.5,0.03,0.63,0.13,1.24,0.13
		c-0.04,0.47-0.23,0.78-0.33,1.22c0.62-0.05,0.5-0.28,1.11-0.33c0.01,0.45,0.13,0.73,0.22,1.11c0.24-0.16,0.58-0.58,0.67-0.89h0.22
		c0.09,0.32,0.04,0.18,0.23,0.44c0.15,0.2,0.15,0.26,0.44,0.34c0.05-0.54,0.22-0.51,0.33-1c0.5,0.01,0.44,0.11,1,0.11
		c-0.08-0.36-0.19-0.48-0.22-0.89c0.4-0.03,0.53-0.14,0.89-0.22c-0.23-0.35-0.63-0.52,0.2-0.56c0.51-0.03,1.16,0.01,1.68,0.01
		l-2.35-0.16c-0.4-0.12-0.05,0.43-0.31-0.12c-0.02-0.05-0.07-0.03-0.11-0.05c0.22-0.27-0.31-0.22,0.33-0.22
		c0.28-0.04,0.53-0.26,0.67-0.45c-1.95-0.16-1.09-0.28-1-1.33c-0.46,0.1-0.67,0.29-1.11,0.33c0-0.56-0.11-0.6-0.11-1.22h-0.22
		c-0.12,0.45-0.42,0.47-0.56,1c-0.24-0.18-0.58-0.67-0.67-1h-0.22c-0.01,0.48-0.11,0.6-0.11,1.22c-0.55-0.13-0.76-0.34-1.22-0.44
		c0.18,0.38,0.26,0.85,0.44,1.22h-1c0.17,0.26,0.48,0.6,0.55,0.89c-0.67,0-0.91-0.08-1.44-0.11l-2.93-0.17
		c-2.31-0.34-4.24-0.45-6.88-0.89c-3.54-0.59-6.9-1.42-10.04-2.39c-3.52-1.09-5.7-1.9-9.08-3.35c-1.47-0.63-2.87-1.28-4.25-1.96
		l-2.54-1.35c-0.49-0.41,0.15-0.31-0.48-0.84c-0.31-0.26-0.17-0.03-0.41-0.36c0.38-0.09,0.78-0.26,0.89-0.67
		c-0.18-0.09-0.97-0.2-1.22-0.22l0.35-1.1l-1.12,0.34c-0.03-0.37-0.15-0.91-0.23-1.24c-0.08,0.09-0.11,0.12-0.2,0.24
		c-0.23,0.31-0.3,0.55-0.58,0.75c-0.23-0.35-0.44-0.77-0.78-1c-0.04,0.45-0.11,0.6-0.11,1.22c-0.62-0.15-0.59-0.41-1.14-0.34
		c-0.52,0.06-0.29-0.03-1.05-0.47l-4.11-2.88c-0.7-0.52-1.32-1.01-1.99-1.56l-1.4-1.15c-1.64-1.38-2.7-2.29-4.21-3.78
		c-1.23-1.22-1.15-0.94-2.61-2.6l-5.78-6.65c-1.08-1.35-5.42-7.33-5.67-8.42c0.24-0.06,0.68-0.23,0.89-0.33
		c-0.23-0.32-0.49-0.34-0.78-0.78c0.29-0.39,0.58-0.33,0.89-0.78c-0.56,0-0.68-0.11-1.33-0.11l0.35-1.1
		c-0.59,0.01-0.6,0.17-1.13,0.22c-0.08-0.33-0.1-0.85-0.22-1.11c-0.5,0.18-0.18,0.31-0.67,0.67c-0.12-0.23-0.25-0.52-0.44-0.67
		l-0.22-0.13C26.09,140.27,26.14,139.73,25.87,139.95"
          />
          <path
            class="st0"
            d="M89.9,31.64c-0.19,0.16-0.17,0,0.22,0.11c0.08-0.17,0.14-0.32,0.23-0.47c0.27-0.46,0.12,0.3,0.22-0.42
		c0.23,0.49-0.32,0.04,0.23,0.17c0.41,0.1,0.46-0.06,1.1-0.36l0.08,0.18c0.01,0.02,0.03,0.06,0.03,0.08
		c0.01,0.02,0.02,0.05,0.02,0.07c0.5,1.5,0.62,0.29,0.62,2.21h1.33v1.78c0.63,0,1.88,0,2.07,0.06c0.44,0.14,0.49,0.7,1.47,0.88
		c0.61,0.11,1.52,0.1,2.24,0.1c0.86,0,0.92,0.33,1.14,0.28c0.08-0.02-0.04-0.09,0.41-0.16c0.02,0.02,0.04,0.02,0.05,0.06
		c0,0.03,0.16,0.4,0.58,0.28c1.06-0.33,0.21-0.19,1.49-0.1l2.2,0.23c0.56,0.08-0.29,0.07,0.34,0.24c0.09,0.02,0.36,0.03,0.55,0.08
		c0.52,0.15,0.8,0.55,0.67,0.73l0.77,0.02c0.17,0,0.26,0.04,0.41,0.06l0.4,0.02c0.03,0,0.07,0,0.11-0.01
		c-0.02,0.03-0.4-0.26,0.12,0.41c0.42,0.54,0.85,0.75,1.14,1.33c-0.06,0.07-0.11,0.11-0.19,0.2l-0.29,0.38
		c-1.14,0-1.64,0.15-2.36-0.27c-0.41-0.24-0.29-0.07-1.03-0.25c-0.56-0.14-0.21-0.02-0.83-0.01c0.41,0.52,0.62,0.11,1.38,0.84
		c1.44,1.38,0.29,4.21-1.89,3.45c-1.14-0.39-1.04-1.45-1.04-2.18c-0.12,0.1-0.07-0.2-0.31,0.43l-0.09,0.57
		c0.03,0.66,0.05,0.26,0.07,0.27c-0.18-0.05-0.37-0.07-0.53,0.05c-0.52,0.38-0.04-0.39-0.1,0.17c0,0.02,0.05,0.15,0.08,0.16
		c0.37-0.06,0.24-0.03,0.55-0.11v0.55c0.21-0.09,0.21-0.05,0.45-0.11c-0.01-0.01-0.24-0.3-0.03,0c0.23,0.33,0.35,0.32,0.58,0.38
		c0,0-0.25,0.06-0.11,0.39c0.31-0.05,0.25,0,0.44-0.11c-0.09,0.4-0.11,0.35-0.11,0.89c0.43-0.22,0.17-0.2,0.41-0.62
		c0.22-0.38,0.2-0.3,0.47-0.38l0,0.11c0.16,0.03,0.12-0.07,0.29,0.31l0.06,0.19c0.13,0.45,0.11,0.25,0.09,0.72h0.44
		c-0.09-0.54-0.32-0.89-0.11-1.33c0.77,0.2,0.71,1.66,1.66,1.89c-0.07-0.88-0.33-0.18-0.33-1.11c-0.61,0.04,0.09,0.23-0.78-0.89
		l0.26-0.16c0.02-0.01,0.05-0.03,0.07-0.04l0.34-0.15c0.02,0,0.09,0.01,0.11,0.02c0.18,0.12,0-0.15,0.12,0.34h0.44
		c-0.32-0.78-0.39-0.34-0.44-1c0.6,0.14,0.51,0.46,2.33,0.89c-0.61,0.43-1.3,0.18-1.89,0c0,0.85-0.07,0.16,0.33,0.44
		c-0.27,0.18,1.17,0.03,1.44,0c-0.2,0.76-0.13-0.15-0.18,0.5c-0.03,0.41-0.08-0.17,0.17,0.27c0.3,0.52,0.04,0.07,0.05,0.09
		c0.1,0.39-0.24-0.06-0.04,0.69c0.29-0.18,0.1-0.03,0.29-0.26c1-1.18,1.11-4.5,0.11-6.37c-0.94-1.76-2.88-2.74-5.15-3.17
		c-3.12-0.6-5.69-0.35-7.65-0.68c-1.13-0.19-1.55-1.29-2.18-2.04c-0.73-0.85-0.72-0.87-1.71-1.4c-0.3-0.16-0.42-0.26-0.7-0.41
		c0-0.72,0-1.09,0.33-1.33c0.01,0.34,0.04,0.45,0.12,0.76c0.24,0.91,0.39,0.32,0.96,0.81c0.31,0.27,0.14,0.29,0.47,0.53
		c0.73-0.54,1.16-4.31,4.29-4.05c1.89,0.15,1.33-0.2,2.04-0.72c-0.02,0.8-0.17,0.27-0.33,0.89c1.14,0.6,1.14,0.53,2,0.33
		c-0.11,0.47-0.09,0.54-0.67,0.55c0.17,0.35,0.18,1.22,0.07,1.73c-0.13,0.65-0.42,0.75-0.85,1.04c0,0.47-0.05,0.45,0.11,0.78
		c-0.41-0.11-0.29-0.23-0.7-0.31c-0.35-0.07-0.7,0.03-0.84,0.01c-0.42-0.05-0.98-0.42-1.57-0.48c0.14-0.51,0.62-0.71,0.89-1.11
		l0.66,0.34c-0.09,0.23-0.04,0.06-0.14,0.19c-0.24,0.31,0.02-0.27-0.19,0.25c0.34,0.03,0.21,0,0.44,0.11
		c2.2-2.08-2.48-3.01-2.45,0.11c0.01,1.54,1.37,2.23,2.79,2.23c3.46,0,4.68-5.33,2.14-7.58c-0.99-0.88-2.07-1.11-3.92-1.09
		c-2.78,0.03-3.37,1.96-4.46,2.97c-0.53,0.5-0.95,0.62-1.75,0.8c0.62-0.93,2.15-2.66,3.11-3.21c0.23-0.13,0.46-0.25,0.74-0.37
		c0.34-0.16,0.51-0.14,0.7-0.41c-1.72-0.4-2.91,0.76-3.75,2.02c-1.31,1.96-3.12,1.38-3.24,2.87c0.3,0,0.52-0.02,0.69-0.09
		c0.46-0.17,0.26-0.17,0.44-0.45c0.19-0.29-0.03-0.19,0.44-0.34c0.22-0.07,0.51-0.12,0.76-0.12C91.34,31.41,89.61,30.02,89.9,31.64
		 M95.56,28.76c0.53,0.04,0.66,0.21,0.89,0.55c-0.13,0.15-0.12,0.14-0.22,0.33C95.79,29.41,95.58,29.41,95.56,28.76z M108.32,41.74
		c-0.26,0-0.13-0.04-0.44-0.11v-0.22c0.57-0.13,1.1-0.51,1.77-0.67C109.46,41.03,108.75,41.74,108.32,41.74z"
          />
          <path
            class="st0"
            d="M58.61,154.15c-0.03-0.02-0.13-0.1-0.15-0.12c-0.24-0.15-0.04-0.1-0.4-0.21l-0.12,0.08
		c-0.01,0.01-0.05,0.03-0.06,0.04c-0.02,0.02-0.05,0.04-0.07,0.05c-0.33,0.28-0.19,0.45-0.78,0.38c0.03-0.45-0.07-0.3,0.03-0.55
		c0.12-0.31,0.28-0.27,0.44-0.34c0.96-0.51,1.05-1.65,1.2-2.24c0.1-0.41,0.21-0.57,0.25-0.98c0.29,0.19,0.3,0.11,0.33,0.56h0.22
		c0.02-0.75,0.72-1.34,1.11-1.44v-0.22c-0.37-0.09-0.58-0.18-0.78-0.45c0.14-0.52,0.78-0.76,1.45-1.22c0.94-0.65,0.47-0.91,0.44-2
		c-0.29,0.12-0.21-0.23-0.22,0.45c-0.19-0.09-0.36-0.13-0.55-0.22c0.2-0.38,0.44-0.76,0.66-1.12c0.23-0.38,0.44-0.75,0.67-1.1
		c-0.64,0-0.73,0.15-1.3,0.08c-0.72-0.09-0.54-0.09-1.24,0.15c-2.2,0.76-3.14,0.69-3.13,2.87c0.01,1.24-0.25,1.78-0.33,2.78
		c-1.5,0.12-2.38,1.21-2.62,2.7c-0.2,1.23,0.4,3.65,0.66,4.69c0.32,1.28,0.55,3.02,1.45,3.99c1.18,1.26,2.97-0.55,3.73-0.95
		c-0.34-0.5-0.28-0.69-0.11-1.33c0.21-0.82,0-0.66,0-1.44c-0.25,0.02-1.69,0.46-1.89,0.56c0.14-0.52,0.6-0.7,0.97-1.03
		c1.44-1.29,4.22-1.97,4.63-1.36c0,0,0.42,1.54,0.45,1.65c0.78,2.65-0.84,2.12-1.31,3.6c-0.13,0.41-0.21,0.54,0.14,0.79
		c0.41-1.78,0.74-0.21,1.55-1.78c0.78-1.49,0.08-3.73-0.22-4.99c-1.53,0-3.57,0.52-4.19,0.43
		C58.96,154.83,58.93,154.61,58.61,154.15"
          />
          <path
            class="st0"
            d="M79.14,155.82c-2.12,0.18-0.97,1.8-1.67,3.11c-0.56-0.13-0.44-0.31-1-0.45c0,1.22-0.19,1.17-0.67,1.89
		l0.66-0.13c-0.25,1-1.64,1.13-1.96,2.72l-0.04,0.51c-0.12,0.7-0.33,0.55-0.33,0.56c-0.39-0.33-0.45-0.94-0.39-1.44
		c-0.25,0.13-0.8,0.39-1.05,0.43c-0.26-0.27-0.22,0.36-0.22-0.43c-0.01-0.34,0.1,0.11-0.06-0.19c-0.41-0.74-0.32-0.37-0.9-1.73
		c-0.17-0.41-0.24-0.72-0.41-1.14c-0.16-0.38-0.33-0.71-0.51-1.05c-1.18,0-2.95,0.83-3.65,1.34c-1.81,1.35-0.96,1.65-1.15,2.39
		c-0.04,0.18-0.4,0.61-0.53,0.81c0.5-0.12,1-0.51,1.16-1.06c0.22-0.79,0.1-1.37,0.91-1.87c0.07-0.05,0.32-0.16,0.37-0.18
		c0.29-0.12,0.47-0.17,0.85-0.26c0.54-0.13,1.3-0.39,1.82-0.4c0.33,0.63,0.86,1.8,0.97,2.58c0.13,0.92-0.05,2.76-0.31,3.3
		c-0.44-0.32-0.28-1.09-0.56-1.66c-0.98,0.08-0.77,0.5-2.13,1.2c-0.17,0.08-0.31-0.07-0.42,0.69c-0.1,0.68-0.16,1.22-0.03,1.81
		c0.53,2.29,3.6,1.06,5.68,0.4c1.74-0.55,4.18-1.12,5.01-2.87c1.13-2.36-1.82-1.55,2.82-4.5c1.22-0.78,0.74-1.93,0.35-3.78
		c-0.16-0.78-0.18-0.65-0.5-1.16l-0.52-1.04c-0.01-0.02-0.02-0.05-0.04-0.07c-0.16,0.69-0.94,1.58-1.11,2.22
		C79.09,156.33,78.96,156.2,79.14,155.82"
          />
          <path
            class="st0"
            d="M37.08,76.81c0-0.08-0.01-0.27-0.03-0.35c0-0.02-0.02-0.05-0.02-0.08c0-0.02-0.02-0.05-0.02-0.07
		c-0.01-0.03-0.03-0.1-0.04-0.12c-0.02-0.17-0.06,0.01,0.04-0.19c0.16-0.31,0.03,0.05,0.09-0.12c-0.44-0.07-1.16-0.57-1.77-1.32
		c-0.01-0.02-0.03-0.04-0.05-0.06l-0.79-1.39c0.28-0.14,0.63,0.24,0.39,0.11c0.76,0.22,1.1-0.16,1.96-0.17
		c0.35-0.01,1.8,0.14,2.03-0.11c0.02,0.03,0.04,0.07,0.05,0.1l0.29,0.51c0.24,0.29,0.31-0.12,0.16,0.49c0.09-0.74,0.49,0.3,0.6,0.48
		c0.61,1,0.34,1.06,1.09,1.38c0.17,0.6-0.07,0.4,0.06,0.77c0.16,0.48,0.22,1.11,0.03,1.31c0,0-0.62,0.29-1,0.21
		c-0.41-0.09-0.73-0.41-1.08-0.18c0.55,0.74,4.54,1.64,6.7,0.48c0.91-0.49,1.9-1.56,2.37-1.74c1.02-0.39,2.5,0.78,3.36-0.41
		c-1.55,0-1.91-0.17-3.11-0.45c0.77-0.67,2.87-0.19,3.22,0c0.42,0.22-0.24,0.02,0.33,0.22c0.35-0.3,0.82-0.38,1.11-0.78
		c-1.21-0.64-2.23-1.35-4.22-0.89c0-1.14,0.01-1.65-0.22-2.56c-0.18-0.73-0.35-0.85-0.67-2.21c-0.68,0.18-1.28,1.41-1.49,2.06
		c-0.91,2.87,2.15,3.23-0.35,4.97c-3.36,2.34-7.14-2.49-7.15-7.71c0-0.74,0.11-1.2,0.11-1.77c-1.51,0.8-2.45,2.77-3.82,3.07
		c-0.7,0.16-1.37-0.28-1.62-0.63c0.22-0.11,2.44,0.47,4.35-2.53c0.71-1.12,0.34-1.53-0.23-2.49c-0.69-1.17-0.44-0.56-0.95-0.82
		c-0.25-0.13-0.58-0.73-0.85-0.93c-0.88,0.2-1.47,1.35-1.95,2.05c-0.76,1.11-0.58,0.01-0.9,1.76c-0.15,0.8-1,3.13-0.15,4.64
		C33.65,72.61,35.75,76.26,37.08,76.81 M35.51,65.27c-0.03-0.31,0.03-0.12,0.13-0.34c0.33,0.09,0.27,0.04,0.45,0.32
		c0.58,0.89-0.09,1.69-0.9,2.12c-0.21-0.9-0.19-0.8,0.44-1.22L35.51,65.27z M34.64,72.04c0.47,0.04,1.12,0.37,1.33,0.66
		c-0.19,0.09-0.49,0.22-0.78,0.22C34.86,72.92,34.75,72.3,34.64,72.04z"
          />
          <path
            class="st0"
            d="M23.87,106.55c0,3.82,3.8,6.11,6.55,6.11c2.84,0,3.37-0.5,4.9-2.75c0.68-1,0.87-0.89,0.87-3.35
		c-0.23,0.11-0.63,0.16-0.96,0.26c-0.35,0.1-0.61,0.22-0.96,0.11c-0.54-0.17-1.05-0.65-1.74-0.71c-0.49,0.93-0.28,2.33-2.66,2.33
		c-3.59,0-4.39-5.33-0.22-5.33c0-0.49-0.08-1.11,1.22-1.11c-0.02,1.02-0.88,0.48-1.11,1.33c0.31,0.08,0.26,0.05,0.47,0.3
		c0.53,0.63,0.24,1.09,0.19,1.69c-0.36,0.09-0.49,0.19-0.89,0.22c-0.21-0.2-0.36-0.34-0.44-0.67c0.55-0.13,0.39-0.3,0.44,0.22
		c0.38-0.03,0.41-0.09,0.67-0.22c-1.06-1.99-3,0.16-1.1,1.66c0.21,0.17,3.54,0.97,3.54-2.66c0-1.1-0.56-1.87-1.2-2.35
		c-2.25-1.69-4.43-1.04-6.15,0.88c-0.37,0.42-0.76,1.14-0.97,1.69C24.07,104.84,23.87,105.66,23.87,106.55 M26.2,103.33
		c0.57,0.05,0.57,0.16,0.78,0.55C26.16,103.88,26.44,103.86,26.2,103.33z M26.09,108.99c0.13-0.5,0.72-0.98,1.22-1.11
		C27.82,108.84,27.17,108.99,26.09,108.99z M32.53,108.1c0.7,0,1.38,0.78,1.44,1.55c-0.34-0.18-0.6-0.3-0.98-0.46
		c-0.34-0.14-0.63-0.34-1.02-0.43C32.04,108.54,32.29,108.1,32.53,108.1z M29.21,108.98l0.76,0.01c0.36,0.76,0.06,1.02-0.11,1.77
		L29.21,108.98z"
          />
          <path
            class="st0"
            d="M57.5,153.49c-0.16,0.07-0.32,0.03-0.44,0.34c-0.1,0.25,0,0.1-0.03,0.55c0.59,0.07,0.45-0.1,0.78-0.38
		c0.02-0.01,0.05-0.03,0.07-0.05c0.02-0.01,0.05-0.04,0.06-0.04l0.12-0.08c0.36,0.11,0.16,0.06,0.4,0.21
		c0.03,0.02,0.12,0.1,0.15,0.12l0.14-0.14c0.43-0.56,0.1-0.44,0.52-0.74l0.07,1.22c0.88,0.02,1.28,0.12,2.41-0.19
		c0.86-0.23,1.36-0.48,2.29-0.26c0.17,0.72,0.32,1.59,0.44,2.33c0.12,0.74,0.39,1.63,0.44,2.33c0.11,0.02,0.63,0.13,0.72,0.17
		c0.97,0.48-0.77,0.94,2.76-0.24l2.29-0.82c0.48,0.91,0.62,0.99,0.91,2.2c0.19,0.77,0.5,1.74,1.09,2.13c0.56-0.13,0.44-0.31,1-0.44
		c-0.2,0.87-0.66,0.89-1.22,0.89c0,0.79-0.04,0.15,0.22,0.43c0.26-0.04,0.8-0.3,1.05-0.43c-0.06,0.51,0,1.11,0.39,1.44
		c0,0,0.21,0.14,0.33-0.56l-0.15-0.14c-0.63-0.82-0.01-0.91-0.1-1.6c-0.03-0.21-0.13-0.29-0.2-0.59c1.09-0.58,0.85-1.26,1.41-3.47
		c0.32-1.24,1.98-1.17,2.47-2.63c-0.04,0.01-0.08,0.03-0.11,0.04c-0.03,0.01-0.07,0.03-0.1,0.04c-0.03,0.01-0.15,0.05-0.19,0.05
		c-0.06,0.01-0.06,0.21-0.22-0.07c-0.17-0.3-0.04-0.03,0.04-0.22l0.12-0.2c-0.32-0.21-0.22-0.2-0.65-0.2
		c-0.85,0.13-0.59,0.36-1.66,0.11v-0.22c0.36-0.08,0.33-0.02,0.55-0.33c-0.51-0.17-0.44-0.09-0.89,0.11
		c-0.06,0.57,0.2,0.95,0.8,0.83c-0.2,0.36,0.05,0.13-0.26,0.26l-1.05-0.08c-0.66-0.02-0.52-0.14-0.61-0.22
		c-0.03-0.02-0.1-0.1-0.12-0.11l-0.09-0.02c-0.02-0.61-0.19-0.72-0.06-1l-0.6-0.09c-0.15-0.36-0.44-1.15-0.44-1.57
		c-0.08-0.06-0.32-0.11-0.33-0.11c-0.15,0.37-0.04,0.23-0.35-0.19c-0.37-0.49-0.94-1.44-0.65-2.03c-0.46,0.11-1.29,0.76-2.65,0.32
		l-0.24-0.1c1.02-0.09,0.84-0.13,1-0.33c-0.31-0.03-0.24-0.06-0.62-0.1c-1.85-0.18-0.22,0.06-1.05-0.2c-0.33-0.1-1.25,0.07-1.7-0.06
		c-0.6-0.17-0.29-0.07-0.44-0.46l-0.11-0.42c0.08-0.61,0.02,0.38,0.02-0.21c0-0.21-0.04-0.27-0.07-0.39
		c-0.22-0.77-0.38-1.13-0.9-1.52l0.4,0.09c-0.14-0.36,0.02-0.17-0.1-0.42c-0.12-0.26-0.19-0.19-0.27-0.52l0.2-0.09
		c0.29-0.15,0.11,0.11,0.11-0.12c-0.01-0.62-0.17-0.43,0.21-0.54c0.03-0.01,0.24-0.05,0.25-0.05c0.35-0.04,0.06-0.06,0.38,0.03
		c0.33,0.09,0.17,0.2,1.04,0.2c-0.15-0.24-0.14-0.25-0.55-0.45c-2.72-1.33-2.42-1.87-4.11-2.77c0.08,0.31,0.28,0.49,0.48,0.75
		c0.2,0.26,0.38,0.5,0.55,0.79c0.48,0.79,0.07,1.25,1.31,1.35c-0.6,1.26-0.38,0.51-1.2,1.58c-0.89,1.16-0.53,2.15-0.78,3.11
		c-0.14,0.55-0.21,0.34-1.1,1.23l-1.58,1.41v0.89c-1.11,0-0.57,0.55-1.47,0.78C57.32,153.78,57.64,153.7,57.5,153.49 M63.82,146.61
		c0.72,0.19,0.98,1.12,1,2C63.86,147.96,64.27,147.54,63.82,146.61z M66.49,151.05c0,0.96,0.04,0.77,0.33,1l0.05-0.17
		c0.21-0.69,0.17-0.62,0.17-1.39c0.3,0,0.92,0.04,1.05,0.28c0,0,0.01,0.03,0.01,0.04c0,0.01,0.01,0.03,0.01,0.03
		c0.18,0.44,0.1,0.05,0.1,0.82c0.74,0,2.25,0.14,2.61,0.01c0.01,0.02,0.01,0.06,0.01,0.07c0,0.01,0.01,0.05,0.02,0.06
		c0.18,0.45,0.27-0.16,0.6,0.39c-0.46,0.36,0.57-0.18-0.31,0.07c-0.12,0.03-0.37,1.48-0.2,1.92c0,0.01,0.02,0.04,0.03,0.05
		l0.13,0.15c-0.38,0.05-0.52,0.15-0.75,0.43c-0.01,0.01-0.04,0.04-0.05,0.05l-0.14,0.4c0.61-0.2,0.65-0.31,1.16-0.62l1.06-0.71
		l0.77,0.78c1.07,2.77-0.22,1.89-0.22,3.77c-1.04-0.76-0.74-3.36-4.77-0.67c-0.4,0.27-1.2,0.88-1.66,1
		c-0.24-0.46-0.32-0.42-0.22-0.89h-0.22c-0.12,0.3,0.07,0.11-0.24,0.2c-0.11,0.03-0.41,0.02-0.54,0.02c0-1.37,0.25-2.74,0.02-4.13
		c-0.23-1.37-0.89-1.54-2.9-1.54l0.18-0.15c1.16-1,0.79-1.23,1.51-1.59c0.27-0.14,1.66-0.52,1.95-0.41
		c0.06,0.02-0.13,0.16,0.57,0.16L66.49,151.05z M61.38,151.82l0.27-0.95c0.13-0.27,0.06-0.14,0.18-0.27
		c0.09,0.39,0.11,0.36,0.11,0.89H61.6C61.56,152.03,61.72,151.72,61.38,151.82z M60.05,152.93c0.14-0.39,0.84-0.69,1.22-0.78
		c0.11,0.41,0.11,0.14,0.22,0.55C60.89,152.71,60.58,152.89,60.05,152.93z M74.03,159.04c0.02-0.73,0.21-0.94,0.89-1L74.03,159.04z
		 M73.26,159.26c0.29,0.14,0.12,0.1,0.56,0.11v1.33h-0.22C73.57,160.07,73.33,159.88,73.26,159.26z"
          />
          <path
            class="st0"
            d="M32.64,102c-0.06,0.28,0.18,0.58,0.46,0.87c0.16,0.17,0.06,0.14,0.32,0.24c0.21,0.09,0.64-0.09,0.85-0.15
		c0.37-0.11,0.75-0.19,1.06-0.27c0.71-0.18,1.4-0.42,2.08-0.58c-0.09-0.25-0.04-0.09-0.18-0.26c-0.12-0.15-0.12-0.08-0.26-0.29
		c0.47-0.11,0.98-0.22,1.55-0.22l-0.33,0.22c-0.2,0.18-0.14,0.01-0.22,0.33h0.78c-0.18,0.26-0.13,0.11-0.22,0.44
		c1.61,0,2.8-0.22,4.33-0.22c0.55,0,1.44-0.09,1.88,0.34c0.78,0.77-0.09,0.22,1.23,1.1c-0.05-0.56-0.59-2.1-1.76-2.12
		c-0.52-0.01-0.78,0.02-1.28-0.05c-2.49-0.36-1.86-0.61-3.01,0.11c-0.37,0.23-0.58,0.22-0.94,0.4c0.46-1.74,5.59-1.48,6.14-1.04
		c0.59,0.47,0.58,0.95,1.63,1.04c-0.04-0.46-0.11-0.61-0.11-1.22c-1.7-1.14-3.48-0.87-5.84-1.27c-2.78-0.47-3.74-0.79-5.82,0.6
		c0.03-1.13,0.67-1.12,0.78-1.44c0.14-0.4-0.07-0.14,0.33-0.35c0.05-0.15,0.68-0.17,0.9-0.21l0.21-0.04
		c0.03-0.01,0.09,0.01,0.11-0.03c0.02-0.04,0.07-0.02,0.11-0.03l-0.05-1.01c0-0.01,0.03-0.03,0.04-0.03l0.2-0.13
		c-0.05-0.47-0.23-1.25-0.32-1.74c-0.15-0.77,0.1-1.13,0.07-1.5l-0.04-0.14c0.03,0.03,0.09,0.03,0.11,0.04
		c0.46,0.13,0.35-0.11,0.23,0.08c0.03-0.06,0.12,0.02,0.13-0.41c0-0.03,0-0.06,0-0.09l-0.46-1.16c-0.85-0.08-0.31,0.35-1.78-0.07
		c-0.37-0.11-0.63-0.18-0.95-0.02c-1.22,0.61-0.59-0.22-0.96,0.43c-0.09,0.16-0.13,0.14,0.02,0.54c-0.05-0.04,0-0.29-0.36,0.06
		c-0.31,0.3,0.1,1.42-0.38,2.94c-0.11,0.35-0.05-0.04-0.14,0.52c-0.27,1.56-1.65,3.66-1.25,4.59c0.14,0.23,0.04,0.05,0.3,0.24
		c0.02,0.02,0.05,0.03,0.07,0.05c0.02,0.02,0.05,0.04,0.07,0.05c0.02,0.02,0.05,0.04,0.07,0.05C32.52,101.56,32.47,101.46,32.64,102
		"
          />
          <path
            class="st0"
            d="M48.73,64.27c0,0,0.04-0.14,0.04-0.16c-0.02-0.65-0.17-0.18-0.17-0.61c0-0.84-0.21-1.25,0.44-0.93
		c0.29,0.14,0.22-0.17,0.22,0.96c0.45-0.27,0.28-0.58,0.28-1.72c-1.33,0-1.06,0.02-1.25,0.33l-0.32-0.3
		c-0.03,0.01-0.17,0.07-0.21,0.08c0.03-0.77,0-1.23,0-2.05c0.55,0.07-0.11,0.04,0.2-0.18c0.25-0.17-0.33-0.11,0.18-0.05l0.13,0.06
		c0.03,0.01,0.09,0.06,0.11,0.07l0.53,0.09c0.64,0.04,0.2-0.15,0.54-0.04c0.01,0,0.04,0.02,0.05,0.02c0.01,0,0.04,0.02,0.06,0.03
		c0.89,0.4,2.32,1.68,3.05,2.37l0.26,0.44c0.18,0.25,0.27,0.15,0.29,0.59c0,0.01-0.01,0.05-0.01,0.06
		c-0.01,0.61-0.19-0.39,0.02,0.24c0.13,0.38,0.18,0.29,0.12,0.7c1.14,0.12,0.73,0.35,0.86,0.81l0.09,0.14
		c0.01,0.01,0.05,0.03,0.06,0.04c0.01,0.01,0.04,0.02,0.06,0.03l0,0.78c0.03-0.02-0.21-0.08,0.14,0.03c1,0.31-0.48-0.06,0.32,0.11
		l-0.3,0.72c-0.25-0.13-0.6-0.49-1.1-0.43c-0.94,0.11-0.24-0.03-0.56,0.24l0.89,0.22c0.45,0.23,0.77,0.44,0.89,0.89
		c-2.85-1.51-6.36,3.57-3.89,5.22c0.2,0.13,0.39,0.2,0.57,0.32c0.36,0.24,0.59,0.64,0.98,0.9c0.55-0.37,0.94-0.79,1.37-1.29
		c5.45-6.35-2.27-13.75-6.35-14.59c-1.36-0.28-3.65-0.44-4.74,0.17c-2.5,1.41-1.71,2.18-3.57,1.92c-2-0.28-1.56-0.22-3.06,0.66
		c-0.31,0.19-0.68,0.35-0.85,0.59c0.45,0.61,2.89,1.58,4.33,0.89c-0.02,0.95-0.31,1.41,0.1,2.79c0.19,0.66,0.61,1.65,0.9,2.2
		c0.7-0.47,1.27-2,1.46-2.87c0.22-1.04,0.3-0.73-0.19-1.5c-0.24-0.38-0.56-1.11-0.6-1.62c0.26-1.01,3.86-3.19,6.37,0.5
		c0.49,0.72,0.54,1.08,0.8,1.64L48.73,64.27z M36.64,60.94c0.46-0.04,0.25-0.11,0.67-0.11c0.83,0,1.74,0.28,2.44,0.44
		c-0.16,0.11,0.11-0.02-0.21,0.12c-0.66,0.28-1.3,0.26-2,0.1c-0.35-0.08-0.51-0.18-0.89-0.21V60.94z M52.5,71.04
		c0.07,0.81,0.44,0.57,0,1.22c-0.25-0.09-0.09-0.02-0.27-0.17c-0.03-0.02-0.23-0.29-0.24-0.32c-0.37-0.69,0.21-1.76,1.07-1.83
		C53.1,70.11,53.46,70.78,52.5,71.04z"
          />
          <path
            class="st0"
            d="M89.9,31.64c0.02,0.03,0.05,0.13,0.05,0.16c0.01,0.08-0.01,0.21-0.01,0.25c-0.17,0.94-1.22,2.49-1.35,3.01
		c0.27-0.09,0.55-0.05,0.45-0.45c0.01,0.42,0.06,0.38,0.19,0.56c0.02-0.03,0.04-0.07,0.05-0.1c0.01-0.03,0.03-0.07,0.05-0.11
		c0.35-0.91,0.03-0.51,0.26-0.55l0.28,0.01c0.54-0.01,0.29-0.14,0.84-0.08c0.46,0.05,0.36-0.31,1.05-0.13
		c0.03,0.01,0.09,0.08,0.11,0.05c0.13-0.18,0.61,1.17,0.66,1.35l1.28,0v1.33h3.55l-0.16,2.81c-0.01,0.04-0.02,0.08-0.02,0.11
		l-0.28,0.73c-0.09,0.24-0.21,0.32-0.31,0.62c0.22-0.13,0.05-0.02,0.3-0.12c0.72-0.29,1.37-0.16,2.42-0.16
		c0.07-0.41,0.02-0.06,0.04-0.35c0-0.02-0.01-0.08-0.01-0.12c0.45,0.08,0.19,0.73,1.38-0.03c0.49-0.31,1.02-0.66,1.61-0.47
		c0.4,0.13,0.93-0.14,1.44-0.23c0.45-0.08,1.2-0.04,1.67-0.22c0.62-0.02,0.27-0.13,0.83,0.01c0.74,0.18,0.62,0.01,1.03,0.25
		c0.72,0.42,1.22,0.27,2.36,0.27l0.29-0.38c0.07-0.09,0.12-0.13,0.19-0.2c-0.28-0.58-0.71-0.79-1.14-1.33
		c-0.52-0.66-0.15-0.37-0.12-0.41c-0.03,0.01-0.08,0.01-0.11,0.01l-0.4-0.02c-0.14-0.02-0.24-0.05-0.41-0.06l-0.77-0.02
		c0.14-0.18-0.15-0.59-0.67-0.73c-0.19-0.05-0.46-0.06-0.55-0.08c-0.63-0.17,0.22-0.16-0.34-0.24l-2.2-0.23
		c-1.28-0.09-0.43-0.22-1.49,0.1c-0.41,0.13-0.57-0.25-0.58-0.28c-0.01-0.03-0.03-0.04-0.05-0.06c-0.46,0.07-0.33,0.15-0.41,0.16
		c-0.23,0.05-0.29-0.28-1.14-0.28c-0.71,0.01-1.63,0.01-2.24-0.1c-0.98-0.18-1.03-0.74-1.47-0.88c-0.19-0.06-1.45-0.06-2.07-0.06
		v-1.78h-1.33c0-1.92-0.12-0.7-0.62-2.21c-0.01-0.02-0.02-0.05-0.02-0.07c-0.01-0.02-0.03-0.06-0.03-0.08l-0.08-0.18
		c-0.64,0.3-0.68,0.46-1.1,0.36c-0.55-0.14-0.01,0.31-0.23-0.17c-0.1,0.72,0.05-0.05-0.22,0.42c-0.09,0.16-0.15,0.3-0.23,0.47
		C89.74,31.65,89.71,31.8,89.9,31.64"
          />
          <path
            class="st0"
            d="M157.04,131.85c-0.24,0.73-0.08,0.67,0.11,1.22c-0.46,0.24-0.59,0.67-0.91,0.99
		c-0.09,0.09-0.18,0.15-0.28,0.25l-0.47,0.75c-0.21-0.31-0.32-0.51-0.78-0.55c0.01,0.43-0.07,0.06,0.09,0.35
		c0.17,0.31,0.3,0.05,1.21,1.9c0.37,0.77,0.18,0.75,0.85,0.92c0.6,0.16,0.91,0.13,1.07,0.71c-0.31,0.42-1.09,0.52-1.66,0.67
		c-1.51,0.37-1.27,0.53-1.78,0.67c0,1.17,0.64,2.61,0.85,3.82c0.14,0.84-0.17,1.26-0.44,1.81c-0.45,0.88-0.41,0.67-0.41,1.92
		c0.56-0.65,0.26-1.47,1.55-1.78c0-1.86-0.22-3.34-0.22-5.1c0.59-0.14,2.1-0.33,2.77-0.33c-0.19,0.28-0.3,0.29-0.48,0.53
		c-0.49,0.62,0.12,0.04-0.19,0.69c-0.64-0.07-0.16-0.09-0.41-0.15c-0.39-0.1-0.17-0.11-0.48,0.04c0.12,0.45,1.02,0.94,1.49,1.07
		c1.21,0.33,0.6-0.64,1.94,0.16c1.55,0.92,1.91,1.47,3.42-0.03c1.48-1.48,0.96-1.88,0.14-3.52c-0.76-1.51,0.02-2.01-0.26-3.4
		c-0.09-0.44-0.15-0.66-0.63-0.7c0,0.08,0.01,0.25,0,0.33l-0.11,0.34c-0.1-0.44-0.29-0.75-0.33-1.22c-0.89,0.24-0.28,1.32-0.49,2.29
		c-0.11,0.5-0.25,0.61-0.33,1.23c-0.08,0.62,0.12,0.56,0.26,1.14c-1.96,0-1.19,0.7-1.5,1.28c-0.17,0.31-0.02,0.2-0.31,0.01
		c-0.21-0.13-0.27-0.24-0.39-0.31c-0.41-0.21-0.71,0.03-1.12,0.13c1.21-1.8,1.12-0.75,1.87-1.12c0.78-0.4-0.47-0.97,1.24-1.87
		l0.22-4.33c0.73,0,1.17-0.1,2-0.11c0.67,0,1.25-0.15,1.74-0.37c1.29-0.58,0.33-1.33,1.25-3.08c-0.88,0.07-1.11,1.18-1.37,1.96
		c-0.21,0.64-0.28,0.6-1.07,0.6c-3.6-0.02-2.96-1.15-4.02,2.63c-0.08,0.27-0.3,1.11-0.42,1.35c-0.22-0.16-0.53-0.6-0.8-0.86
		c-1.11-1.03-1.55-0.14-2.08-2.24c-0.03-0.12-0.07-0.28-0.1-0.36C157.1,131.81,157.35,132.09,157.04,131.85 M157.26,136.62
		c-0.28-0.21-0.52-0.66-0.55-1.11c-0.93,0.08-0.28,0.14-1-0.33c0.22-0.32,1.48-1.7,1.78-1.78c-0.08,0.17-0.31,1.19-0.33,1.45
		C157.09,135.62,157.26,136.01,157.26,136.62z M157.26,136.62c0.21-0.34,0.11-0.94,0.11-1.33c-0.01-0.64,0.1-0.84,0.11-1.33
		c0.38,0.1,0.57,0.3,0.65,0.68c0.15,0.64,0.34,0.86,0.34,0.87v2.22c0.18-0.2,0.12-0.09,0.19-0.37c0.05-0.18-0.05-0.82,0.92-1.08
		c0.17,0.74,0.21,0.7,1.11,0.78c-0.12,0.44-0.19,0.34-0.84,0.83c-0.39,0.29-0.63,0.51-1.05,0.73
		C158.35,138.31,157.4,137.23,157.26,136.62 M163.14,140.06c0-0.26,0.04-0.13,0.11-0.44c2.28,0,0.98,2.97,0.18,1.27
		C163.35,140.71,163.14,140.23,163.14,140.06z M161.59,141.5c0.52,0.12,1.02,0.4,1.55,0.44c-0.04,0.49-0.36,0.68-0.67,0.89
		C161.66,142.62,161.59,142.14,161.59,141.5z"
          />
          <path
            class="st0"
            d="M69.15,149.71c0.48,0.19,0.79,0.03,1.28-0.06c0.82-0.15,0.55-0.06,0.5-0.72c0.26,0.14,0.37,0.24,0.55,0.44
		c-0.1,0.24-0.05,0.07-0.14,0.19c-0.24,0.31,0.02-0.27-0.19,0.25l0.89,2.22c0.02,0.01,0.26,0.05,0.33,0.11
		c0.01,0.42,0.3,1.21,0.44,1.57l0.6,0.09c-0.13,0.28,0.05,0.39,0.06,1l0.09,0.02c0.02,0.02,0.1,0.09,0.12,0.11
		c0.1,0.07-0.05,0.19,0.61,0.22l1.05,0.08c0.31-0.13,0.05,0.1,0.26-0.26c-0.6,0.12-0.86-0.26-0.8-0.83c0.45-0.2,0.38-0.28,0.89-0.11
		c0.57,0.19,0.07-0.03,0.76,0.02c0.29,0.02,0.48,0.13,0.79,0.21c-0.16,0.14-0.16,0.18-0.45,0.22c0.43,0,0.33-0.01,0.65,0.2
		l-0.12,0.2c-0.08,0.19-0.21-0.08-0.04,0.22c0.16,0.28,0.15,0.08,0.22,0.07c0.03,0,0.15-0.04,0.19-0.05
		c0.03-0.01,0.07-0.03,0.1-0.04c0.03-0.01,0.07-0.03,0.11-0.04c0.58-0.45-0.06-1.29,1-2c0.15,0.22,0.2,0.31,0.33,0.56
		c0.72,0,1.02-0.15,1.66-0.12c0.51,0.03,1.08,0.19,1.56,0.23c-0.58-0.5-1.89-0.78-2.77-1.22c-1.37-0.69-3.56-2.3-4.85-3.36
		c-0.81-0.67-1.47-1.18-2.92-1.18c-0.33-1.26-3.5-2.38-4.86-2.92c-0.59-0.23,0.06-0.29-0.58-0.08c-0.86,0-0.7-0.11-1.04-0.2
		c-0.32-0.09-0.04-0.07-0.38-0.03c-0.01,0-0.22,0.05-0.25,0.05c-0.38,0.12-0.22-0.08-0.21,0.54c0,0.22,0.18-0.04-0.11,0.12
		l-0.2,0.09c0.08,0.33,0.15,0.26,0.27,0.52c0.12,0.25-0.04,0.06,0.1,0.42l-0.4-0.09c0.53,0.38,0.69,0.75,0.9,1.52
		c0.03,0.12,0.07,0.18,0.07,0.39c0,0.59,0.06-0.4-0.02,0.21l0.11,0.42c0.16,0.39-0.15,0.29,0.44,0.46c0.45,0.12,1.37-0.05,1.7,0.06
		c0.82,0.26-0.81,0.02,1.05,0.2C68.91,149.65,68.84,149.68,69.15,149.71"
          />
          <path
            class="st0"
            d="M99.89,163.14c0.84,0,2.98-1.08,3.44,0.89c-1.16-0.31-0.89-1.34-3.4-0.52c-1.21,0.4-2.31,0.74-3.52,1.14
		c-1.52,0.5-5.6,2.18-5.95,3.48l1.38-0.95c0.2-0.13,0.29-0.17,0.48-0.3c1.78-1.14,4.49-2.53,6.79-2.53
		c-0.42,0.8-0.65,1.16-1.45,1.21c-0.6,0.04-0.5-0.14-0.77,0.23c0.59,0.14,1.37,0.56,1.66,1c-0.14,0.59-0.33,0.43-0.33,1.22h0.22
		c0.1-0.41,0.42-0.87,0.67-1.33c0.25,0.06,0.87,0.33,1.11,0.44c-0.3,0.56-0.58,1.09-0.89,1.66c0.45-0.12,0.24-0.15,0.67-0.78
		c0.27-0.39,0.36-0.5,0.74-0.81c0.89-0.74,0.55-0.15,1.93-1.07c0,0.44,0.04,0.45,0.11,0.78c1.27,0,3.43-1.57,3.85-2.25
		c0.48-0.79,2.51-2.06,0.51-4.32c-0.43-0.48-0.94-0.97-1.8-0.97C103.29,159.37,100.61,161.78,99.89,163.14"
          />
          <path
            class="st0"
            d="M167.47,91.57c0,1.57-0.06,2.28,0.16,3.83c0.55,3.85-0.64,3.19-1.83,3.82c0.64,0.95,2.27,0.28,1.92,2.8
		l-0.32,4.62c0.07,2.71,0.36,3.74,2.65,4.64l1.74,0.81l0.58-2.32c0.39-2.42-2.35-1.87-2.35-0.68c0,0.46,0.43,0.85,0.89,0.89
		c0.24-0.36,0.18-0.08,0.22-0.56c-0.25-0.06-0.25-0.09-0.55-0.11c0.16-0.59,1.11-0.72,1.11,0.44c0,1.65-3.33,1.87-3.33-3.88
		c0-1.82,0.29-3.16,1.37-4.41c2.16-2.52-0.85-1.16-1.17-2.12c-0.11-0.34,0.56-0.27,0.95-0.32c0.56-0.08,0.84-0.03,0.96-0.48
		c0.11-0.26,0-0.43-0.08-0.57c-0.11-0.2-0.25-0.35-0.38-0.52c-0.31-0.41-0.59-0.62-0.86-1.03c-0.43-0.66-0.25-0.66-0.36-1.53
		c-0.14-1.03-0.43,0.15-0.43-2.67c0-2.13,1.12-4.8,2.59-4.37c0.71,0.21,0.74,0.82,0.74,1.71l-1,0.11c0.01-0.49-0.04-0.51,0.44-0.55
		c-0.09-0.2-0.13-0.36-0.22-0.56c-0.51,0.24-0.95,0.1-0.81,0.92c0.3,1.66,2.36,1.05,2.36-0.36c0-0.52-0.52-2.04-0.67-2.66
		c-0.45,0.1-0.65,0.4-1.6,0.73c-0.63,0.22-1.1,0.39-1.54,0.79C167.9,88.66,167.47,90.16,167.47,91.57"
          />
          <path
            class="st0"
            d="M92.23,38.19c0.14,1.41-0.76,0.78-0.42,2.45l0.08,0.24c0.25,0.77,0.16,0.7-0.07,0.99l-0.74,0.94
		c-0.33,0.6,0.05,0.06-0.11,0.59l-0.07,0.45c0.28-0.01,0.4-0.3,0.44-0.44h0.44c0.37-0.17,0.3-0.15,0.58-0.41
		c0.24-0.22,0.38-0.31,0.64-0.48c0.8-0.52,1.26-0.72,2.09-1.34c0.84-0.63,1.55-1.12,1.57-1.98c-0.61-0.32-1.09-0.41-1.89-0.22
		c0.08-1,1.11-0.93,2-0.33c0,1.5-0.12,1.89-1,2.56c-0.47,0.36-1.7,1.07-2.33,1.22c1.32,1.98,6.37-1.05,8.38-2.04
		c0.31-0.15,0.31-0.1,0.49-0.4c-0.6-0.19-1.13,0.16-1.61,0.47c-1.19,0.76-0.94,0.11-1.38,0.03c0,0.04,0.01,0.09,0.01,0.12
		c-0.02,0.29,0.03-0.05-0.04,0.35c-1.05,0-1.7-0.13-2.42,0.16c-0.25,0.1-0.08-0.01-0.3,0.12c0.1-0.3,0.22-0.38,0.31-0.62l0.28-0.73
		c0.01-0.03,0.02-0.07,0.02-0.11l0.16-2.81h-3.55v-1.33l-1.28,0c-0.05-0.18-0.53-1.53-0.66-1.35c-0.02,0.03-0.08-0.04-0.11-0.05
		c-0.7-0.18-0.6,0.18-1.05,0.13c-0.56-0.06-0.31,0.07-0.84,0.08L89.6,34.4c-0.23,0.04,0.08-0.36-0.26,0.55
		c-0.02,0.04-0.04,0.08-0.05,0.11c-0.01,0.03-0.03,0.07-0.05,0.1c-0.13-0.17-0.19-0.14-0.19-0.56c0.1,0.39-0.18,0.35-0.45,0.45
		c0.12-0.52,1.18-2.06,1.35-3.01c0.01-0.04,0.02-0.17,0.01-0.25c0-0.03-0.03-0.13-0.05-0.16c-0.41-0.03-0.12-0.18-0.63,1.03
		c-0.76,1.77-1.9,2.71-1.63,3.45c0.13,0.34,0.54,0.7,0.97,0.57c0.82-0.25,0.31-0.99,1.82-1.64c0.39-0.17,0.91-0.44,1.25-0.52
		c0.15,0.67,0.69,1.8,0.63,2.64C92.28,37.55,92.09,37.76,92.23,38.19"
          />
          <path
            class="st0"
            d="M70.7,43.07c0.41-0.14,0.25-0.05,0.33,0c-0.54,1.13-1,2.51-0.87,3.76c0.11,1.03-0.17,0.34-0.24,1.23
		c0.32,0.22,0.57,0.32,1.11,0.33c0.51-0.97,0.83-2.18,1.33-2.55c-0.05,0.58-0.54,1.76-0.79,2.2c-0.64,1.12-1.14,0.54-2.15,0.63
		c-0.82,0.08-1.47,0.75-1.45,1.49c0.02,0.85,1.08,0.9,1.29,1.66c-0.43,0.2-0.91,0.33-1.33,0.55c0.1,0.45,0.23,0.88,0.33,1.33
		c0.59-0.39,0.47-0.8,1.26-1.18l0.62-0.27c-0.15-0.64-0.49-0.59-0.66-1.33c1.08-1.61,3.51,1.12,2.97-1.19
		c-0.18-0.77-0.51-1.39,0.4-1.76c0.39-0.16,1.62-0.45,2.07-0.48c-0.02-0.75-0.38-0.47-0.06-0.73c0.07-0.06,0.72-0.24,0.84-0.27
		c-0.07-0.81-0.38-0.49-0.44-1.22c0.7-0.16,1.31-0.36,1.66-0.89c-0.29-0.43-0.13-0.29-0.55-0.56c-1.13-0.73-0.02-2.01,0-2.66
		c-0.88,0.47-0.61,0.34-1.36,1.08c-0.53,0.53-0.81,0.99-1.19,1.25c0.16-0.7,0.71-0.92,0.78-1.78c-0.66,0.15-1.21,0.9-1.59,1.41
		c-0.23,0.32-0.43,0.59-0.65,0.9c-0.23,0.33-0.33,0.67-0.65,0.9c0.1-1.22,2.34-3.31,1.01-3.02c-0.74,0.17-0.74,0.69-1.07,1.73
		c-0.13,0.42-0.14,0.58-0.38,0.85c0.05-0.57,0.8-2.48,1-3.33h-0.78l-0.18,0.59c-0.01,0.02-0.02,0.05-0.04,0.07
		c0-0.05,0.01-0.43,0.01-0.45c-0.07-0.41,0.07-0.24-0.23-0.44C71.04,42.08,70.85,42.25,70.7,43.07 M73.81,43.52l-0.06,0.24"
          />
          <path
            class="st0"
            d="M116.87,110.1c-0.19,0.56,0.36,1.14,0.18,2.28c-0.26,1.59,0.74,2.49,2.04,3.27c0.37,0.22,1.44,0.78,1.9,0.88
		c-0.57-0.85-2.47-2.2-2.81-2.98c-0.49-1.12,1.65-1.45,1.17-4.27c-0.26-1.55-0.94-2.7-2-3.77l-3.25-2.52c0.6,0.05,0.14,0.17,0.66,0
		c0.22-0.02,0.24-0.03,0.44-0.11l-0.1-0.02c0-0.01-0.01-0.03-0.02-0.04l0.05-0.24c0.01-0.01,0.02-0.04,0.03-0.05
		c0-0.01,0.02-0.04,0.03-0.05l0.16-0.34c0.03-0.31-0.12-0.01-0.17-0.59c-0.02-0.3,0.01-0.79,0.01-1.11h-1.89
		c0,0.72,0.26,1.33-0.18,1.74l0.17,0.26c0.01,0.02,0.26,0.38,0.08,0.37l-0.29-0.15c0.32,0.98-0.19,0.93,0.22,1.33
		c0.18,0.18,0.16,0.15,0.22,0l-0.55,0.11l-0.06,0.31c0.07,0.62,0.18,0.06-0.27-0.31c0.06,0.69-0.63,1.91-0.32,2.61
		c0.01,0.02,0.04,0.06,0.05,0.08c0.24,0.48,1.12-0.28,1.84,0.1l0.15,0.09c0.95,0.53,1.16,0.53,1.98,1.53l0.49,0.88
		C117.06,109.93,116.9,110,116.87,110.1"
          />
          <path
            class="st0"
            d="M140.62,161.03c-0.65-0.17-0.88-0.8-1.11-1.55l-0.19,0.67c-0.17,0.7-0.01,0.1-0.16,0.44
		c-0.16,0.36-0.06,1.9,0.01,2.05c0.15,0.3,0.44-0.22,0.09,0.35c-0.35,0.57,0.42-0.12-0.08,0.05l-0.08,0.48
		c-0.03,0.74-0.07,1.25,0.2,1.21l-1.33-0.03c0.64-0.27,0.38-0.08,0.44,0c-0.41-0.05-0.58-0.23-0.59-0.59l0.26-0.52
		c-0.01,0.01-0.12,0.03-0.14,0.03c-0.02,0-0.05-0.01-0.07-0.01l-0.57-0.24c-0.1,0.57-0.12,0.55-0.78,0.55
		c1.08,2.03,2.82,1.12,4.6,0.72c1.12-0.25,2.76-0.37,3.31-1.35c0.53-0.97-0.17-2.11-0.36-2.91c0.18-0.27,0.32-0.38,0.53-0.69
		c0.59-0.87,1.13-1.74,1.13-2.87c-1.44-0.96-1.16,0.01-2.14,0.09c-0.46,0.04-0.49-0.14-0.64-0.43c0.15-0.22,1.28-0.97,0.24-1
		c-1.54-0.05-1.14,2.49-2.9,3.67C140.42,159.44,140.62,160.56,140.62,161.03 M140.84,162.37c0.12-1.44,1.38-1.58,2.22-1.78
		c-0.16,0.6-0.64,0.6-1.11,0.89C141.54,161.74,141.36,162.23,140.84,162.37z M140.62,161.03l-0.56,2.11L140.62,161.03z
		 M140.06,163.14h-0.33c0-0.66-0.01-1.3,0.33-1.55C140.08,162.54,140.7,163.14,140.06,163.14z"
          />
          <path
            class="st0"
            d="M44.51,138.84c0.53,0,0.96-0.07,1.33,0.11c-0.13,0.32,0.08,0.22-0.55,0.22c0.24,0.9,1.54,1.1,1.78,2
		c-0.2,0.73-1.08,0.66-2,0.66c0.4-0.55,1.09-0.1,1.11-1.11c-1.85-0.43-3.19,0.65-3.22,2c0.49-0.12,1.03-0.61,1.44-0.89l2.46,1.54
		c0.49,0.21,1.05,0.31,1.43,0.34c1.55,0.11,1.22-0.15,1.87-0.21c0.13,0.53,0.35,0.34,0.88,0.22c0.32-0.07,0.77-0.19,1.12-0.22v-0.22
		c-0.43-0.12-0.71-0.41-1-0.67c-0.45,0.3-0.38,0.52-1,0.67c0.27-1.17,2-5.32,2-6.22c-2.38-1.26-1.31,0.92-3.78,0.78
		c-0.34-0.02-0.58,0.02-0.84,0.14c-0.47,0.22-0.16,0.25-0.59,0.41c-0.15-0.13-0.14-0.12-0.33-0.22c0.28-1.05,1.45-0.9,2.44-0.67
		c0.12-0.49,0.34-0.7,0.56-1.22h-0.44c-0.1,0.48-0.36,0.75-0.82,0.72c-0.64-0.05,0.36,0.19-0.18-0.06
		c-0.32-0.15-0.48-0.12-0.85-0.02c-0.95,0.24-0.52,0.77-0.82,0.4c-0.18-0.22-0.03,0.08-0.04-0.43l-0.31,0.03
		c0.33,0.04,0.05,0.25,0,0.8c-0.28,0.23,0.4,0.19-0.44,0.19c0.02-0.01-0.04-0.17,0.24,0.03c0.3,0.22,0-0.11,0.08,0.14
		c0,0.02,0,0.08,0,0.11c0,0.02,0,0.05,0,0.06l0.12,0.33c-0.7-0.27-0.78-0.19-1.52-0.19C44.57,138.63,44.6,138.63,44.51,138.84
		 M48.18,139.95c0.6-0.14,0.56-0.16,0.89,0.33c-0.32,0.08-0.51,0.19-0.78,0.33C48.28,140.36,48.25,140.12,48.18,139.95z"
          />
          <path
            class="st0"
            d="M57.83,39.41c-0.42,0-0.21,0.07-0.67,0.11c0.46,0.86,2.19,3.25,2.33,3.77h0.33c0.13-0.58,0.42-0.6,0.56-1.11
		c2.13,0.18,0.98,0.67,2.22,0.67c1.76,0,2.66-2.87,2.66-4.22c0-1.17-2.18-4.75-5.04-0.94C58.51,39.98,59.66,39.41,57.83,39.41"
          />
          <path
            class="st0"
            d="M48.73,64.27c-0.12,0.51,0.11,0.32,0.29,1.27c0.21,1.17,0.17,2.2,0.15,3.4c-0.02,1.92,0.28,0.89,1.25-0.09
		c0.87-0.88,1.87-1.91,3.3-1.91l-0.89-0.22c0.32-0.27-0.38-0.13,0.56-0.24c0.5-0.06,0.86,0.3,1.1,0.43l0.3-0.72
		c-0.8-0.17,0.68,0.2-0.32-0.11c-0.35-0.1-0.11-0.05-0.14-0.03l0-0.78c-0.02-0.01-0.06-0.02-0.06-0.03
		c-0.01-0.01-0.05-0.03-0.06-0.04l-0.09-0.14c-0.13-0.46,0.29-0.7-0.86-0.81c0.07-0.41,0.01-0.32-0.12-0.7
		c-0.21-0.63-0.04,0.37-0.02-0.24c0-0.01,0.01-0.05,0.01-0.06c-0.01-0.44-0.1-0.34-0.29-0.59l-0.26-0.44
		c-0.73-0.68-2.16-1.97-3.05-2.37c-0.01-0.01-0.04-0.02-0.06-0.03c-0.01,0-0.04-0.02-0.05-0.02c-0.34-0.11,0.11,0.07-0.54,0.04
		l-0.53-0.09c-0.02-0.01-0.08-0.06-0.11-0.07l-0.13-0.06c-0.51-0.06,0.07-0.12-0.18,0.05c-0.32,0.22,0.35,0.25-0.2,0.18
		c0,0.82,0.03,1.28,0,2.05c0.04-0.01,0.18-0.07,0.21-0.08l0.32,0.3c0.19-0.31-0.08-0.33,1.25-0.33c0,1.14,0.17,1.45-0.28,1.72
		c0-1.13,0.07-0.82-0.22-0.96c-0.65-0.32-0.45,0.09-0.44,0.93c0,0.43,0.15-0.04,0.17,0.61C48.77,64.13,48.73,64.26,48.73,64.27"
          />
          <path
            class="st0"
            d="M155.49,126.52c0.09,0.28-0.18-0.09,0.1,0.14c0.13,0.11,0.09,0.06,0.26,0.16c2.37,1.4,1.44,4.85,2.45,6.43
		c0.29,0.45,0.62,0.39,1.07,0.59c0.33,0.15,0.68,0.46,1,0.67c0.36-0.74,0.53-4.25,0.3-5.3c-0.19-0.89-0.62-0.46-0.69-1.91
		c-0.05-1.01-0.1-1.62-0.83-2.11c0,0.56-0.01,0.58-0.11,1c-0.35-0.09-0.41-0.2-0.61-0.5c-0.72-1.16-0.18-2.6-1.17-2.38
		c-0.23,0.14-0.92-0.07-2.11-0.24c-0.73-0.11,0.09-0.07-0.77-0.2c0.13,0.32,0.06,0.17,0.21,0.46c0.55,1.11,0.21,0.4,0.18,0.85
		c-0.04,0.44,0.22,0.45-0.28,0.82l0.5,0.84C155.35,126.67,155.15,125.83,155.49,126.52"
          />
          <path
            class="st0"
            d="M151.93,67.48c0.02,0.7,0.5,1.29,0.67,2c-0.66-0.32-0.99-0.34-1.44,0.33c0.88,0.46,1.16,0.12,1.66,0
		c0.09,0.39,0.19,0.63,0.37,0.96c0.75,1.38,0.97,1.42,2.39,1.93l4.23,1.54c0.32-0.68,0.59-1.84,0.21-2.54
		c-0.29-0.52-1.13-1.17-1.56-1.65c-1.5-1.69-1.3-4.26-5.12-3.07C152.85,67.15,152.41,67.44,151.93,67.48 M155.26,68.82
		c0.91,0.08,2.73,2.32,2.89,3c-1.12-0.59-1.45-1.27-2.15-2.17C155.73,69.29,155.49,69.15,155.26,68.82z"
          />
          <path
            class="st0"
            d="M110.1,45.96c-0.2-0.75,0.14-0.3,0.04-0.69c0-0.02,0.26,0.42-0.05-0.09c-0.25-0.44-0.21,0.14-0.17-0.27
		c0.05-0.65-0.02,0.26,0.18-0.5c-0.27,0.03-1.71,0.18-1.44,0c-0.4-0.29-0.33,0.41-0.33-0.44v0.11h-0.44
		c-0.12-0.49,0.06-0.23-0.12-0.34c-0.02-0.01-0.09-0.02-0.11-0.02l-0.34,0.15c-0.02,0.01-0.05,0.03-0.07,0.04l-0.26,0.16
		c0.87,1.12,0.17,0.93,0.78,0.89c0,0.93,0.27,0.23,0.33,1.11c0,0.24-0.12-0.05,0.19,0.36c0.22,0.28,0.15,0.04,0.26,0.42
		c-0.24-0.12-0.32-0.09-0.59-0.19c-0.87-0.34-0.95-0.72-1.21-1c-0.2-0.21-0.02-0.09-0.2-0.14h-0.44c0.02-0.48,0.04-0.27-0.09-0.72
		l-0.06-0.19c-0.17-0.38-0.13-0.28-0.29-0.31l0-0.11c-0.27,0.07-0.25-0.01-0.47,0.38c-0.24,0.42,0.02,0.4-0.41,0.62
		c-0.02,0.47-0.21,1.28-0.22,1.78h-0.22c-0.2-0.85-0.38-1.11,0.04-2.29c0.09-0.25,0.07-0.05,0.08-0.26
		c-0.14-0.33,0.11-0.39,0.11-0.39c-0.23-0.06-0.36-0.06-0.58-0.38c-0.21-0.3,0.02-0.01,0.03,0c-0.24,0.06-0.24,0.02-0.45,0.11
		c-0.55-0.03-1.12,0.75-1.66,1.11c0.03-0.41,0.18-0.66,0.41-0.92c0.39-0.43,0.62-0.4,0.7-0.63c-0.03-0.02-0.08-0.14-0.08-0.16
		c0.06-0.56-0.42,0.2,0.1-0.17c0.17-0.12,0.35-0.1,0.53-0.05c-0.02-0.02-0.05,0.39-0.07-0.27l0.09-0.57
		c0.24-0.62,0.19-0.32,0.31-0.43c0.22-0.5,0.65-1.02,0.67-1.66c-1.01,0.23-1.4,0.09-2.27,0.62c-3.17,1.95-1.31,7.76,4.16,7.6
		c0.95-0.03,1.64-0.33,2.28-0.72c0.26-0.16,1.19-0.92,1.32-1.22C110.17,45.99,110.11,46.16,110.1,45.96"
          />
          <path
            class="st0"
            d="M118.2,97.56c0.8,0.35,1.35,0.56,1.97,1.14c0.24,0.23,0.56,0.53,0.76,0.8c0.63,0.84,0.91,1.85,3.04,1.85
		l2.18-0.27c0.51-0.1,0.37,0.01,0.59-0.29c-0.68-0.06-1.63-0.23-2.34-0.32c-3.3-0.46-1.38-1.12-2.32-3.23
		c-0.17-0.37-0.26-0.5-0.51-0.82l-0.46-0.53c-0.23-0.15-0.03-0.08-0.25-0.09c-0.38,0.12-0.52-0.05-0.93-0.28l-0.52-0.27
		c-0.02-0.01-0.05-0.03-0.07-0.05l-0.26-0.18c-0.07,0.34,0.02,0.12-0.11,0.35c-0.01,0.02-0.03,0.05-0.04,0.06l-0.18,0.15
		c-0.22-0.15-0.54-0.11-0.89-0.11c-0.2-0.5,0.28-0.02-0.22-0.22c0.2-0.54,0.28,0.37,0.26-0.55c-0.8-0.12-1.28-0.28-2.06-0.22
		c-0.74,0.06-0.45,0.08-0.53,0c-0.12,0.49-0.19,0.39,0.22,0.66c-0.13-0.14-0.03-0.04-0.56-0.08c-0.76-0.05-0.47-0.08-0.9-0.19
		c-0.64-0.17-0.2,0.1-0.72-0.23c-0.1-0.06,0.44-0.21-0.47-0.18c-0.95,0.03-0.3,0.02-0.57,0.24c0,0.29-0.11-0.11,0.08,0.38l0.38,0.98
		c0.08,0.22-0.02-0.08,0.09,0.24c0.36,1.08,0.44,0.92,0.45,2.39c0.16,0.07,0.04,0.01,0.23,0.11c0.02,0.01,0.05,0.03,0.07,0.04
		c0.35,0.19,0.34,0.25,0.71,0.2l0.21-0.15c0-0.01,0.02-0.04,0.03-0.05c0.07-0.21-0.01-0.45-0.14-0.6c-0.26,0.22,0.08,0.14,0.33,0.22
		c-0.23-1.24,0.08-0.41,0.22-1.33c-0.02,0.05-0.29,0.07,0.12,0.15c0.22,0.04,0.34-0.03,0.49-0.08l0.18-0.08
		c0.1-0.04,0.11-0.06,0.25-0.11c0.47-0.16,1.02-0.14,1.51-0.03c0.04,0.01,0.16,0.03,0.2,0.05c0.09,0.03,0.1-0.02,0.25,0.11
		C118.31,97.39,118.11,97.04,118.2,97.56"
          />
          <path
            class="st0"
            d="M138.06,43.63c0.53,0,0.8-0.12,1.22-0.22c0.1-0.42,0.11-0.44,0.11-1c0.52,0.35,0.22,0.21,0.29,0.72
		c0.11,0.77,0.34,0.22,2.3,1.69c0.88,0.66,2.31,1.78,3.41,1.81c0.33-0.69,0.09-0.92-1.03-1.74c-1.47-1.09-1.45-0.8-2.83-2.17
		c-0.35-0.34-0.45-0.56-0.77-0.89c-0.35-0.36-0.5-0.46-1-0.67c-0.39-0.16-0.82-0.37-1.25-0.41c0.1,1.14,0.51,0.97,0.67,1.55
		c-0.65-0.34-2.71-1.07-3.33-1.99c0.22-0.11,0.4-0.16,0.67-0.22c0.27,0.51,0.73,1.14,1.41,1.29c0.57,0.12,0.47-0.15,0.54-0.68
		c0.06-0.47-0.01-0.83-0.17-1.16c0.42,0.1,0.46,0.23,0.89,0.33c0.14-0.21,1.2-1.28,0.53-2.3c-0.12-0.18-0.22-0.28-0.44-0.3
		l-0.5,0.06c-0.5,0.16-0.73,0.65-0.89,1.13c-0.2,0.6-0.09-0.03,0.09,0.64c-1.12,0.26-0.54,0.81-1.44,0.89
		c-0.05-0.59-0.34-0.62-0.79-0.77c-0.05-0.02-0.57-0.18-0.58-0.18c-0.31-0.03-0.31,0.1-0.52,0.18c-0.72-1.07-1.85-0.57-2.66-0.56
		C132.71,41.86,136.5,40.67,138.06,43.63"
          />
          <path
            class="st0"
            d="M150.05,131.07c-0.7-0.02-1.84-0.21-2.33-0.44c0.03,1.38,1.47,0.35,1.77,1.66c-2.2-0.05-2.75-0.91-3.55-1.44
		c0.04,1.6,1.24,1.75,2.4,2.27c1.98,0.89,1.64,1.87,1.82,2.95c0.06,0.34,0.1,0.62,0.23,0.88c0.44,0.9,1.35,1.21,2.44,1.22
		c0.51,0.01,1.65,0.07,2.18,0.15c0.75,0.12,1.23,0.4,2.04,0.4v-0.33c-1.06-0.28-1.54-1.54-1.88-2.22c-0.46-0.9-1.42-1.43-2.33-1.88
		c-2.51-1.24-0.96-1.74-2.4-3.82l-0.93-1.17c0,0.51-0.08,0.62,0.14,0.97C149.83,130.6,150.01,130.57,150.05,131.07"
          />
          <path
            class="st0"
            d="M32.64,102c-0.17-0.54-0.12-0.44-0.64-0.84c-0.02-0.02-0.05-0.04-0.07-0.05c-0.02-0.01-0.05-0.03-0.07-0.05
		c-0.02-0.01-0.05-0.03-0.07-0.05c-0.26-0.19-0.16-0.01-0.3-0.24c-0.4-0.93,0.99-3.02,1.25-4.59c0.09-0.56,0.03-0.17,0.14-0.52
		c0.48-1.51,0.07-2.64,0.38-2.94c0.36-0.35,0.31-0.1,0.36-0.06c-0.15-0.4-0.12-0.38-0.02-0.54c0.37-0.65-0.26,0.18,0.96-0.43
		c0.32-0.16,0.58-0.09,0.95,0.02c1.47,0.42,0.93-0.01,1.78,0.07l0.46,1.16c0,0.03,0.01,0.06,0,0.09c-0.02,0.43-0.1,0.35-0.13,0.41
		c0.12-0.19,0.23,0.06-0.23-0.08c-0.02-0.01-0.08-0.01-0.11-0.04l0.04,0.14c0.03,0.37-0.21,0.73-0.07,1.5
		c0.09,0.5,0.27,1.27,0.32,1.74l-0.2,0.13c-0.01,0-0.03,0.03-0.04,0.03l0.05,1.01c-0.03,0.02-0.09-0.01-0.11,0.03
		c-0.02,0.04-0.08,0.02-0.11,0.03l-0.21,0.04c-0.22,0.04-0.85,0.06-0.9,0.21c-0.4,0.21-0.19-0.05-0.33,0.35
		c3.17,0,3.06,0.44,2.62-0.85c-0.33-0.97-0.74-2.26-0.74-3.48c0.33,0.38,1.05,3.14,1.2,3.69c0.23,0.85,0.14,0.97,0.58,1.09
		c0.44-0.92-0.86-2.59-1.13-4.97c-0.06-0.54-0.37-2.17-0.62-2.49c-0.29-0.37-1.02,0.1-1.99-0.12c-0.38-0.09-0.38-0.06-0.48-0.41
		c1.3,0.11,1.61,0.59,3.22-0.22c1.08-0.55,1.32-1.03,3-1.01c2.67,0.04,3.66,1.62,4.09,1.93c0.65,0.48,0.46-0.47,0.46-1.15
		c-0.34-0.08-0.29-0.11-0.54-0.24c-1.14-0.6-1.85-0.35-2.76-1.12c-1.46-1.24-1.77-0.35-3.91-0.42c-1.18-0.03-1.66-0.47-2.56-0.67
		c-0.19,0.27-0.16,0-0.01,0.25c0.14,0.24,0.01,0.09,0.35,0.21l0.33,0.09c0.01,0.24,0.11,0.19-0.08,0.35
		c0.03-0.01,0.08-0.01,0.1-0.02l0.76,0.02c0.2,0.47,0.37,1.28-0.21,1.48c-0.46,0.15-0.83-0.07-1.44,0.16
		c-0.03-0.03-0.06-0.07-0.08-0.09c-0.02-0.02-0.05-0.06-0.07-0.08c-0.02-0.02-0.04-0.06-0.06-0.08l-0.02-0.09
		c-0.3,0.1,0.05-0.11-0.08,0.15l-0.08,0.16c-0.01-0.02-0.03,0.07-0.05,0.1c-0.03-0.02-0.06-0.05-0.08-0.06l-0.37-0.27
		c-0.05,0.08,0.24-0.05-0.11,0.12c-0.33,0.17,0.12-0.08-0.25,0.06c-0.46,0.17,0.15-0.31-0.16,0.13L33.64,91l-0.2-0.46
		c-0.08-0.25-0.15,0.17-0.06-0.5l0.16-0.68c0.05-0.17-0.73-0.08,0.89-0.46l-0.64-0.32c0.25-0.12,0.46-0.33,0.68-0.37
		c0.13-0.02,0.73,0.02,1.05,0.02c-0.33-0.41,0.14-0.24-0.67-0.35c0,0-0.38,0.02-0.44,0.02c-1.3,0.39-1.47,1.01-1.72,2.5
		c-0.21,1.32,0.12,2.62,0.05,4.05c-0.05,1.01-0.57,2.43-0.89,3.22c-0.64,1.61-0.78,1.77-0.78,3.44c0.26,0.13,0.55,0.21,0.77,0.34
		C32.28,101.69,32.03,101.75,32.64,102"
          />
          <path
            class="st0"
            d="M100.56,115.42c-0.16,0.09,0.01-0.12-0.13,0.21c-0.03,0.08-0.06,0.21-0.11,0.33
		c-0.07,0.21-0.15,0.38-0.28,0.62c-0.66,1.13-1.78,2.02-3.02,2.41c-0.94,0.3-1.48,0.69-1.83,1.83c-0.35,1.13-0.29,1.96-0.29,3.37
		c0.1-0.12,0.04,0.01,0.17-0.27l1-2.67c1.04-2.66,1.85,0.97,5.58-1.96c0.52-0.41,1.34-1.42,1.54-2.02c0.1-0.3,0.1-0.11,0.03-0.41
		c-0.04-0.41-0.18-0.09-0.08-0.61c0-0.01,0.02-0.05,0.02-0.06l0.04-0.08c0-0.02,0.02-0.02,0.02-0.03c-0.02-0.02-0.06-0.04-0.08-0.05
		c-0.02-0.01-0.06-0.03-0.07-0.04l-0.12-0.06c-0.28-0.15-0.17-1.84-0.16-2.07c0-0.33,0.01-0.69-0.12-1
		c-0.28-0.06-0.76,0.1-0.83-0.17c-0.01-0.03-0.04-0.04-0.06-0.05l0.52,0.16c0.15-0.01,0.18-0.09,0.14-0.26l-0.05-0.17
		c-0.12-0.23-1.28-0.06-1.51-0.01c-0.05,0.01-0.07,0.01-0.11,0.02l-0.2,0.08c-0.19,0.09-0.23-0.22-0.26,0.56l-0.09,0.95
		c0.97,0.01,0.27-0.01,0.56,0.22c0,0.04-0.01,1.33-0.15,1.11C100.61,115.3,100.59,115.4,100.56,115.42"
          />
          <path
            class="st0"
            d="M91.79,43.4h-0.44c-0.05,0.15-0.16,0.44-0.44,0.44l0.07-0.45c0.17-0.53-0.21,0.01,0.11-0.59l0.74-0.94
		c0.23-0.29,0.32-0.22,0.07-0.99l-0.08-0.24c-0.35-1.67,0.56-1.04,0.42-2.45l-0.45,0.56c-0.13,0.22-0.26,0.41-0.33,0.66
		c-1.16,0-4.54,0.75-4.83,1.5c-0.12,0.31,0.33,7.13,3.39,7.38c0.14-0.27,0.3-0.47,0.27-0.75c-0.05-0.41-0.26-0.04,0.18-0.69
		c-0.58,0-0.99-0.05-1.39-0.27c-0.64-0.36-1.9-4.06-0.63-4.97c0.39-0.28,0.85-0.2,1.48-0.19c1.35,0.03,0.81,0.08,1.13-1.31
		c0.04-0.16,0.02-0.15,0.12-0.33c0.12-0.22,0.11-0.17,0.19-0.26c0,1.7,0.45,1.52,0.23,1.99c-0.04,0.09-0.4,0.54-0.52,0.72
		c-2.77,4.01-0.83,2.62,3.47,5.21c2.47,1.48,2.32,0.41,3.59,0.08c-0.15-0.66-0.51-0.79-0.67-1.44c-0.59,0.16-0.86,0.8-1.33,1.11
		c-0.38-0.1-0.21-0.11-0.6-0.29c-1.1-0.48-0.73,0.07-1.34-0.66c-0.71-0.86-2.35-1.38-2.83-2.05l0.3-0.5
		C91.77,43.46,91.64,43.78,91.79,43.4"
          />
          <path
            class="st0"
            d="M93.23,15.33l1.67,0.11c0.51,0.2,1.19,0.11,1.77,0.11l-0.24,0.2c-0.24,0.28-0.1-0.01-0.2,0.35
		c0.62,0,0.66,0.11,1.22,0.11c-0.09,0.38-0.29,0.9-0.44,1.22c0.58-0.05,0.68-0.32,1.22-0.45c0,0.39,0.1,0.97,0.22,1.22
		c0.51-0.34,0.38-0.75,0.89-0.89c0.15,0.54,0.19,0.13,0.33,0.67H100c0.1-0.42,0.23-0.47,0.33-0.89c0.56,0,0.5,0.1,1,0.11
		c-0.03-0.4-0.14-0.53-0.22-0.89c0.36-0.09,0.49-0.19,0.89-0.22c-0.15-0.56-0.25-0.25-0.55-0.66c5.15,0,7.93,0.37,12.64,1.01
		c0.77,0.1,3.36,0.75,3.89,0.55l0.56-0.11c-0.16-0.18-0.19-0.1-0.78-0.21c-0.35-0.07-0.73-0.15-1.02-0.21
		C112.24,15.56,106.3,15,101.66,15l0.46-0.54c-0.31-0.02-1.11-0.09-1.24-0.22c-0.18-0.18-0.01-0.02,0.22-1.02
		c-0.58,0.01-0.59,0.18-1.11,0.22c-0.01-0.49-0.11-0.52-0.11-1.11h-0.33c-0.08,0.37-0.3,0.7-0.55,0.89
		c-0.36-0.53-0.38-0.78-0.78-0.89c-0.24,0.49,0.08,0.99-0.33,1.07c-0.13,0.02-0.69-0.33-1-0.4c0.04,0.47,0.23,0.78,0.33,1.22h-1
		c0.21,0.31,0.44,0.47,0.55,0.89c-0.71,0-1.26,0.01-1.81,0.08L93.23,15.33z"
          />
          <path
            class="st0"
            d="M142.5,69.48c0,1.33,0.02,2,0.84,3.15c0.42,0.59,1.07,1.26,1.66,1.67c0.63,0.45,1.4-0.15,1.97-0.69
		c0.49-0.47,1.08-1.35,1.08-2.25c0-1.14-0.76-1.5-1.3-2.14c-0.42-0.5-1.12-1.48-1.14-2.3c-0.34,0.09-0.21-0.01-0.34,0.32
		c-0.34,0.86,0.32,1.35-0.21,2.34c-1.4-0.94-0.58-2.45-1.89-3.33C142.92,67.33,142.5,68.13,142.5,69.48"
          />
          <path
            class="st0"
            d="M108.43,29.31c-1.62-0.04-1.31-2.26-4.44-2.33c0.13,0.5,0.49,0.67,0.67,1.33l0.51,1.6
		c0.62,1.08,2.49-0.24,2.49,1.62c0,2.93-1.99,2.77-2.33,4.22c1.63,0,2.22,0.81,2.91-0.09c1.31-1.72,0.75-3.72,0.75-5.24
		c1.22-0.81,1.83-0.81,2.02-1.65l-0.02-1.68c0-0.56,0.18-0.59,0.22-1.11C109.35,25.98,108.46,28.36,108.43,29.31"
          />
          <path
            class="st0"
            d="M69.7,44.29c0.24-0.28,0.06-0.05,0.11-0.44c-0.64-0.21-0.11,0.01-0.39-0.27c-0.38-0.38-1.54-1.72-1.87-1.83
		c-0.49-0.16-0.44,0.24-0.84-0.38L66.6,41.7c-0.25-0.29,0.02-0.32-0.71-0.33c0.06,0.49,0.15,0.22-0.07,0.67
		c-0.02-0.49-0.11-0.73-0.14-0.79c-0.03-0.07-0.23-0.4,0.02-0.74c0.35-0.49-0.38,0.31,0.1-0.1l0.63-0.35
		c0.81-0.48,1.23-0.9,1.28-1.86c0.23-0.16,0.44-0.22,0.66-0.29c0.61-0.19,0.27-0.03,0.67-0.3c0.27,0.46,0.89,0.23,1.44,0.49
		c0.07,0.95-0.21,0.93-0.06,1.33c0.18,0.47-0.24,0.2,0.4,0.32c0-1.88,0.81-2.14-1.44-2.33c0.16-0.22,0.27-0.41,1.16-0.16
		c0.61,0.17,0.49,0.37,0.95,0.49c-0.88-3.78-4.57-4.18-5.44-2.89c0.4,0.59,1.39,1.43,1.98,1.9c0.34,0.27,0.43,0.28,0.68,0.65
		c-0.32,0.08-0.16-0.01-0.33,0.22c-0.38-0.14-0.18-0.22-0.78-0.22c-0.8,1.19,0.28,1.48-1.67,2.44c-0.65,0.32-0.56,0.52-0.55,1.45
		c0.01,0.7,0,1.41,0,2.11c-1.56,0.36-2.25-0.26-3.2-0.21c-1.8,0.1-1.39,0.78-2.9,0.65c-0.01-0.34-0.05-0.41-0.11-0.66
		c-0.36,0.26-0.47,1.16,0.22,1.44l0.13,0.03c0.49,0.03,0.87-0.27,1.33-0.47c1.51-0.64,2.07,0.36,3.96,0.79
		c0.28,0.07,0.65,0.07,0.91,0.2c0.3-0.45,1.43-2.58,1.55-3.11l1.69,1.64C69.39,44.16,69.26,44.11,69.7,44.29"
          />
          <path
            class="st0"
            d="M137.4,151.71c-0.1,0.7,0,1.51,0,2.33c0.22,0.25,0.01,0.21,0.54,0.24c0.03,0,0.23-0.04,0.24-0.04
		c0.5-0.17,0.08,0.05,0.11-0.09c0.05,1.26,0.11,0.42-0.09,0.94l-0.23,1.4c0.07,0.41,0.75,1.01,1.54,0.81
		c0.03-0.07,0.05-0.17,0.09-0.25c0.13-0.23,0.09-0.18,0.24-0.33c0.31,0.19,0.35,0.38,0.31,0.72c-0.01,0.07-0.04,0.21-0.05,0.34
		c-0.08,0.64-0.11,0.27-0.29,0.57c-0.2,0.34,0.03-0.38-0.02,0.11c0,0.01,0,0.05,0,0.06c0,0.02,0,0.05,0,0.07
		c0,0.02,0,0.05-0.01,0.07c-0.13,0.51-0.37,0.34-0.27,0.83c0.38-0.1,0.28-0.22,0.65-0.68c0.23-0.29,0.46-0.45,0.71-0.73
		c0.18-0.2,0.59-0.66,0.42-0.82c-0.43-0.41,0.04,0.14-0.52,0.18c-0.04,0-0.28-0.05-0.37-0.05c0.17-0.75,0.98-1.26,1.29-1.71
		c0.18-0.26,0.35-0.7,0.38-1.07c0.04-0.63-0.02-0.98-0.34-1.21c-0.07,0.31-0.05,0.25-0.22,0.44c-0.08-0.97-0.33-1.62-1.33-1.89
		c0.04,0.44-0.07,0.34,0.33,0.45v0.33c-0.29,0.08-0.4,0.19-0.67,0.33c-0.84-1.58-0.05-2.22-0.44-3.56
		c-0.26-0.91-1.63-1.56-2.67-1.32v0.66c-0.66-0.48-0.54-1.26-1.22-1.44c-0.06,0.73-0.49,1.53-0.01,2.67
		c0.15,0.35,0.18,0.45,0.56,0.55c0.1-0.45,0.29-0.57,0.33-1.11h0.22c0.13,0.36,0,0.1,0.36,0.42c0.18,0.17,0.29,0.26,0.44,0.45
		c0.94,1.16,1.45,3,0.43,3.68c0-0.43,0.06-1.55-0.08-1.91C137.54,151.58,137.65,151.87,137.4,151.71 M139.51,155.26
		c-0.02,0.02-0.05,0.02-0.06,0.05c-0.01,0.05-0.39,0.2-0.48,0.65c-0.09,0.43,0.08,0.83,0.21,1.08c-0.56-0.15-0.22-0.29-0.78-0.44
		c0-0.84,0.24-1.61,0.38-2.39c0.15-0.83,0.06-1.83,0.06-2.72c0.7,0.81,0.02,2.1,0.56,3.49c0.43-0.41,0.14-0.48,0.44-0.9
		C140.46,154.59,139.93,155.03,139.51,155.26z"
          />
          <path
            class="st0"
            d="M96.89,172.69c0,1.84,2.43,3.02-3.88,3.55c0.86,1.18,3.54,1.39,5.34,0.79c1.18-0.39,3.87-1.9,3.87-3.23
		C102.22,170.92,96.89,171.98,96.89,172.69"
          />
          <path
            class="st0"
            d="M153.04,106.22c0,1.63,0.43,2.26,1.11,3.22c0.53,0.75,1.31,1.89,2.55,1.89c0-0.8-0.44-2.57-0.44-4.88
		c0-2.29,0.06-3.53,0.55-5.66C154.71,101.89,153.04,103.18,153.04,106.22"
          />
          <path
            class="st0"
            d="M153.38,92.34c0,1.8,0.6,2.64,1.63,3.92c0.53,0.66,1.07,1.27,2.03,1.3c0-1.03-0.44-2.57-0.44-5
		c0-2.24,0.07-3.49,0.55-5.55c-1.14,0.6-1.67,0.79-2.63,1.92C153.91,89.65,153.38,91.06,153.38,92.34"
          />
          <path
            class="st0"
            d="M61.6,48.07c0,0.41-0.1,0.26-0.11,0.78c-0.68,0-0.74-0.22-1.22-0.22c-0.51,0-0.74,0.18-1.22,0.22
		c0.29,0.39,1.05,0.64,1.64,1.47c0.39,0.55,0.75,1.52,1.25,1.86c-0.04-0.46-0.23-1-0.33-1.44c2.32,0.54-0.84,3.41,1.37,4.73
		c0.57,0.34,0.99,0.37,1.85,0.37c-0.46-0.69-1.1-0.36-1.66-1.33c-1.01-1.73,1.03-1.69,1.06-3.11c0.02-0.76-1.06-1.82-1.84-1.89
		c0-0.71-0.3-0.78,0.44-0.78c1.02,0,1.78,1.55,1.78,2.66c0,1.56-2.04,2.43-0.33,3.33c-0.27-1.17-0.47-1.03,0.67-2.1
		c1.66-1.55,0.17-3.67-1.09-4.92c-0.77-0.77-1.89-1.86-3.36-1.86c0.2,0.3,0.5,0.58,0.73,0.93C61.41,47.05,61.6,47.67,61.6,48.07"
          />
          <path
            class="st0"
            d="M146.72,60.16l3.11-0.78c0.71,0,1.36,0.15,1.55,1c-0.53,0.79-1.72,0.5-2,1.56c0.64,0.01,1.69,0.41,3.17-0.71
		c1.57-1.19,1.41-2.69,0.35-4.15c-0.44-0.61-0.17-0.47-1.3-0.47c-1.43,0-2.35,0.41-3.41,0.81c-0.88,0.34-0.62,0.32-1.04,1.07
		C146.87,58.99,146.72,59.36,146.72,60.16"
          />
          <path
            class="st0"
            d="M51.17,133.4c0.09,0.26,0.05,0.18,0.11,0.44c0.53,0,0.61-0.08,0.96,0.09c-0.35,0.51-0.06,0.24-0.87,0.33
		c0.35,0.1,0.07,0.56-0.09,0.91l0.22,0.31c0.38,0.63-0.03-0.02,0.4,0.44c0.01,0.01,0.04,0.09,0.04,0.07
		c0.01-0.02,0.03,0.05,0.04,0.07c-0.4,0.1-0.61,0.11-1.16,0.11c0.14,0.33-0.17,0.15,0.74,0.26c1.91,0.25,4.32,0.03,3.88-2.67
		c-0.24-1.47-1.71-4.28-3.07-4.59c0,2.3,0.95,1.45,1.52,2.37c0.45,0.73,0.04,2.9,0.04,3.74c-0.95,0-1.45-0.11-2.33-0.11
		c0.2-0.17,0.14-0.13,0.43-0.24c0.17-0.06,0.39-0.13,0.57-0.2c0.37-0.14,0.78-0.27,1.11-0.45c0-1.74-0.47-2.34-1.86-3.58
		c-1.44-1.28-2.57-0.94-4.13-1.3c0.1,0.44,0.53,1.01,0.86,1.25c0.59,0.43,0.99,0,1.71,0.17c0.96,0.23,1.76,1.38,1.76,2.57H51.17z"
          />
          <path
            class="st0"
            d="M66.49,151.05c0,1.87-0.11,3.34-0.11,5.18l0.61-0.1c-0.13,0.42-0.33,0.83-0.61,1.11
		c-0.18,0.21-0.1,0.07-0.11,0.47c0.37-0.03,0.12,0.05,0.34-0.1c0.18-0.12,0.1-0.09,0.15-0.23l0.58-0.96
		c0.23,0.18,0.13,0.31,0.48,0.45c0.28-0.21,2.24-1.3,2.33-1.59l0.14-0.4c0.01-0.01,0.04-0.04,0.05-0.05
		c0.23-0.28,0.37-0.38,0.75-0.43l-0.13-0.15c0-0.01-0.02-0.04-0.03-0.05c-0.18-0.44,0.08-1.89,0.2-1.92
		c0.89-0.25-0.15,0.29,0.31-0.07c-0.33-0.55-0.42,0.06-0.6-0.39c0-0.01-0.01-0.06-0.02-0.06c0-0.01-0.01-0.05-0.01-0.07
		c-0.36,0.13-1.87-0.01-2.61-0.01c0-0.77,0.08-0.39-0.1-0.82c0,0-0.01-0.03-0.01-0.03c0,0-0.01-0.03-0.01-0.04
		c-0.13-0.24-0.74-0.28-1.05-0.28c0,0.77,0.04,0.7-0.17,1.39l-0.05,0.17C66.53,151.81,66.49,152.01,66.49,151.05"
          />
          <path
            class="st0"
            d="M86.46,167.47c1.48-0.39,2.78-5.63,5.44-1.66c2.75-1.46,1.65-7.3-3.19-3.3
		C86.83,164.05,86.46,165.12,86.46,167.47"
          />
          <path
            class="st0"
            d="M56.28,66.82c0.35-0.25,0.56-1.05,0.56-1.66c0-2.82-2.43-4.88-2.66-5.88c-0.47,0.34-0.09,0.54-0.56,0.89
		c-0.18-0.66-1.77-1.87-2.33-2c0.21,0.43,0.21,0.44,0.11,0.89c-1.48-0.34-0.82-2.12-3.33-2.33c-1.79-0.15-0.61,0-1.89,0
		c-0.56,0-0.98-0.13-1.33-0.33c-0.35-0.2-0.64-0.57-1-0.67c0.45,1.92,1.8,1.15,3.7,2.18c0.41,0.22,0.77,0.14,1.21,0.24
		c1.04,0.24,2.09,0.96,2.83,1.61c1.92,1.68,3.22,3.63,4.18,6.03C56.14,66.69,55.91,66.55,56.28,66.82"
          />
          <path
            class="st0"
            d="M37.08,76.81c0.01,0.14-0.16,0.02,0.29,0.37l1.09,0.7c0.38,0.23,0.28,0.23,0.62,0.15
		c0.35-0.23,0.67,0.09,1.08,0.18c0.37,0.08,0.99-0.21,1-0.21c0.19-0.2,0.13-0.83-0.03-1.31c-0.13-0.37,0.11-0.17-0.06-0.77
		c-0.75-0.32-0.48-0.38-1.09-1.38c-0.11-0.18-0.51-1.22-0.6-0.48c0.15-0.62,0.08-0.2-0.16-0.49l-0.29-0.51
		c-0.01-0.03-0.03-0.07-0.05-0.1c-0.23,0.24-1.68,0.1-2.03,0.11c-0.87,0.01-1.21,0.39-1.96,0.17c0.24,0.13-0.1-0.25-0.39-0.11
		l0.79,1.39c0.01,0.02,0.03,0.04,0.05,0.06c0.61,0.76,1.33,1.26,1.77,1.32c-0.06,0.16,0.07-0.2-0.09,0.12
		c-0.1,0.19-0.06,0.02-0.04,0.19c0,0.02,0.03,0.09,0.04,0.12c0,0.02,0.02,0.05,0.02,0.07c0.01,0.02,0.02,0.05,0.02,0.08
		C37.07,76.54,37.08,76.73,37.08,76.81"
          />
          <path
            class="st0"
            d="M31.53,72.37c0,1.92,1,2.8,2.06,4.38c0.26,0.38,0.53,1.07,0.83,1.28c0.07-0.3,0.15-0.58,0.22-0.89
		c0.23,0.17,0.57,0.72,1.09,1.13l1.35,0.98c0-1.61,0.26-0.72,1.45,0.55c0.02,0.02,0.04,0.04,0.05,0.06c1.5,1.53,3.08-0.04,5.11,1.22
		c0.17,0.11,1.09,0.91,0.75,0.16c-0.51-1.14-5.96-1.93-8.23-4.22c-0.19-0.19-0.28-0.26-0.46-0.43l-0.43-0.45
		c-3.14-3.58-2.43-4.42-2.79-5.2l-0.45-0.44C31.84,70.94,31.53,71.67,31.53,72.37"
          />
          <path
            class="st0"
            d="M69.7,44.29c0.01,0.28-0.18-0.09,0.11,0.33c0.45-0.12,0.37-0.22,0.61-0.62c0.23-0.39,0.43-0.49,0.28-0.94
		c0.15-0.83,0.33-1,0.33-2.11c0.16-0.42,0.11-0.36,0.03-0.69c-0.02-0.06-0.11-0.31-0.12-0.32c-0.15-0.33,0.02-0.12-0.13-0.21
		c-0.64-0.13-0.22,0.15-0.4-0.32c-0.15-0.4,0.14-0.38,0.06-1.33c-0.55-0.25-1.17-0.03-1.44-0.49c-0.4,0.27-0.06,0.12-0.67,0.3
		c-0.22,0.07-0.43,0.13-0.66,0.29c-0.05,0.96-0.46,1.38-1.28,1.86l-0.63,0.35c-0.47,0.41,0.25-0.39-0.1,0.1
		c-0.25,0.35-0.04,0.67-0.02,0.74c0.02,0.07,0.12,0.3,0.14,0.79c0.22-0.45,0.13-0.18,0.07-0.67c0.73,0.01,0.45,0.05,0.71,0.33
		l0.11-0.32c0.4,0.62,0.35,0.22,0.84,0.38c0.33,0.11,1.49,1.45,1.87,1.83c0.28,0.28-0.25,0.05,0.39,0.27
		C69.76,44.25,69.95,44.01,69.7,44.29"
          />
          <path
            class="st0"
            d="M184.89,92.45c-0.16,0.41-0.05,2.38,0.03,2.97c0.06,0.43,0.09,0.88,0.09,1.48c0,0.76-0.16,0.77-0.45,1.21
		c-0.34-0.25-0.36-0.5-0.57-0.76c-0.16-0.18-0.06-0.15-0.31-0.24c0,0.62-0.07,0.77-0.11,1.22c-0.48-0.11-0.62-0.33-1.11-0.44
		c0.04,0.47,0.23,0.78,0.33,1.22c-0.56,0-0.5-0.1-1-0.11c0.14,0.54,0.63,0.69,0.77,1.22c-0.32,0.08-0.18,0.04-0.44,0.22
		c-0.2,0.15-0.26,0.15-0.34,0.44c0.32,0.07,0.87,0.19,1.22,0.22c-0.16,0.32-0.36,0.84-0.45,1.22c0.32-0.17,0.68-0.41,1.11-0.44
		c0.1,0.22,0.22,0.78,0.22,1.11c0.16-0.11,0.22-0.13,0.36-0.31c0.07-0.09,0.05-0.03,0.12-0.19c0.12-0.25,0.06-0.06,0.07-0.27
		l0.33-0.28c0.76-0.53,0.04-0.28,0.71-0.17c0.47,0.08-0.05,0.03,0.28-0.02c0.33-0.05,0.14-0.04,0.72-0.02l-0.04-0.29
		c0.27-0.36,0.4-0.4,0.89-0.44c-0.04-0.47-0.19-0.51-0.66-0.9l-0.12-0.1c0.44-0.1,0.85-0.27,0.89-0.78c-0.59,0-0.62-0.1-1.11-0.11
		c0.01-0.58,0.18-0.59,0.22-1.11l-1.1,0.35c-0.01-2.46-0.2-4.83-0.45-7.05L184.89,92.45z"
          />
          <path
            class="st0"
            d="M123.08,163.7c-0.02,0.02-0.05,0.06-0.06,0.08l-0.13,0.2c-0.01,0.01-0.02,0.04-0.03,0.05l-0.12,0.58
		c-0.22-0.65,0.14-0.46-0.55-0.57c1.29,4.21,4.92,3.13,6.69,1.36c1.07-1.07,1.37-2.06,2.04-3.4c0.41-0.82,1.04-2.27,2.15-1.74
		c2.54,1.2-1.74,2.54-1.74,3.65c0,0.61,0.58,1.59,1.63,1.68c0.53-1,0.21-0.82,0.31-1.19l0.88-0.94c1.07-1.13,0.53-2.82-0.59-3.69
		c-0.35-0.28-0.67-0.56-1.31-0.32c-0.34,0.13-0.75,0.54-1,0.79c-1.59,1.6-1.55,4.01-3.68,5.42
		C126.39,166.44,123.08,166.66,123.08,163.7"
          />
          <path
            class="st0"
            d="M154.71,134.51c0.14,0.17,0,0.16,0.08-0.11c-0.32-0.39-0.15-0.19-0.53-0.46c-0.24-0.17-0.08-0.08-0.24-0.19
		c-0.22-0.16-0.08-0.13-0.32-0.18c0.28-0.27,0.5-0.53,0.52-1.05c0-0.02-0.03-0.52-0.04-0.58c-0.11-1.24-0.47-0.68-0.79-0.67
		c-0.38,0.01,0.12-0.28-0.61,0.06c0-0.03,0-0.26,0-0.28l-0.07-0.2c-0.45,0.18-0.34,0.33-0.78,0.44c0.82-1.54,1.11-3.32,1.55-5.22
		c1.24,0.33,0.67,2.32,0.04,3.48c-0.3,0.54-0.5,0.58-0.7,1.18c0,0.25-0.04,0.01,0.33,0.11c0.19-0.41,0.34-0.57,0.61-0.96
		c0.26-0.36,0.44-0.75,0.5-1.2c0.36,0.13,0.32,0.31,0.41,0.02c0.14-0.46-0.48-0.44,0.66-0.37c0.12,0.45-0.24,0.38,0.14,0.65
		c0.63,0.44,0.28,0.07,0.9,0.21c-0.35-1.06-1.3-1.71-1.55-2.78c0.3,0.07,0.27,0.09,0.67,0.11c-0.33-0.69-0.14,0.15-0.5-0.68
		l-0.5-0.84c0.5-0.37,0.24-0.38,0.28-0.82c0.03-0.45,0.37,0.25-0.18-0.85c-0.15-0.29-0.08-0.14-0.21-0.46
		c0.86,0.13,0.05,0.09,0.77,0.2c1.19,0.17,1.88,0.38,2.11,0.24c-0.15-0.36,0.09-0.11-0.35-0.32l-5.31-0.91
		c-1.16-0.05-0.83,0.34-1.89-0.22c0.44,1.91,2.49-0.17,2.66,1.89l-1.42-0.7c0.84,1.32,0.74,0.87,2.19,1.38
		c1.02,0.36,1.21,0.99,1.68,1.87c-0.59-0.16-0.86-0.8-1.33-1.11c-0.34,1.46-1.52,5.06-2.22,6.11c0.19,0.35,0.44,0.68,0.66,1
		C152.35,132.88,153.93,134.5,154.71,134.51 M154.51,123.86c-0.42,0.11-1.12-0.13-1.28-0.67c0.16-0.09-0.18-0.17,0.46-0.1
		C154.14,123.14,154.38,123.43,154.51,123.86z M152.71,132.52c0-0.48,0.13-0.6,0.22-1c0.33,0.09,0.24,0,0.33,0.33
		c0.34-0.23,0.25-0.33,0.67-0.44c0,0.32,0.04,0.78-0.03,1.08c-0.07,0.31-0.22,0.41-0.31,0.8c-0.35-0.09-0.22-0.05-0.48-0.3
		C153.03,132.91,152.71,132.59,152.71,132.52z"
          />
          <path
            class="st0"
            d="M140.62,25.09l-0.37-0.03l0.28,1.23l-0.91,0.02c-0.12,0.55-0.08-0.11,0.44,0.89
		c-0.39,0.27-0.62,0.38-0.89,0.78c0.65,0,0.78,0.11,1.33,0.11c-0.11,0.22-0.39,0.97-0.44,1.22c0.25-0.06,0.87-0.33,1.11-0.44
		c0.1,0.42,0.22,0.69,0.22,1.22c0.4-0.27,0.51-0.49,0.78-0.89c0.42,0.28,0.34,0.43,0.66,0.66c0.27-0.19,0.41-0.45,0.44-0.89
		c1.63,0,1,0.54,0.89-0.78c0.23-0.06,0.1,0.04,0.22-0.11c0.2-0.38-0.04-0.05,0.15-0.27c0.12-0.15,0.06-0.06,0.19-0.16l-0.12-0.35
		c-0.05-0.16-0.27-0.22-0.03-0.45c0.29-0.29,0.33-0.01,0.47-0.54c-0.39-0.09-0.84-0.13-1.22-0.22c0.12-0.53,0.46-1.07,0.05-1.02
		l-0.83,0.24c-0.07-0.32-0.19-0.87-0.22-1.22c-0.46,0.31-0.43,0.69-0.89,1c-0.09-0.35-0.4-0.9-0.78-1
		C141.17,25.2,141.33,24.92,140.62,25.09"
          />
          <path
            class="st0"
            d="M171.91,144.83c0.3-0.37-0.42-0.02,0.35-0.24c1.12-0.32-0.2,0.27,0.54,0.02c0.29,0.41,0.22,0.53,0.33,1
		c0.54-0.19,0.18-0.38,0.78-0.77c0.47,0.31,0.33,0.55,0.78,0.66c0.03-0.41,0.17-0.58,0.33-0.89h1l-0.24-0.77
		c0.3-0.14,0.62-0.2,0.9-0.34c-0.29-0.43-0.54-0.46-0.78-0.78c0.29-0.39,0.59-0.32,0.89-0.78c-1.71-0.04-1.21-0.29-1.33-0.67v-0.31
		c-0.05,0.29-0.06,0.13-0.31,0.01l-0.36-0.16c-0.26-0.13-0.33,0.02-0.33-0.42c0.09-0.22,0.19-0.12,0.06-0.51
		c-0.2-0.59-0.2,0.37-0.84,0.84c-0.55-0.82-0.51-0.47-0.55-1h-0.22c0,0.56-0.11,0.61-0.11,1.22c-0.58-0.05-0.68-0.32-1.22-0.45
		c0.09,0.38,0.29,0.9,0.44,1.22h-1c0.29,0.54,0.49,0.57,0.78,1.11c-0.35,0.19-0.67,0.35-0.89,0.67c0.18,0.09,0.97,0.2,1.22,0.22
		c-0.03,0.32-0.06,0.34-0.16,0.64C171.87,144.67,171.78,144.73,171.91,144.83"
          />
          <path
            class="st0"
            d="M107.32,110.43c-0.38-0.09-0.01-0.12-0.41,0.03c-0.18,0.07-0.25,0.16-0.42,0.26
		c-0.31,0.18-0.56,0.17-0.73,0.38c0.24,0.28,0.06,0.05,0.11,0.44c-0.39,0.01-0.58,0.11-0.89,0.25l-0.17,0.08
		c0.21,0.34,0.03,0.12,0.24,0.17l0.25,0.65c0.29-0.05,0.3-0.1,0.64-0.11l-0.08,0.46c0,0.02-0.01,0.05-0.01,0.06
		c0,0.01-0.01,0.04-0.01,0.05c-0.08,0.77,0.83,0.66,0.3,3.83l-0.04,0.16c-0.08,0.39,0.02,0.67,0.03,1.25l1.73-0.1
		c0.23-0.84,0.4-0.28,0.5-1.19l0.2-1.39c0.36-0.67-0.1,0.57-0.06-1.3c0.02-0.66,0.02-0.76-0.27-1.25c-0.04-0.07-0.33-0.28-0.06-0.1
		c0-1.16-0.18-1.12,0.09-1.69l0.09-0.24c0.13-0.43-0.04-0.2-0.2-0.44l-0.03-0.2c0.05-0.22,0.19-0.1,0.08-0.36
		C107.74,110.48,108.08,110.43,107.32,110.43"
          />
          <path
            class="st0"
            d="M145.94,47.84c0.44,0.12,0.45,0.32,0.89,0.44c0.4-0.27,0.51-0.49,0.78-0.89l-0.29-0.58
		c0.45-0.74,0.67-1.11,0.11-2.45c-0.27-0.65-0.87-1.37-1.3-1.91c-0.46-0.59-0.99-1.21-1.25-1.97c-0.4-1.18,0.12-1.65,0.52-2.41
		c0.51,0.01,0.74,0.18,1.22,0.22c-0.18-2.21-3.09,0.09-2.11,2.78c0.49,1.36,1.65,2.14,1.83,3.49c0.07,0.54-0.17,1.02-0.28,1.49
		c0.59,0.14,0.96,0.15,1.11,0.78c-0.33-0.16-1.13-0.23-1.55-0.33v1c-0.61,0.05-0.66,0.33-0.97,0.7c-0.76,0.89-1.68,0.68-2.82,0.55
		c-1.52-0.17-2.99-0.26-4.06,1.01c-0.27,0.32-0.53,0.69-0.44,1.26c0.11,0.73,0.3,0.71,1.08,0.71c-0.06-0.69-0.16-0.53-0.78-0.67
		c0.02-0.95,0.75-1.65,1.46-1.87c1.85-0.58,5.23,1.56,6.93-0.18C146.53,48.49,146.08,48.36,145.94,47.84"
          />
          <path
            class="st0"
            d="M72.92,48.51c0.1,1.22,0.86,1.77,0.89,3c-0.28,0.07-0.43,0.13-0.67,0.22c-0.87,0.35-0.94,0.25-1.86,1.02
		c-0.86,0.72-1.63,0.8-2.58,1.31c0.2,0.87,0.82,1.79,0.89,2.55c0.34-0.01,0.41-0.05,0.67-0.11c-0.05-0.6-0.61-1.29-0.07-1.84
		c0.65-0.67,5.39-1.36,5.39-2.15c0-1.5,0.06-1.43-0.31-2.91c-0.33-1.31-0.18-1.53-1.02-1.53C73.49,48.07,73.42,48.38,72.92,48.51"
          />
          <path
            class="st0"
            d="M15.66,102.33c0.23,0.06,0.08-0.06,0.4,0.36c0.08,0.1,0.03,0.03,0.13,0.17l0.46-0.76
		c0.34,0.13,0.69,0.15,1,0.22c-0.04-0.53-0.18-0.35-0.22-0.89c0.46-0.11,0.3-0.21,0.89-0.22c-0.12-0.46-0.42-0.53-0.78-0.78
		l0.11-0.33l0.14-0.35c-0.7-0.06-0.9-0.05-0.82-1.05c-0.37,0.03-0.46,0.12-0.6-0.2c-0.16-0.39-0.07-0.55-0.05-0.95
		c-0.3,0.17-0.54,0.37-0.52,0.84l-0.27-0.06c-0.58-0.13-0.44-0.63-1-1c0,0.56-0.11,0.61-0.11,1.22c-0.44-0.1-0.78-0.34-1.22-0.44
		c0.1,0.44,0.26,0.87,0.44,1.22c-0.56,0-0.57-0.08-1-0.11c0.05,0.56,0.24,0.42,0.78,1.22c-0.39,0.1-0.78,0.37-0.89,0.78
		c0.62,0,0.74,0.1,1.22,0.11c-0.05,0.61-0.29,0.68-0.33,1.22c0.48-0.11,0.63-0.33,1.11-0.45c0.04,0.43,0.19,0.8,0.22,1.22
		C15.14,103.06,15.21,102.68,15.66,102.33"
          />
          <path
            class="st0"
            d="M55.5,26.32c0.02,0.03,0.04,0.05,0.04,0.11l0.06,0.23c0.01,0.04,0.01,0.07,0.01,0.12
		c0.06,0.55-0.15,0.33,0.59,0.71c-0.02,0.24-0.07,0.3-0.11,0.64l0.52-0.04c0.18,0.47,0.23,0.88,0.33,1.33
		c0.3-0.21,0.56-0.48,0.66-0.89h0.22c0.08,0.29,0.35,0.63,0.56,0.78l0.45-0.89l0.99,0l-0.24-0.77c0.28-0.14,0.52-0.2,0.79-0.34
		c-0.21-0.78-0.55-0.56-0.56-0.56l-0.39-0.19c0.17-0.39,0.31-0.44,0.47-0.65c-0.46-0.02-0.06,0.04-0.3-0.06l-0.27-0.17
		c-0.03-0.02-0.06-0.05-0.09-0.07c-0.03-0.02-0.06-0.04-0.09-0.07c0.28-0.27,0.39-0.52,0.44-1.11c-0.54,0.04-0.66,0.23-1.11,0.33
		c0-0.56-0.11-0.61-0.11-1.22c-0.37,0.1-0.68,0.65-0.78,1c-0.24-0.18-0.58-0.66-0.66-1h-0.22c-0.04,0.46-0.11,0.61-0.11,1.22
		c-0.54-0.12-0.65-0.4-1.22-0.44c0.1,0.44,0.29,0.75,0.33,1.22c-0.43-0.04-0.36-0.11-0.89-0.11C54.91,25.76,55.18,26.21,55.5,26.32"
          />
          <path
            class="st0"
            d="M140.73,173.46c0.34,0.13,0.13-0.01,0.33,0.22c-0.25,0.38-0.26,0.28-0.43,0.67l0.29,0.28
		c0.03,0.02,0.15-0.04,0.4,0.33l0.19,0.32c0.99-0.07,0.26-0.05,0.56,0.18c-0.12,0.51,0.06,0.53,0.11,1.11h0.22
		c0.11-0.4,0.36-0.68,0.66-0.89c0.1,0.36,0.28,0.59,0.56,0.78c0.22-0.32,0.26-0.55,0.44-0.89c0.49,0,0.65-0.06,1,0.11
		c-0.05-0.57-0.3-0.59-0.11-1c0.02-0.03,0.1-0.12,0.11-0.14c0.15-0.23,0.13-0.26,0.03-0.44c-0.09-0.17-0.19-0.27-0.37-0.37l0.4-0.67
		c-0.36-0.08-0.52-0.36-0.52-0.73c0-0.04,0-0.07,0.01-0.1c0.03-0.35-0.2-0.05,0.12-0.31c0.17-0.14,0.08,0.06,0.11-0.33
		c-0.45,0.1-0.57,0.29-1.11,0.33c0-0.9-0.07-1.03-0.33-1.22c-0.09,0.33-0.42,0.82-0.67,1c-0.23-0.35-0.44-0.77-0.78-1
		c0,0.62-0.1,0.74-0.11,1.22c-0.48-0.11-0.73-0.24-1.11-0.44c0.01,0.43,0.19,0.92,0.33,1.22c-0.49,0-0.65,0.06-1-0.11
		c0.07,0.32,0.18,0.51,0.46,0.75C140.73,173.51,140.55,173.41,140.73,173.46"
          />
          <path
            class="st0"
            d="M26.54,55.61c0,0.08-0.05,0.37-0.06,0.4c-0.07,0.95,0.22,0.84-0.94,0.72c0.2,0.7-0.1,0.66-0.49,0.99
		c0.16,0.45,0.13,0.31,0.44,0.62c-0.2,0.24-0.14,0.14-0.27,0.49c0.02,0.02,0.07,0.02,0.08,0.06c0.02,0.05,0.06,0.05,0.08,0.06
		c0.39,0.29,0.46,0.1,0.38,0.82l-0.03,0.21c0.3,0.07,0.49,0.27,0.73,0.45c0.02,0.02,0.06,0.04,0.09,0.06
		c-0.17,0.45-0.07,0.56,0.22,0.78c0.11-0.4,0.36-0.68,0.67-0.89c0.09,0.32,0.31,0.68,0.66,0.78c0.1-0.43,0.24-0.62,0.33-1
		c0.5,0.01,0.44,0.11,1,0.11c-0.03-0.4-0.14-0.53-0.22-0.89c0.4-0.03,0.53-0.14,0.89-0.22l-0.8-0.87c0.32-0.18,0.73-0.44,0.91-0.69
		c-0.2-0.1-0.91-0.22-1.22-0.22c0.01-0.58,0.18-0.59,0.22-1.11c-0.4,0.04-0.19-0.04-0.33,0.11l-0.62,0.09
		c-0.04,0-0.37,0.02-0.38,0.02v-0.67L28,55.4c-0.43,0.1-0.4,0.29-0.8,0.88C26.72,55.95,26.88,55.72,26.54,55.61"
          />
          <path
            class="st0"
            d="M76.25,42.63l0.11,0.78c0.36,0,1.1-0.11,1.33-0.22c0.8,3.44,0.33,1.65,1.89,4.77
		c0.23,0.46,0.51,1.15,0.78,1.55c0.53-0.05,1.3-0.48,1.71-0.73c0.61-0.37,1.02-0.6,1.39-1.16c-0.45,0.11-0.87,0.19-1.38,0.29
		c-0.48,0.09-0.97,0.23-1.4,0.27l-1.78-6.99c-0.65-0.15-1.23-0.23-1.79-0.02C76.57,41.37,76.25,41.91,76.25,42.63"
          />
          <path
            class="st0"
            d="M152.82,130.74c-0.21-0.04-0.15-0.1-0.11,0.11l0.07,0.2c0.01,0.03,0,0.26,0,0.28
		c0.73-0.35,0.24-0.05,0.61-0.06c0.31-0.01,0.67-0.58,0.79,0.67c0.01,0.06,0.04,0.56,0.04,0.58c-0.01,0.52-0.23,0.78-0.52,1.05
		c0.25,0.05,0.1,0.02,0.32,0.18c0.16,0.12,0,0.02,0.24,0.19c0.37,0.27,0.21,0.06,0.53,0.46c-0.08,0.27,0.06,0.28-0.08,0.11
		c0.46,0.05,0.57,0.24,0.78,0.55l0.47-0.75c0.11-0.1,0.2-0.17,0.28-0.25c0.32-0.32,0.46-0.75,0.91-0.99
		c-0.19-0.55-0.35-0.49-0.11-1.22c0.18-0.47-0.04-1-0.18-1.48c-0.09-0.32-0.31-1.02-0.48-1.18c-0.62-0.14-0.28,0.24-0.9-0.21
		c-0.38-0.27-0.02-0.2-0.14-0.65c-1.14-0.07-0.52-0.09-0.66,0.37c-0.09,0.29-0.05,0.12-0.41-0.02c-0.05,0.46-0.24,0.85-0.5,1.2
		c-0.28,0.39-0.43,0.55-0.61,0.96C152.78,130.75,152.82,130.99,152.82,130.74"
          />
          <path
            class="st0"
            d="M44.4,126.86c-0.02,0.02-0.05,0.03-0.06,0.08l-0.26,0.25c-0.02,0.02-0.05,0.04-0.07,0.05l-0.17,0.11
		c-0.03,0.02-0.07,0.04-0.1,0.06c0.02,0.04,0.03,0.08,0.04,0.13c0.01,0.04,0.05,0.07,0.07,0.09c0.15-0.05,0.28-0.07,0.44-0.11
		c-0.07,0.88-0.66,1.44-0.89,2h0.44c0.2-0.44,0.18-0.32,0.41-0.57c0.16-0.17,0.04,0.1,0.15-0.19c0.05,0.08,0.12,0.25,0.12,0.25
		l0.2,0.11c0.36,0.2,0.41,0.78,1.5,1.49c1.77,1.14,1.36,0.47,2.17,1.06c0.21,0.16,0.47,0.38,0.56,0.19c-0.42,0-0.29-0.02-0.64,0.09
		c-0.47,0.15,0.23,0.13-0.47,0.13c0.15,0.35-0.24,0.16,0.99,0.23c0.42,0.03,0.46,0.1,1.01,0.1c-0.42-1.58-2.88-0.72-4.99-3.88
		c0.37-0.71,1.14-2.04,2.3-1.87c0.44,0.06,1.07,0.47,1.47,0.65c0.37,0.17,1.2,0.55,1.66,0.55c0.43-0.12,1.28-1.38,1.5-2.05
		c0.13-0.37,0.13-0.65,0.27-0.95c-2.11,0.49-1.1,0.9-1.86,1.48c-0.35,0.26-0.55,0.11-1.13-0.15c-0.4-0.18-0.73-0.3-1.18-0.49
		c-1.6-0.67-2.31-1.51-2.92,0.39c-0.05,0.16-0.22,0.72-0.39,0.81C44.3,127.06,44.64,127.18,44.4,126.86"
          />
          <path
            class="st0"
            d="M56.61,45.85c0.31,0.22,0.58,1.1,1,1.88h2.22c-0.09-0.08-0.02-0.06-0.26-0.18c-0.26-0.14-0.18-0.08-0.41-0.14
		c-0.44-0.13-0.44-0.03-0.55-0.45c0.35,0.03,0.9,0.22,1.21,0.34c0.38,0.15,0.59,0.43,1.01,0.54c-0.04-1.67-0.67-1.19-1.7-1.63
		c-0.47-0.2-1.72-1.13-1.96-1.48c0.33,0.08,0.34,0.11,0.78,0.11c-0.3-0.56-0.42-0.49-0.73-0.93c-0.5-0.71,0-0.73-0.37-1.18
		c-0.09,0.52-0.21,0.79-0.18,1.4c-0.14-0.11-0.14,0.09-0.32-0.36c-0.07-0.16-0.06-0.28-0.03-0.45c0.05-0.29,0.2-0.49,0.42-0.67
		c0.3-0.25,0.56-0.5,0.66-0.92c-0.19-0.09-0.36-0.13-0.55-0.22c0.36-0.54,0.39-0.23,0.55-0.67c-1.26,0-1.66,0.47-1.86,1.47
		c-0.41,2.03,0.23,3.05,0.57,4.15c0.51,1.66,0.26,1.99,1.19,2.14c0.47,0.08,1.1,0.22,1.55,0.23c-0.25-0.34-1.11-0.64-1.53-1.02
		C56.81,47.37,56.61,46.76,56.61,45.85"
          />
          <path
            class="st0"
            d="M156.26,61.71c0.15-0.64,0.75-1.01-0.03-2.08c-0.56-0.77-1.34-2.39-2.3-2.47c0,0.82,0.14,1.32,0.12,2
		c-0.02,0.75-0.23,1.15-0.23,2c0.64,0.34,1.25,0.78,2.22,0.78c-0.5,0.95-0.54,2.4-0.34,3.55c0.1,0.56,0.23,0.66,0.34,1.11
		c0.45-0.12,0.55-0.48,0.74-0.92c0.27-0.61,0.64-2.28,0.58-2.94c-0.05-0.52-0.22-1.01-0.32-1.46
		C156.48,61.42,156.82,61.57,156.26,61.71"
          />
          <path
            class="st0"
            d="M126.08,146.83h-0.33c-0.09-0.22,0.1-0.44-1.29,0.15c-0.44,0.19-0.25,0.26-1.15,0.74
		c0.01,0.33,0.08,0.87,0.13,1.31c0.12,1.09,0.16,1.8-0.08,2.95c-0.16,0.76-0.28,0.58-0.6,1.06l0.16,0.28
		c0.51,0.75,1.75,0.86,3.22,2.11l0.24,0.21c0.29,0.25,0.24,0.3,0.6,0.4c0.15-1.79,0.55-0.95-1.38-2.39c-2-1.48-1.06-4.17-1.06-5.49
		c2.68-0.62,2.4,0.43,2.22-1.78C126.24,146.4,126.18,146.4,126.08,146.83"
          />
          <path
            class="st0"
            d="M150.27,127.19c0.96,0.02,0.88,0.75,1.11,1.56c0.09,0.33,0.15,0.34,0.22,0.66c0.65-0.44,1-2.63,1-3.44
		c-1.57,0-0.86,0.36-2.62-0.71l-4.46-2.87c-0.79-0.65-0.55-1.02-1.92-1.74c0,1.11,0.09,0.97,1.04,2.17
		c0.27,0.35,0.58,0.52,0.95,0.71c1.14,0.58,1.99,1.91,3.74,2.92C149.75,126.68,150.03,126.84,150.27,127.19"
          />
          <path
            class="st0"
            d="M139.95,148.94l0.24,1.88c0.48-0.04,0.83-0.33,1.31-0.44c0.95,0.64,1.89,1.72,2.55,2.66
		c1.79,2.53,1.27,3.91,3.22,2.89c-0.06-0.26-0.1-0.33-0.11-0.67l-1-0.11c0.1-0.38,0.06-0.34,0.44-0.44
		c-0.11-1.33-0.93-0.92-1.41-1.47c-0.2-0.23-0.43-0.59-0.59-0.85c-0.93-1.56-1.48-2.25-2.34-3.54c-0.24-0.35-0.46-0.54-0.76-0.8
		C141.2,148.25,140.28,148.85,139.95,148.94"
          />
          <path
            class="st0"
            d="M118.75,100.22c-0.06-0.18,0.1,0-0.15-0.18l-1.6-0.73c-0.42-0.16-0.33-0.26-0.8-0.09
		c-0.17,0-0.53,0.06-0.58,0.06c-0.27,0-0.09,0-0.3-0.03c-0.42-0.07-0.55-0.37-0.51,0.28c0.06,0.97,0.14-0.22,0.19,0.68h0.87
		c0.1,0.48,0.05,0.23,0.22,0.44h0.69c0,0.57-0.1,0.89,0.01,1.24c-0.63,0.13-0.45,0.09-0.47,0.33c1.52,0.01,2.13,1.21,2.86,1.3
		c0.75,0.08,1.49,0.26,2.08,0.21l-1.1-2.04C119.33,100.73,118.82,101.1,118.75,100.22"
          />
          <path
            class="st0"
            d="M136.4,120.42c-0.96,0-1.65-0.33-2.28-0.71c-0.34-0.2-0.59-0.33-0.91-0.53c-0.36-0.22-0.6-0.41-1.13-0.42
		c0.45,0.85,1.03,0.98,1.92,1.4c0.8,0.38,1.52,0.67,1.74,1.59c-0.96,0-2.04-0.68-2.7-1.07c-0.39-0.24-1.97-1.39-2.29-1.48
		c0,0.68-0.09,0.78,0.19,1.25c1.42,2.38,1.42,3.13,3.65,2.04c0.35-0.17,1.26-0.28,1.71-0.29c0-1.03-0.16-1.22,0.78-1.44
		c-0.01-0.45-0.18-0.66-0.01-1c0.2-0.39,0.43-0.27,0.56-0.78c-2.57,0-2.16-0.54-3.55-1.33c-0.25-0.14-0.87-0.41-1.22-0.44
		c0.18,0.76,0.58,0.79,1.89,1.44C135.52,119.04,136.37,119.27,136.4,120.42 M131.74,120.53c0.91,0.48,1.84,0.62,2.11,1.78
		c-0.45,0.01-1.11,0.3-1.78-1L131.74,120.53z"
          />
          <path
            class="st0"
            d="M79.14,132.52l-1.36,2.08c-0.16,0.21-0.03,0.16-0.3,0.25l-0.44,0.11c-0.64,0.08-1.05,0.03-1.22,0.66
		c0.96,0,2.44,0.47,3.02,0.87c1.16,0.81,0.83,1.17,1.24,1.67c0.24,0.3,0.41,0.28,0.41-0.21c0-1.91-1.4,0.26,1.44-3.99
		c0.32,0,0.66,0.03,0.96-0.04c0.34-0.09,0.51-0.19,0.6-0.51c-0.76-0.36-3.36-0.18-4.09-2.35c-0.14-0.43-0.07-0.43-0.46-0.54
		c-0.14,0.59-0.34,0.35-0.11,0.98C78.92,131.79,79.14,132.1,79.14,132.52 M79.58,136.18c-0.43-0.1-0.89-0.52-1.44-0.89
		c0.13-0.57,1.21-1.9,1.55-2.55c0.59,0.31,1,0.71,1.55,1c-0.23,0.44-0.51,0.76-0.82,1.17C80.2,135.21,79.66,135.88,79.58,136.18z"
          />
          <path
            class="st0"
            d="M130.29,144.61c0,1.78,2.89,2.48,3.64,4.02l0.47,2.19c0.44,0,0.45-0.04,0.78-0.11c0-0.67-0.19-0.82-0.22-1.44
		c-0.02-0.53,0-1.13,0-1.67c-1.56-1.05-2.99-1.22-3.11-2.66c1.27-0.67,2.22-0.39,2.22-1.33c0-0.53-0.21-0.45-0.33-0.89
		C133.39,142.75,130.29,144.44,130.29,144.61"
          />
          <path
            class="st0"
            d="M32.86,104.66c0,0.59,0.11,0.97,0.22,1.44c1.4,0.12,1.77,0.43,3.36-0.85c0.45-0.37,1.25-1.13,1.41-1.7
		c-0.19-0.28-0.39-0.37-0.67-0.55c-0.39,0.26-0.42,0.34-1.1,0.34c-0.94,0-2.28-0.16-2.76,0.24
		C33.02,103.83,32.86,104.12,32.86,104.66 M35.3,105.1c0.36-0.17,0.4-0.15,0.44,0.33C35.1,105.38,35.5,105.48,35.3,105.1z"
          />
          <path
            class="st0"
            d="M97.34,58.72v0.22c0.45,0.12,0.48,0.42,1,0.56c0,0.48-0.01,0.97-0.01,1.45c0,0.6-0.02,0.87,0.45,0.99
		c0.03-0.42,0.09-0.3,0.11-0.78c0.02-0.53-0.22-1.7,0.89-2c0,0.99,0.33,1.79,0.33,3.44c0,0.68-0.51,0.78-1.44,1v0.22
		c0.19,0.04,1.58,0.79,2-0.78c1.4,0,2.55,0.17,2.55-1.11c0-0.58-0.68-1.33-1.55-1.33c-0.62,0-0.8,0.49-1.22,0.78
		c0-2.31,0.16-2.77-1-2.77c-0.48,0-0.45,0.22-0.78,0.22c-0.3,0-0.44-0.23-0.61-0.27C97.73,58.47,97.73,58.63,97.34,58.72
		 M101.66,61.27c0.96,0,0.78,0.03,0.78,1.11c-1.29,0.3-0.04,0.3-1.33,0C101.14,61.98,101.24,61.27,101.66,61.27z"
          />
          <path
            class="st0"
            d="M121.08,136.62c-3,2-1.51-2.44-3.33-3.66c-0.37,0.09-0.74,0.21-0.83,0.64c-0.13,0.59,0.52,0.26,0.94,0.25
		c0.57,1.2-0.3,3.89,2.55,3.89c0.52,0,2.61-1.14,0.33-2.66c0.14-0.52,0.77-1,0.74-1.43c-0.06-1.07-1.97-1.75-2.4-0.33
		c-0.27,0.9,0.53,1.69,1.19,2.21C120.67,135.83,120.96,136.09,121.08,136.62 M119.75,133.4l0.36-0.31c0.92,0.18,1.15,1.2-0.13,1.31
		C119.94,133.95,119.81,133.88,119.75,133.4z"
          />
          <path
            class="st0"
            d="M146.05,75.14c0.74,1.01,2.87,1.45,4.33,1.33c0-1.2-0.05-1.59-0.64-2.25c-0.31-0.35-1.25-1.18-1.69-1.3
		L146.05,75.14z"
          />
          <path
            class="st0"
            d="M107.77,155.15c2.68,0,4.33-2.05,0.92-2.7c-0.67-0.13-0.81,0.09-1.41,0.32c-0.51,0.2-2.08,0.66-0.98,1.96
		C106.56,155.04,107.23,155.15,107.77,155.15"
          />
          <path
            class="st0"
            d="M132.29,80.25c0.58-0.05,1.33-0.52,1.75-0.8c0.62-0.4,1.03-0.74,1.91-0.53c-0.14,1.67-1.9,1.22-2.66,2.66
		c1.35,0,1.72-1.3,3.44-1.44c-0.04,1.72-2.43,1.55-3.06,2.71c-0.13,0.24-0.38,1.27-0.38,1.61c0.48,0.01,0.7,0.11,1.33,0.11
		c0.11-0.48,0.29-0.46,0.33-1c-0.39-0.09-0.36-0.11-0.89-0.11c0.24-1.03,1.96-1.33,2.68-1.98c0.26-0.24,0.53-0.77,0.53-1.24
		c0-0.45-1.2-1.02-1.66-2.99c-0.54,0.39,0.14,0.55-1.55,1.56C133.29,79.28,132.74,79.41,132.29,80.25"
          />
          <path
            class="st0"
            d="M65.27,54.06c0.94,0,0.89,0.17,0.97,0.36c0.02,0.05,0.24,1.12,0.25,1.2c-0.19,0.75-1.87,0.89-2.11,1.78
		c0.89-0.24,0.18-0.56,1.55-0.56c-0.02,0.73-0.2,0.49-0.33,1c2.16,0,2.22-1.12,2.22-2.88c0-0.9-0.5-2.55-1.33-3.11
		c0,2.39-1.4,1.17-1.81,2.1C64.4,54.57,65.21,54.06,65.27,54.06"
          />
          <path
            class="st0"
            d="M60.94,35.97c1.24-0.83,1.81-1.06,3.25-1.63c1.24-0.5,2.3-0.89,3.41-1.47c0.44,0.82,1.17,1.45,2.11,1.66
		c-0.04-0.47-0.8-2.51-1-2.89c-1.38,0.32-3.08,1.08-4.36,1.63c-0.61,0.26-1.47,0.7-1.97,1.03L62,34.59
		C61.67,34.91,61.05,35.55,60.94,35.97"
          />
          <path
            class="st0"
            d="M48.07,69.04c0.29-0.14,0.12-0.1,0.55-0.11c-0.05-0.56-0.25-0.56-0.38-1.07c-0.13-0.52,0.28-1.4-0.16-3.71
		c-0.15-0.76-0.4-0.58-0.9-0.32c-1.08-1.61-1.01-2-2.33-2c0.22,0.81,1.43,0.26,1.67,3.54c0.05,0.71,0.19,1.59,0.43,2.12
		C47.23,68.09,47.71,68.51,48.07,69.04"
          />
          <path
            class="st0"
            d="M38.19,91.79c0.12,0.49,0.4,1.16,0.44,1.66c2.69,0,1.15-2.25,5.14-0.37c0.82,0.39,0.96,1.12,1.97,1.14
		c0-1.73-1.28-1.85-3.12-2.54c-1.16-0.43-1.37-0.64-1.98-0.36C39.82,91.71,39.08,91.58,38.19,91.79"
          />
          <path
            class="st0"
            d="M64.04,118.98c-2.25,0-1.28,0.04-1.83-0.39c-0.24-0.19-0.36-0.16-0.61-0.28c0.04,1.96,2.02,0.62,2.72,1.61
		c0.22,0.31,0.33,0.63,0.5,0.94c-0.33,0.49-1.71,2.65-0.22,2.77c0.02-0.27,0.07-0.31,0.07-0.46c0-0.33-0.33-0.45,0.45-1.56
		c0.58-0.84,1.17-0.09,2.28-1.05c1.93-1.69-0.98-4.17-2.83-2.06C64.34,118.77,64.38,118.98,64.04,118.98 M64.93,119.86
		c0-2.43,2.33-1.69,2.33-1v0.89c0,0.39-0.8,0.94-1.11,0.95C65.79,120.72,64.93,120.11,64.93,119.86z"
          />
          <path
            class="st0"
            d="M173.91,55.06c-0.06,0.69-0.22,0.54-0.44,1.04c-0.86,0-0.32-0.11-0.92-0.61c0.01,0.48,0.06,0.46-0.19,0.8
		c-0.39,0.23-0.29,0.21-0.68,0.08c-0.01,0.5,0.32,0.56-0.43,1c0.52,0.61,0.32,1.06,0.57,1.37c0.2,0.25,0.28,0.2,0.51,0.28
		c0.09,0.03,0.46,0.15,0.49,0.16c0.14,0.03,0.41,0.04,0.53,0.04l1.1-0.06c0.01-0.03,0.02-0.12,0.03-0.11l0.05-0.21
		c0.16-0.87,0.02-0.64,0.02-0.85c0.09-0.28-0.17,0.23,0.12-0.07c0.01-0.01-0.15,0.39,0.06-0.07l0.03-0.1
		c0.05-0.42-0.36,0.08,0.33-0.43c-0.51-0.11-0.23-0.14-0.64-0.01l0.24-0.9c-0.48-0.12,0.33,0.44-0.51-0.02
		c-0.38-0.21-0.27-0.24-0.27-0.76c0.15-0.38,0.12-0.07,0.07-0.55C173.96,54.85,173.75,54.5,173.91,55.06"
          />
          <path
            class="st0"
            d="M36.18,88.13c-0.47-0.24-1.04-0.22-1.76-0.22c0.05,0,0.43-0.03,0.44-0.02c0.82,0.11,0.34-0.06,0.67,0.35
		c-0.32,0-0.93-0.05-1.05-0.02c-0.22,0.04-0.43,0.25-0.68,0.37l0.64,0.32c-1.63,0.37-0.84,0.29-0.89,0.46l-0.16,0.68
		c-0.08,0.67-0.02,0.25,0.06,0.5l0.2,0.46l0.92-0.26c0.32-0.44-0.29,0.04,0.16-0.13c0.37-0.14-0.08,0.11,0.25-0.06
		c0.35-0.18,0.06-0.05,0.11-0.12l0.37,0.27c0.02,0.02,0.05,0.04,0.08,0.06c0.02-0.03,0.04-0.12,0.05-0.1l0.08-0.16
		c0.13-0.27-0.22-0.05,0.08-0.15l0.02,0.09c0.02,0.02,0.04,0.05,0.06,0.08c0.02,0.02,0.05,0.06,0.07,0.08
		c0.02,0.02,0.05,0.06,0.08,0.09c0.6-0.23,0.98-0.01,1.44-0.16c0.59-0.2,0.42-1.01,0.21-1.48l-0.76-0.02c-0.02,0-0.07,0.01-0.1,0.02
		c0.19-0.16,0.1-0.1,0.08-0.35l-0.33-0.09c-0.35-0.13-0.21,0.03-0.35-0.21C36.01,88.13,35.99,88.4,36.18,88.13"
          />
          <path
            class="st0"
            d="M96.56,161.48c-0.05-0.58-0.37-0.5-1-0.56l-1.78-0.11c-0.98,0-0.56,0.52,0.11,1.78
		c0.52-0.04,2.02-0.74,2.66-0.89c-0.03,0.43-0.11,0.36-0.11,0.89c0.9,0.02,1.91,0.48,2.77,0.55c0.22-0.41,0.45-0.69,0.67-1.11
		l-1.37-0.63c-0.5-0.25-0.94-0.46-1.4-0.71C96.92,160.99,96.82,161.29,96.56,161.48"
          />
          <path
            class="st0"
            d="M173.35,59.83c0.21,0.1,0.1,0.05,0.26,0.15c0.03,0.02,0.06,0.04,0.09,0.06l0.29,0.2
		c0.2-0.42,0.23-0.62,0.69-0.72l0.45-0.24c0.18-0.33-0.07-0.31,1-0.56c-0.15-0.55-0.59-0.3-0.78-1c0.63-0.15,0.38-0.31,0.89-0.44
		c-0.34-0.47-0.73-0.13-1.16-0.39c-0.22-0.12-0.17-0.02-0.03-0.45c0.08-0.26,0.14-0.35,0.19-0.6c-0.58,0.01-0.59,0.18-1.11,0.22
		c0-0.78,0.04-0.23-0.22-0.44c0,0.51-0.11,0.55,0.27,0.76c0.84,0.46,0.03-0.11,0.51,0.02l-0.24,0.9c0.41-0.13,0.13-0.1,0.64,0.01
		c-0.69,0.51-0.28,0.01-0.33,0.43l-0.03,0.1c-0.22,0.46-0.05,0.07-0.06,0.07c-0.29,0.3-0.04-0.2-0.12,0.07
		c0,0.2,0.14-0.02-0.02,0.85l-0.05,0.21c0-0.02-0.02,0.07-0.03,0.11l-1.1,0.06c-0.12,0-0.4-0.01-0.53-0.04
		c-0.03-0.01-0.4-0.13-0.49-0.16c-0.23-0.08-0.31-0.04-0.51-0.28c-0.25-0.31-0.05-0.75-0.57-1.37c0.75-0.44,0.42-0.5,0.43-1
		c0.39,0.13,0.29,0.15,0.68-0.08c-0.94,0-0.32,0.04-0.55-0.22c-0.06-0.17,0.13-0.04-0.31-0.24c-0.16-0.07-0.29-0.11-0.47-0.2
		c0.04,0.47,0.23,0.78,0.33,1.22h-1c0.26,0.39,0.66,0.72,0.78,1.22c-0.32,0.09-0.68,0.31-0.78,0.67c0.48,0.01,0.61,0.11,1.22,0.11
		c-0.1,0.45-0.29,0.75-0.33,1.22c0.38-0.09,0.68-0.28,1-0.44c0.18,0.39,0.16,0.87,0.33,1.22C172.84,60.65,173.16,60.2,173.35,59.83"
          />
          <path
            class="st0"
            d="M40.63,73.59c0.29-0.08,0.12,0.04,0.22-0.22h0.44c0.11,0.41,0.44,0.77,0.68,1.1c0.32,0.43,1.06,1.23,1.54,0.57
		c-0.63-0.15-1.12-0.71-1.37-1.29c-0.63-1.47-0.07-3.15-0.94-4.38c-0.21-0.29-0.34-0.47-0.54-0.79c-0.32-0.49-0.32-0.39-0.92-0.53
		c0.54,2.32,0.2,0.4,0.33,2.89c0.02,0.32,0.1,1.03,0.18,1.38C40.35,72.82,40.52,73.12,40.63,73.59"
          />
          <path
            class="st0"
            d="M64.16,77.81c0,0.57,0.2,0.85,0.47,1.19c0.37,0.48,0.58,0.49,0.86,0.92c-0.18,0.77-0.04,0.76-1.8,0.57
		c-0.56-0.06-0.79,0.1-1.31,0.1c0.11,0.41,0.12,0.23,0.33,0.56c0.4-0.1,1.89-0.39,1.89,0.22c0,0.56-0.1,0.5-0.11,1
		c0.36-0.19,0.65-0.37,1-0.56c0.1,0.37,0.94,1.59,1.22,1.78c0.57-1.01-0.79-1.76-0.89-2.89c0.24-0.17,0.14-0.02,0.22-0.33
		c0.89,0,2.14,0.64,2.77-0.22c-1.47-0.34-1.33-0.15-2.11-0.33c0.01-0.49,0.11-0.52,0.11-1.11c-1.08,0.57-0.51,0.65-1.33,0.67
		c-0.08-0.33-0.7-1.67-1-1.89C64.39,77.54,64.16,77.65,64.16,77.81"
          />
          <path
            class="st0"
            d="M134.4,36.53c0,0.56,0.13,0.01,0.58,0.58c0.02,0.03-0.06,0.3,0.75,0.3h0.56c-0.01,0.34-0.05,0.41-0.11,0.67
		c1.59,0.37,1.17-0.16,1.86-0.81c0.32-0.3,0.87-0.34,1.25-0.52c-0.18-0.69-1.34-1.31-2.3-1.2C136.21,35.65,134.4,35.84,134.4,36.53"
          />
          <path
            class="st0"
            d="M170.91,99.22c0,0.52,0.13,0.8,0.22,1.22c0.92,0,1.75,0.07,2.51-0.15c0.61-0.18,1.54-0.49,1.59-1.18
		c-0.79-0.21-1.23-1-3.11-1C171.17,98.11,170.91,98.25,170.91,99.22"
          />
          <path
            class="st0"
            d="M121.75,66.71c-0.37,0.03-0.72,0.22-1.09,0.35c-0.37,0.12-0.75,0.31-1.24,0.32c-0.09-0.4-0.36-1.07-0.78-1.22
		c-0.26,0.97,0.03,0.61,0.11,1.55c-0.34,0.08-0.5,0.2-0.78,0.33c-0.38,0.19-0.56,0.15-0.66,0.56c1.32,0.11,0.76-0.22,1.55-0.22
		c0.21,0,0.38,0.44,0.48,0.63c0.19,0.37,0.21,0.38,0.52,0.59c0.19-0.73,0.11-0.74-0.11-1.66c0.85-0.07,1.46-0.54,2.33-0.56
		c-0.09,1.05-0.48,0.44-0.22,1.56c0.48-0.32,1.22-1.44,1.22-2.22c0-0.74-3.33-1.8-2.15-0.75C121.32,66.3,121.44,66.24,121.75,66.71"
          />
          <path
            class="st0"
            d="M150.6,77.14c1.42-0.75,3.35-3.37,3.55-4.22c-0.24-0.36-0.44-0.34-0.55-0.78h-0.22
		c-0.11,0.41-0.14,0.28-0.41,0.59c-0.76,0.89-0.1,0.76-1.81,1.31c-0.77,0.25-0.47,0.24-0.45,1.09
		C150.74,75.99,150.6,76.38,150.6,77.14"
          />
          <path
            class="st0"
            d="M139.73,99c0,0.93-0.07,0.89,0.11,1.66c1.52,0,0.22-0.55,2-0.55c0.68,0,1.56,2.33-0.44,2.33
		c-0.54,0-1.78-1.02-1.78-0.22c0,1.89-0.01,0.88,0.22,1.89c0.51-0.14,0.55-0.59,0.55-1.22c0.54,0.05,0.29,0.11,1.22,0.11
		c2.29,0,2.25-3.55-0.11-3.55c-0.56,0-0.59,0.18-1.11,0.22c0-0.26-0.05-0.55-0.12-0.76c-0.13-0.39-0.05-0.35-0.43-0.46
		C139.78,98.7,139.73,98.7,139.73,99"
          />
          <path
            class="st0"
            d="M163.92,66.49c0.62,0,0.96,0.12,1.04-0.71c0.07-0.75-0.76-1.89-1.4-2.37c-0.9-0.67-2.44-1.24-3.86-1.36
		c0.1,0.38,0.12,0.34,0.48,0.52c1.61,0.84,1.98,0.64,2.89,2.54C163.25,65.49,163.49,66.49,163.92,66.49"
          />
          <path
            class="st0"
            d="M100.33,140.73c0,0.62,0.3,0.85,0.55,1.33c-0.88,0-0.92,0.18-1.76-0.12c-0.61-0.22-0.8-0.54-1.68-0.54
		c0.56,2.41,6.61,1.55,5.37-1.14C102.3,139.14,100.33,139.57,100.33,140.73 M101.11,140.39c0.51-0.04,1.22-0.56,1.22,1
		C102.08,141.46,101.11,142.18,101.11,140.39z"
          />
          <path
            class="st0"
            d="M57.83,98.89c0,0.53,0.56,1.11,0.89,1.33c-0.46,0.68-1,0.96-1,1.55c0,0.64,0.59,0.88,0.89,1.33
		c0.79-0.07,1.26-0.19,1.33-1c-0.23,0.05-0.77,0.33-0.78,0.33c-0.42,0-0.21-0.07-0.67-0.11c-0.29-1.26,0.27-1.61,2.1-1.67
		c0.43-0.01,1.37,0.1,1.54,0.02c0.16-0.07,0.24-0.4,0.18-0.53c-0.16-0.33-0.01-0.04-0.83-0.04c-2.12,0-3-0.2-3-1.44
		c0-0.38,0.19-0.33,0.55-0.33c0.48,0-0.12,0.1,0.89,0.33c-0.03-1.16-0.46-1-1.22-1C58.09,97.67,57.83,98.26,57.83,98.89"
          />
          <path
            class="st0"
            d="M97.45,139.62c0,2.34,2.55,1.59,2.55,0.89c0-0.84-0.21-1.12-0.56-1.78c2.64,0,2.17,0.64,3.44,0.67
		C102.34,137.06,97.45,137.88,97.45,139.62 M98.45,139.06c1.26,0.3,0.82,0.87,0.78,1.44h-0.89
		C97.85,139.59,97.98,139.76,98.45,139.06z"
          />
          <path
            class="st0"
            d="M150.27,127.19c-0.77-0.18-1.29-0.18-1.93,0.18c-0.67,0.39-1.45,1.31-3.18,1.7c-0.65,0.14-1.58,0.09-2.1,0.34
		c0.52,0.78,0.74,0.3,2.07,0.5c0.82,0.12,0.68-0.24,1.88-0.66C148.23,128.83,150.15,128.59,150.27,127.19"
          />
          <path
            class="st0"
            d="M140.84,75.7c0,1.91,2.78,1.92,2.78,0C143.61,73.97,140.84,73.97,140.84,75.7"
          />
          <path
            class="st0"
            d="M122.97,57.72c0,0.77,0.15,1.59,1.35,1.6c1.75,0.02,1.8-2.82-0.13-2.82C123.71,56.5,122.97,57.14,122.97,57.72
		"
          />
          <path
            class="st0"
            d="M74.14,58.05c0,1.81,2.66,2.02,2.66-0.11C76.81,56.32,74.14,56.1,74.14,58.05"
          />
          <path
            class="st0"
            d="M98.45,51.28c0,2.1,2.78,1.73,2.78,0.11C101.22,49.73,98.45,49.54,98.45,51.28"
          />
          <path
            class="st0"
            d="M147.38,100.33c0,0.33,0.19,0.78,0.36,0.97c1.17,1.36,3.13-0.55,2-1.89C149,98.55,147.38,99.05,147.38,100.33"
          />
          <path
            class="st0"
            d="M98.45,149.27c0,1.03,1.43,1.84,2.31,0.98c1.5-1.48-0.85-3.12-1.92-1.92
		C98.7,148.48,98.45,149.06,98.45,149.27"
          />
          <path
            class="st0"
            d="M56.06,75.7c0,2.21,2.66,1.89,2.66,0.11C58.72,74.11,56.06,74.13,56.06,75.7"
          />
          <path
            class="st0"
            d="M142.17,126.19c0.89,0,1.44-0.75,1.44-1.33c0-2.13-3.3-1.55-2.65,0.43
		C141.11,125.74,141.57,126.19,142.17,126.19"
          />
          <path
            class="st0"
            d="M55.94,124.97c0,1.55,2.66,1.95,2.66-0.22C58.61,123.27,55.94,122.94,55.94,124.97"
          />
          <path
            class="st0"
            d="M49.51,100.11c0,2.09,1.78,1.6,2.14,1.26c0.72-0.69,0.54-2.4-0.82-2.44C50.16,98.9,49.51,99.39,49.51,100.11"
          />
          <path
            class="st0"
            d="M74.03,142.72c0,0.77,1.09,1.96,2.19,1.08c1.36-1.08-0.39-3.38-1.73-2.06
		C74.31,141.92,74.03,142.41,74.03,142.72"
          />
          <path
            class="st0"
            d="M123.08,142.39c0,0.97,0.1,1.66,1.44,1.66c1.37,0,1.96-2.66-0.44-2.66
		C123.74,141.39,123.08,142.05,123.08,142.39"
          />
          <path
            class="st0"
            d="M160.15,88.35l3.51,1.93c0.31,0.15,2.53,1.24,2.64,1.47l0.28,1.16c-1.03,0.55-6.82,3.5-7.1,3.88
		c0.37-0.09,0.51-0.2,0.85-0.37l5.44-2.55c0.37-0.17,0.55-0.28,1.04-0.3c0-0.74,0.11-1.16,0.11-2
		C165.67,90.91,161.14,88.43,160.15,88.35"
          />
          <path
            class="st0"
            d="M159.59,110.32c0.83-0.19,3.97-1.89,5.01-2.32c0.57-0.23,1.2-0.62,1.87-0.68c0-0.79,0.11-1.15,0.11-2
		l-5.25-2.62c-0.63-0.32-1.25-0.52-1.85-0.81c0.12,0.34,0.91,0.62,1.5,0.94l5.05,2.61c0,0.84,0.17,0.62,0.22,1.22l-4.97,2.68
		C160.8,109.63,159.88,109.93,159.59,110.32"
          />
          <path
            class="st0"
            d="M80.91,65.05c-1.7-0.21-1.06-0.26-1.55,0.78l-1-0.11c-0.4,0-0.78,1.12-0.78,1.55c0,0.33,0.43,0.21,0.61-0.05
		c0.08-0.11,0.31-0.69,0.39-0.84c0.23,0.11,0.97,0.22,1.33,0.22c0.06-0.75,0.17-1.23,1.33-0.67c0.06-0.24,0.34-1,0.44-1.22
		c0.4,0.09,0.52,0.22,1,0.22c0.75,0,0.25-0.96-1.44-1C81.14,64.39,80.95,64.61,80.91,65.05"
          />
          <path
            class="st0"
            d="M81.69,66.6c-0.44-0.04-0.52-0.11-1.11-0.11c-0.11,0.41-0.33,0.52-0.44,1c-0.73-0.39-1.25-0.38-1.48,0.52
		c-0.31,1.25,0.07,1,0.14,1.03c0.02,0.01,0.04,0.28,0.3-0.35c0.12-0.29,0.18-0.46,0.25-0.76c0.56,0.05,0.77,0.29,1.33,0.34
		c0.16-0.34,0.19-0.7,0.33-1c0.48,0.01,0.61,0.11,1.22,0.11c0.03-1.39,0.51-0.98,0.98-0.87c0.51,0.12,0.11,0.03,0.68-0.02
		c-0.06-0.78-0.78-0.89-1.55-0.89c-0.33,0-0.27,0.13-0.39,0.39C81.84,66.25,81.81,66.38,81.69,66.6"
          />
          <path
            class="st0"
            d="M101.11,171.58l1.29,1.15c0.31,0.25,0.13,0.26,0.6,0.29c0.39-0.74,1.31-4.93-1.14-2.47
		C101.6,170.8,101.2,171.22,101.11,171.58"
          />
          <path
            class="st0"
            d="M106.88,155.49c0.24,1.03,1,1.44,2,1.44c0.75,0,0.91-0.21,1.44-0.33c0-0.82-0.19-0.92-0.33-1.55
		c-0.5,0.01-0.99,0.24-1.47,0.3C107.9,155.43,107.53,155.49,106.88,155.49"
          />
          <path
            class="st0"
            d="M133.96,54.5c0,0.73,0.17,1.26,0.1,1.87c-0.07,0.62-0.39,1.06-0.43,1.57c0.42,0.1,0.69,0.22,1.22,0.22
		c0-2.57,1.07-4.4-0.78-4.44C134.06,54.24,133.96,54.1,133.96,54.5"
          />
          <path
            class="st0"
            d="M157.82,105.33c0,1.61,0.07,3.68-0.33,5.22c-0.1,0.37-0.21,0.54-0.23,1c0.79-0.53,0.65-0.9,0.78-2.44
		c0.15-1.72,0.78-7.17-0.78-8.22C157.28,101.74,157.82,103.27,157.82,105.33"
          />
          <path
            class="st0"
            d="M158.15,91.68c0,1.61,0.07,2.68-0.19,4.25l-0.37,1.85c0.38-0.25,0.53-0.36,0.63-0.93
		c0.31-1.79,0.37-4.7,0.37-6.62c0-1.3-0.22-2.58-1-3.11C157.61,87.7,158.15,89.24,158.15,91.68"
          />
          <path
            class="st0"
            d="M160.59,72.59c0,0.92-0.43,1.36-0.44,2.22c2.28,1.52,1.67-2.49,1.66-2.89h-1.22V72.59z"
          />
          <path
            class="st0"
            d="M138.29,104.11c0.46,0,0.55-0.29,0.55-0.89c0-0.8,0.14-3.87-0.13-4.42c-0.14-0.27-0.11-0.27-0.42-0.36
		c-0.12,0.5-0.22,0.69-0.23,1.33l0.01,3.11C138.06,103.55,137.8,104.11,138.29,104.11"
          />
          <path
            class="st0"
            d="M166.8,72.04c0,0.86,0.37,1.54,0.67,2.11c0.72-0.06,2.33-1.32,0.61-2.49c-0.38-0.26-0.67-0.39-1.27-0.39V72.04
		z"
          />
          <path
            class="st0"
            d="M91.57,174.02c0.08,0.11,0.27,0.33,0.44,0.33c1.43,0,4.11-1.64,5.01-2.65l1.12-1.21
		c0.18-0.2,0.17-0.23,0.34-0.44c0.3-0.37,0.53-0.54,0.63-0.92c-0.91,0.48-2,2.03-3.25,2.97C93.46,173.9,92.66,173.49,91.57,174.02"
          />
          <path
            class="st0"
            d="M165.47,72.81l-0.22,2.44h1.77c0-2.7-0.26-1.55-0.55-2.66C165.82,72.61,166.05,72.76,165.47,72.81"
          />
          <path
            class="st0"
            d="M29.87,89.68c0.94,0.5,2,1.2,2-0.44l-0.69-0.01c0,0.01-0.02,0.04-0.02,0.05c-0.22,0.27,0.17,0.19-0.28,0.19
		c-0.58,0-0.48-1.11,0.56-1.11c0.33,0,0.72,0.24,1.11,0.34c0.28-0.19,0.46-0.3,0.55-0.67c-0.43-0.1-2.36-0.78-2.94,0.17
		C30.05,88.33,29.89,89.45,29.87,89.68"
          />
          <path
            class="st0"
            d="M95.56,158.37c0.45-0.01,4-0.95,4.79-0.13c0.44,0.46,1.17,1.68,2.53,1.8c-0.35-0.53-1.85-2.44-2.33-2.44
		c-1.59,0-3.09-0.25-4.52,0.48L95.56,158.37z"
          />
          <path
            class="st0"
            d="M167.25,66.82c-0.08-0.92-1.18-1.03-2-1.22c0.02,0.96,0.77,1.48,1.66,1.55c-0.33,0.69,0.28,1.01,0.28,1.01
		l1.28-0.23c0.04-0.43,0.1-0.29,0.11-0.78C168.1,66.74,168.38,66.85,167.25,66.82"
          />
          <path
            class="st0"
            d="M65.49,36.64c0,0.79,0.19,1.27,0.78,1.66c0.57-0.15,0.82-0.82,1.22-1.11c-0.34-0.51-0.73-0.96-0.89-1.55
		C65.95,35.65,65.49,36,65.49,36.64"
          />
          <path
            class="st0"
            d="M162.03,74.92c0,0.45,0.72,0.44,1.11,0.44c0.5-2.14,0.23-1.54,0.11-2.99c-0.8,0.07-0.97,0.08-1,0.88
		C162.22,73.96,162.03,74.26,162.03,74.92"
          />
          <path
            class="st0"
            d="M163.7,75.48c0.62,0,0.66,0.11,1.22,0.11c0-0.18,0.46-2.89-0.33-2.89c-0.72,0-0.61,0.43-0.68,1.09
		C163.84,74.36,163.7,74.76,163.7,75.48"
          />
          <path
            class="st0"
            d="M105.88,158.82c1.32,0.7,1.83,1.2,2.66,2.44c0.46-0.12,0.87-0.53,1.08-0.94c0.43-0.8-0.61-0.42-1.3-0.51
		c-1.22-0.16-1.62-1.55-2.45-1.77V158.82z"
          />
          <path
            class="st0"
            d="M88.68,167.25c1.76,0.41,2.77-0.58,2.77-0.89c0-0.57-0.39-0.68-0.44-1.33c-0.48,0.32-0.35,0.58-1.75,1.58
		C88.98,166.82,88.78,166.88,88.68,167.25"
          />
          <path
            class="st0"
            d="M102.33,169.24c0.56,0.13,1.05,0.43,1.67,0.44c0-1.28,0.14-1.57-0.45-2.44
		C102.35,167.35,102.56,168.24,102.33,169.24"
          />
          <path
            class="st0"
            d="M29.2,113.65c0.49,0,1.32-0.12,1.7-0.19c0.36-0.07,1.23-0.27,1.52-0.48c-1.51,0-1.97,0.39-3.62,0.07
		c-1.16-0.24-2.34-1.06-2.82-1.18C26.26,113.07,28.05,113.65,29.2,113.65"
          />
          <path
            class="st0"
            d="M47.4,146.28c0.68-0.02,2.55-0.41,2.55-1.33c0-0.38-0.08-0.43-0.11-0.78c-1.04,0-0.94,0.17-1.43,0.79
		C48.05,145.42,47.73,145.78,47.4,146.28"
          />
          <path
            class="st0"
            d="M160.37,65.38l0.11,2.22c-0.89,0.07-1.83,0.75-2.22,1.33c0.65,0.54,2.29-0.8,2.66-1
		c0-0.63-0.07-0.95-0.12-1.55c-0.1-1.12-0.02-1.12-0.33-1.34C160.4,65.18,160.37,65.16,160.37,65.38"
          />
          <path
            class="st0"
            d="M167.36,69.93c0,0.54,0.16,1.03,0.47,1.3c0.41,0.37,0.73,0.36,1.2,0.58c0.13-0.28,0.3-1.16,0.33-1.55
		C168.66,70.1,167.36,69.47,167.36,69.93"
          />
          <path
            class="st0"
            d="M63.38,161.26c0.71,0.17,1.17,0.64,1.55,0.78C66.53,159.65,63.53,159.39,63.38,161.26"
          />
          <path
            class="st0"
            d="M159.15,70.15c1.43,0,2.29-0.7,2.65-1.12c0.7-0.83,0.68-1.73,0.68-3.2c-0.55,0.4-0.25,0.74-0.35,1.53
		C161.81,69.62,159.48,68.89,159.15,70.15"
          />
          <path
            class="st0"
            d="M155.93,76.7c0.05-0.61,0.14-0.32,0-0.99l-0.41-2.26c-0.13-0.32-0.59-0.54-0.78-0.46
		c-0.2,0.08-0.16-0.14,0.02,0.89c0.1,0.56,0.52,2.11,0.8,2.53C155.74,76.71,155.6,76.61,155.93,76.7"
          />
          <path
            class="st0"
            d="M160.37,79.69c0.4-0.48-0.19-4.3-1.55-5.22c0.04,1.58,1.08,1.67,1.11,3.89
		C159.93,79.13,160,79.45,160.37,79.69"
          />
          <path
            class="st0"
            d="M103.33,174.57c0.46-0.31,2.13-1.74,2.26-2.06c0.16-0.4-0.01-0.66,0.47-1.63c0.25-0.51,0.58-0.95,0.6-1.63
		c-0.85,0.57-1.08,1.78-1.52,2.81c-0.22,0.54-0.47,0.89-0.84,1.27c-0.26,0.27-0.27,0.36-0.5,0.61
		C103.53,174.23,103.44,174.16,103.33,174.57"
          />
          <path
            class="st0"
            d="M107.44,167.91c0,2-0.7,1.8-0.44,2.88c0.54-0.39,1.64-4.27-0.44-5.66
		C106.57,166.16,107.44,167.36,107.44,167.91"
          />
          <path
            class="st0"
            d="M167.25,69.04c0.45,0.3,0.4,0.63,1.89,0.67v-1.44c-0.42-0.1-0.93-0.23-1.44,0.11
		C167.42,68.54,167.4,68.76,167.25,69.04"
          />
          <path
            class="st0"
            d="M94.45,174.91c0.77-0.06,1.56-0.44,2.44-0.44c-0.02-0.28-0.33-1.22-0.55-1.22
		C96.04,173.24,94.53,173.94,94.45,174.91"
          />
          <path
            class="st0"
            d="M173.79,97.34v0.33c0.38,0.1,0.07-0.01,0.37,0.19c1.83,1.22,1.41,0.58,1.41-1.52
		c-0.79,0.07-0.14,0.19-0.71,0.63C174.65,97.13,174.13,97.31,173.79,97.34"
          />
          <path
            class="st0"
            d="M133.51,59.61c0.54,0.36,0.53,0.7,1.44,0.78c0-1.26,0.08-2-1.22-2C133.71,58.74,133.59,59.28,133.51,59.61"
          />
          <path
            class="st0"
            d="M173.68,101c0.1,0.37,0.04,0.24,0.32,0.45l1.45,0.99c0-0.36-0.02-0.73,0.03-1.08
		c0.02-0.16,0.24-0.82-0.05-0.95C175.31,100.35,174.11,100.96,173.68,101"
          />
          <path
            class="st0"
            d="M108.77,159.26c0.46,0,1.08-0.09,1.25-0.53c0.15-0.4-0.64-0.21-1.12-0.26c-1.46-0.14-1.49-0.59-2.23-0.76
		C107.06,158.46,108.11,159.26,108.77,159.26"
          />
          <path
            class="st0"
            d="M157.26,78.58c1.07-0.57,0.8-1.07,0.48-2.15c-0.32-1.08-0.41-2.7-1.37-2.95c0.27,1.16,1.13,3.04,1.35,3.98
		C157.85,77.96,157.42,78.01,157.26,78.58"
          />
          <path
            class="st0"
            d="M62.38,162.7l0.44,0.34c0.24-0.16,0.33-0.26,0.67-0.34c0,1.46,1,0.84,1,0.22c0-0.05-0.5-0.72-1.02-0.87
		C62.71,161.84,62.54,162.02,62.38,162.7"
          />
          <path
            class="st0"
            d="M95.78,160.59c1.94-0.45,1.44-1.06,3.87-0.1c0.36,0.14,0.87,0.39,1.35,0.43
		C99.96,159.51,96.7,159.22,95.78,160.59"
          />
          <path
            class="st0"
            d="M109.43,158.04c0.82,0,0.96-0.22,1.11-0.89c-2.7,0.23-1.69,0-3.22,0l0.85,0.59
		C108.45,157.87,109.07,158.04,109.43,158.04"
          />
          <path
            class="st0"
            d="M151.6,79.03v0.22c1.44,0,1.53-0.07,2.52-0.59c0.51-0.27,1.54-0.93,1.7-1.52
		C154.03,78.09,154.06,78.45,151.6,79.03"
          />
          <path
            class="st0"
            d="M169.02,92.23v0.78c1.65,0,1.44,0.19,1.44-1c0-0.5-0.61-0.36-1.14-0.24
		C168.66,91.92,169.02,91.85,169.02,92.23"
          />
          <path
            class="st0"
            d="M129.96,52.28c-0.07,0.29-0.23,1.12-0.33,1.33c1.16,0,1.55-0.15,1.78-1.11
		C130.99,52.23,130.64,52.02,129.96,52.28"
          />
          <path
            class="st0"
            d="M104.99,169.91c-0.33,0.22-0.35,0.29-0.6,0.62c-0.27,0.35-0.4,0.29-0.51,0.71c1.45-0.34,1.85-2.79,1.43-4.1
		c-0.13-0.4-0.03-0.35-0.33-0.56V169.91z"
          />
          <path
            class="st0"
            d="M124.41,53.95v0.22c0.5,0.33,0.47,0.46,1.33,0.67c0-0.93,0.1-1.74-1.33-1.78V53.95z"
          />
          <path
            class="st0"
            d="M169.02,106.33c0,0.83,0.15,1.62,1.44,1l-0.11-1.22C169.88,106.14,169.53,106.32,169.02,106.33"
          />
          <path
            class="st0"
            d="M158.81,76.81c-0.01-0.48-0.2-1.31-0.32-1.68c-0.2-0.64-0.3-0.99-0.79-1.32
		C157.5,74.71,158.15,76.36,158.81,76.81"
          />
          <path
            class="st0"
            d="M120.75,50.62c-0.05,0.22-0.29,0.71,0.17,1.16c0.14,0.13,0.55,0.3,0.72,0.39c0.3-1.29,0.33-0.74-0.22-1.78
		C121.15,50.46,120.97,50.51,120.75,50.62"
          />
          <path
            class="st0"
            d="M90.57,173.24c0,0.45,0.4,0.58,0.78,0.67c-0.13-0.56-0.74-0.98,0.11-1.55c0.75,0.4,0.11,0.39,0.78,0.45
		c-0.03-0.34-0.11-0.53-0.22-0.78h-1C90.79,172.35,90.57,172.47,90.57,173.24"
          />
          <path
            class="st0"
            d="M90.46,170.91c-0.96,0.51-1.17,0.19-1.33,0.78c0.46,0,1.02,0.07,1.3-0.15c0.22-0.17,0.45-0.73,0.48-1.07
		c-0.47-0.04-0.37-0.13-0.78-0.22C90.2,170.58,90.3,170.67,90.46,170.91"
          />
          <path
            class="st0"
            d="M142.39,39.74c0.36-0.1,1.92-0.88,2.11-1.66c-0.49,0.23-0.82,0.26-1.25,0.64l-0.46,0.43
		C142.54,39.42,142.48,39.4,142.39,39.74"
          />
          <path
            class="st0"
            d="M101.55,160.15c-0.36-0.54-1.64-1.6-2.44-1.67c0.32,0.62,0.45,0.59,1.04,0.95
		C100.58,159.69,101.08,160.03,101.55,160.15"
          />
          <path
            class="st0"
            d="M109.1,166.25c-0.07-0.79-0.96-1.39-1.66-1.55c0.08,0.29,0.1,0.26,0.26,0.51
		C108.35,166.17,108.17,165.76,109.1,166.25"
          />
          <path
            class="st0"
            d="M106.44,168.91c0.33-0.09,0.18-0.05,0.44-0.22c-0.04-0.49-0.55-2.19-0.89-2.44
		C106.06,167.09,106.37,168.07,106.44,168.91"
          />
          <path
            class="st0"
            d="M102.66,157.59c0,0.47-0.01,0.64,0.2,1.02c0.16,0.29,0.24,0.44,0.58,0.53l-0.37-1.52
		c-0.19-0.61,0.04-0.23-0.3-0.37C102.7,157.4,102.66,157.38,102.66,157.59"
          />
          <path
            class="st0"
            d="M153.23,123.19c0.17,0.54,0.86,0.78,1.28,0.67c-0.13-0.43-0.38-0.72-0.82-0.77
		C153.05,123.02,153.39,123.1,153.23,123.19"
          />
          <path
            class="st0"
            d="M133.96,52.31l0.72-0.08l-0.2-0.61l-0.08-0.11h-0.66C133.74,52.1,133.62,52.02,133.96,52.31"
          />
          <path
            class="st0"
            d="M153.49,66.6c-0.08-0.28-0.52-0.9-0.89-1C152.6,67.03,152.71,66.62,153.49,66.6"
          />
          <path
            class="st0"
            d="M56.84,42.74l-0.12-0.08c-0.22,0.17-0.37,0.38-0.42,0.67c-0.03,0.17-0.03,0.29,0.03,0.45
		c0.18,0.45,0.18,0.25,0.32,0.36C56.63,43.53,56.75,43.26,56.84,42.74"
          />
          <path
            class="st0"
            d="M66.38,156.23c-0.17,0.2,0.12,0.02-0.22,0.21c0.1,0.11,0.25-0.24,0.18,0.39c-0.07,0.54,0.01,0.18-0.29,0.26
		l0.33,0.16c0.28-0.28,0.48-0.69,0.61-1.11L66.38,156.23z"
          />
          <path
            class="st0"
            d="M133.96,52.31l-0.39,0.09l1.54,0.02c-0.05-0.54-0.25-0.74-0.59-0.92l-0.03,0.13l0.2,0.61L133.96,52.31z"
          />
          <path
            class="st0"
            d="M139.39,154.98c0.14,0.17,0.06,0,0.11,0.28c0.42-0.23,0.95-0.67,0.33-1.18
		C139.54,154.5,139.82,154.56,139.39,154.98"
          />
          <path
            class="st0"
            d="M66.76,157.37l0.17,0.02l0.56-0.29c0.16-0.3-0.01-0.09,0.33-0.25c-0.35-0.14-0.25-0.27-0.48-0.45L66.76,157.37
		z"
          />
          <path
            class="st0"
            d="M66.93,157.39c0.1,0.34,0.21,0.44,0.56,0.53c0.01-0.57,0.21-0.45,0-0.82L66.93,157.39z"
          />
          <path
            class="st0"
            d="M158.93,81.69c0.4-0.27,0.68-0.65,1.01-0.99l0.21-0.23C159.62,80.51,159.04,81.18,158.93,81.69"
          />
          <path
            class="st0"
            d="M66.04,157.08c0.29-0.08,0.22,0.28,0.29-0.26c0.08-0.62-0.08-0.27-0.18-0.39c-0.23,0.21-0.39,0.27-0.56,0.49
		C65.81,157.03,65.73,157,66.04,157.08"
          />
          <path
            class="st0"
            d="M156.15,79.58c0.61-0.14,0.85-0.26,1-0.89C156.71,78.99,156.45,79.14,156.15,79.58"
          />
          <path
            class="st0"
            d="M159.59,137.29c0,0.27,0.18,0.03,0.07,0C159.66,137.29,159.59,136.97,159.59,137.29"
          />
          <polygon class="st0" points="120.09,83.35 120.2,83.35 120.1,83.23 	" />
          <polygon class="st0" points="60.94,35.97 60.83,35.97 60.92,36.1 	" />
          <polygon class="st0" points="89.13,88.24 89.13,88.13 88.99,88.22 	" />
          <polygon
            class="st0"
            points="158.93,81.69 158.81,81.69 158.91,81.82 	"
          />
          <polygon
            class="st0"
            points="116.87,92.45 116.98,92.45 116.89,92.32 	"
          />
          <polygon class="st0" points="112.98,87.91 113.09,87.91 113,87.77 	" />
          <polygon
            class="st0"
            points="114.98,121.97 114.98,122.08 115.11,121.99 	"
          />
          <path
            class="st0"
            d="M159.15,110.54l0.03-0.03C159.18,110.51,159.14,110.53,159.15,110.54"
          />
        </g>
      </svg>
  
  
  );
};

export default AstrologyIcon;
