import React from "react";

const HallMarkIcon = () => (

  
      <svg
        version="1.1"
        id="Layer_1"
        viewBox="0 0 200 200"
        className="promises-img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            class="st0"
            d="M101.57,64.75c1.69,0.16,3.25,0.98,4.33,2.29l33.72,33.73c5.47,5.47,2.31,9.8-4.9,9.22h-33.15l19.89,20.47
		h42.35c12.97,0.57,23.35-11.53,10.37-23.94l-59.06-58.79c-4.91-4.89-9.24-8.07-13.55-8.07c-4.31,0-8.66,3.18-13.55,8.07
		l-59.05,58.8c-12.98,12.42-2.6,24.46,10.37,23.93h42.31L101.54,110H68.49c-7.21,0.58-10.37-3.77-4.9-9.22l33.72-33.73
		c1.08-1.31,2.63-2.14,4.33-2.3"
          />
          <path
            class="st0"
            d="M101.34,84.45c-6.74,0.03-12.18,5.51-12.16,12.25c0.03,6.74,5.51,12.18,12.25,12.16
		c6.74-0.03,12.18-5.51,12.16-12.25c-0.03-6.71-5.45-12.13-12.16-12.16"
          />
        </g>
      </svg>
 
 
  );


export default HallMarkIcon;
