import React from "react";

const MeltingIcon = () => (


 



      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        className="promises-img"
      >
        <g>
          <path
            class="st1"
            d="M99.89,30.11c-2.96,0.04-72.09,36.06-80.71,40.44l0.13,6.3l160.9,0.21l0.61-6.67
		C171.49,66.13,102.87,30.07,99.89,30.11z M104.34,68.4c-22.29,4.78-26.52-23.09-8.11-26.96C117.2,37.04,122.34,64.54,104.34,68.4z"
          />
          <polygon
            class="st1"
            points="21.93,169.65 177.23,169.89 177.23,160.41 21.94,160.25 	"
          />
          <polygon
            class="st1"
            points="28.82,157.01 170.98,157.09 170.99,147.3 28.93,147.31 	"
          />
          <path
            class="st1"
            d="M53.78,79.53h-18.3v8.03c3.94-0.9,10.56-1.7,18.3,0.28V79.53z"
          />
          <path
            class="st1"
            d="M81.51,79.53h-18.3v8.03c3.94-0.9,10.56-1.7,18.3,0.28V79.53z"
          />
          <path
            class="st1"
            d="M109.17,79.53h-18.3v8.03c3.94-0.9,10.56-1.7,18.3,0.28V79.53z"
          />
          <path
            class="st1"
            d="M136.59,79.53h-18.3v8.03c3.94-0.9,10.56-1.7,18.3,0.28V79.53z"
          />
          <path
            class="st1"
            d="M164.17,79.53h-18.3v8.03c3.94-0.9,10.56-1.7,18.3,0.28V79.53z"
          />
          <path
            class="st1"
            d="M35.48,144.74h18.3v-8.31c-7.74,1.98-14.35,1.19-18.3,0.28V144.74z"
          />
          <path
            class="st1"
            d="M63.22,144.74h18.3v-8.31c-7.74,1.98-14.36,1.19-18.3,0.28V144.74z"
          />
          <path
            class="st1"
            d="M90.87,144.74h18.3v-8.31c-7.74,1.98-14.35,1.19-18.3,0.28V144.74z"
          />
          <path
            class="st1"
            d="M118.3,144.74h18.3v-8.31c-7.74,1.98-14.36,1.19-18.3,0.28V144.74z"
          />
          <path
            class="st1"
            d="M145.87,144.74h18.3v-8.31c-7.74,1.98-14.36,1.19-18.3,0.28V144.74z"
          />
          <path
            class="st1"
            d="M41.63,88.75c-2.27,0-4.13,1.86-4.13,4.13v38.22c0,2.27,1.86,4.13,4.13,4.13h6c2.27,0,4.13-1.86,4.13-4.13
		V92.89c0-2.27-1.86-4.13-4.13-4.13H41.63z"
          />
          <path
            class="st1"
            d="M69.22,88.75c-2.27,0-4.13,1.86-4.13,4.13v38.22c0,2.27,1.86,4.13,4.13,4.13h6c2.27,0,4.13-1.86,4.13-4.13
		V92.89c0-2.27-1.86-4.13-4.13-4.13H69.22z"
          />
          <path
            class="st1"
            d="M96.99,88.75c-2.27,0-4.13,1.86-4.13,4.13v38.22c0,2.27,1.86,4.13,4.13,4.13h6c2.27,0,4.13-1.86,4.13-4.13
		V92.89c0-2.27-1.86-4.13-4.13-4.13H96.99z"
          />
          <path
            class="st1"
            d="M124.06,88.75c-2.27,0-4.13,1.86-4.13,4.13v38.22c0,2.27,1.86,4.13,4.13,4.13h6c2.27,0,4.13-1.86,4.13-4.13
		V92.89c0-2.27-1.86-4.13-4.13-4.13H124.06z"
          />
          <path
            class="st1"
            d="M151.65,88.75c-2.27,0-4.13,1.86-4.13,4.13v38.22c0,2.27,1.86,4.13,4.13,4.13h6c2.27,0,4.13-1.86,4.13-4.13
		V92.89c0-2.27-1.86-4.13-4.13-4.13H151.65z"
          />
          <path
            class="st1"
            d="M93.73,49.68v0.06h3.1c0.52,0,1.11,0.1,1.76,0.31c0.91,0.35,1.53,0.84,1.84,1.46v0.03H94.9
		c-0.02,0-0.41,0.55-1.19,1.65c0,0.03,0.02,0.04,0.05,0.04h6.98c0,0.26-0.05,0.5-0.15,0.74c-0.63,1.63-2.22,2.44-4.8,2.44
		c-0.54,0-1.02-0.03-1.42-0.08l-0.02,0.06v1.58c0,0.02,2.29,2.64,6.88,7.86h3.11c-4.71-5.37-7.06-8.1-7.06-8.18v-0.02
		c1.04,0,2.13-0.26,3.27-0.8c0.61-0.23,1.25-0.75,1.92-1.56c0.5-0.79,0.78-1.47,0.83-2.04h2.17c0.02,0,0.4-0.55,1.14-1.64
		c0-0.02-0.02-0.04-0.06-0.05h-3.34c-0.15-0.7-0.45-1.31-0.88-1.8h3.14c0.09-0.07,0.48-0.63,1.17-1.67v-0.02H94.9
		C94.87,48.05,94.48,48.59,93.73,49.68z"
          />
        </g>
      </svg>
  
  
  );

export default MeltingIcon;
